import { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper/Paper'
import { GET } from '../../Helpers/fetch'
import {
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    Button,
    IconButton,
    Link,
    Skeleton,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import Grid from '@mui/material/Grid/Grid'

const MyView = () => {
    const [trainings, setTrainings] = useState([])
    const [resources, setResources] = useState([])
    const [authorizations, setAuthorizations] = useState([])
    const [userData, setUserData] = useState([])
    const [iod, setIOD] = useState({})
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0)

    const nextStep = () => {
        if (activeStep < 2) {
            setActiveStep(activeStep + 1)
        }
    }

    const previousStep = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1)
        }
    }

    // useEffect(() => {
    //     setLoading(true)
    //     GET('users/getUser').then(res => {
    //         setData(res.data)
    //         setLoading(false)
    //     })
    // }, [])

    useEffect(() => {
        setLoading(true)
        const getUserData = async () => {
            const result = await GET('users/getUser')
            const { data } = result
            if (data) {
                setUserData(data)
            }
            setLoading(false)
        }

        const getTrainings = async () => {
            const result = await GET('training/getForEmployee', {
                myView: true,
            })
            const { data: Items } = result.data
            if (Items) {
                setTrainings([...Items])
            }
        }

        const getResources = async () => {
            const result = await GET('resourceRegister/getForEmployee', {
                myView: true,
            })
            const { data: Items } = result.data
            if (Items) {
                setResources([...Items])
            }
        }

        const getAuth = async () => {
            const result = await GET('employeeAuthorizations/getForEmployee')
            const { data: Items } = result.data
            if (Items) {
                console.log(Items)
                setAuthorizations([...Items[0].authorizations])
            }
        }

        const getIOD = async () => {
            const result = await GET('companySettings/getIOD')
            const { data } = result.data
            if (data) {
                setIOD(data[0].iod)
            }
            setLoading(false)
        }

        getTrainings().catch(console.error)
        getResources().catch(console.error)
        getUserData().catch(console.error)
        getAuth().catch(console.error)
        getIOD().catch(console.error)

        return () => {
            setTrainings([])
            setResources([])
            setUserData([])
        }
    }, [])

    return (
        <Box height={'80vh'} mt={2} textAlign={'left'} display={'flex'}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'30%'}
                height={'100%'}
            >
                <Box height={'50%'} m={1}>
                    <Paper
                        sx={{
                            height: '64px',
                            boxSizing: 'border-box',
                            p: 2,
                            mb: 1,
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='h5'
                            fontWeight={'bold'}
                        >
                            {loading ? (
                                <Skeleton />
                            ) : (
                                `Witaj, ${userData.firstName}!`
                            )}
                        </Typography>
                    </Paper>
                    <Paper
                        sx={{
                            height: 'calc(100% - 72px)',
                            boxSizing: 'border-box',
                            p: 2,
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='h5'
                            fontWeight={'bold'}
                        >
                            Twoja firma
                        </Typography>
                        <Box p={1}>
                            <Typography variant='h6'>Rodo.pl</Typography>
                            <Typography variant='h6'>Sianowska 4a</Typography>
                            <Typography variant='h6'>60-431 Poznań</Typography>
                        </Box>
                    </Paper>
                </Box>
                <Paper
                    sx={{
                        height: '50%',
                        boxSizing: 'border-box',
                        p: 2,
                        m: 1,
                    }}
                >
                    <Typography
                        color='primary'
                        variant='h5'
                        fontWeight={'bold'}
                    >
                        Twój IOD
                    </Typography>
                    <Box p={1}>
                        <Typography variant='h6'>
                            {iod.name} {iod.lastName}
                        </Typography>
                        <Link
                            underline='hover'
                            color='inherit'
                            variant='h6'
                            href={`mailto:${iod.email}`}
                        >
                            {iod.email}
                        </Link>
                    </Box>
                </Paper>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'70%'}
                height={'100%'}
            >
                <Paper
                    sx={{
                        height: '50%',
                        minHeight: '200px',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        p: 2,
                        m: 1,
                    }}
                >
                    {' '}
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <IconButton
                            color='primary'
                            size='small'
                            onClick={previousStep}
                            disabled={activeStep === 0}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>
                        <Typography
                            color='primary'
                            variant='h5'
                            fontWeight={'bold'}
                        >
                            {
                                [
                                    'Szkolenia do ukończenia',
                                    'Moje Upoważnienia',
                                ][activeStep]
                            }
                        </Typography>
                        <IconButton
                            color='primary'
                            size='small'
                            onClick={nextStep}
                            disabled={activeStep === 1}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                    </Box>
                    <Box sx={{ overflow: 'auto', height: 'calc(100% - 67px)' }}>
                        {activeStep === 0 &&
                            (trainings.length > 0 ? (
                                <List>
                                    {trainings.map(el => (
                                        <ListItem>
                                            <ListItemText primary={el.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography p={1}>
                                    Ukończyłeś wszystkie szkolenia!
                                </Typography>
                            ))}
                        {activeStep === 1 &&
                            (authorizations.length > 0 ? (
                                <List>
                                    {authorizations.map(el => (
                                        <ListItem>
                                            <ListItemText
                                                primary={el.authName}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography p={1}>Brak upoważnień</Typography>
                            ))}
                    </Box>
                    <Box textAlign={'right'}>
                        <Button
                            component={RouterLink}
                            to={
                                [
                                    '/app/moje-szkolenia',
                                    '/app/moje-upowaznienia',
                                ][activeStep]
                            }
                        >
                            {
                                ['Przejdź do szkoleń', 'Przejdź do upoważnień'][
                                    activeStep
                                ]
                            }
                        </Button>
                    </Box>
                </Paper>
                <Paper
                    sx={{
                        height: '50%',
                        minHeight: '200px',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        p: 2,
                        m: 1,
                    }}
                >
                    <Typography
                        color='primary'
                        variant='h5'
                        fontWeight={'bold'}
                    >
                        Zasoby wymagające potwierdzenia odbioru
                    </Typography>
                    <Box sx={{ overflow: 'auto', height: 'calc(100% - 67px)' }}>
                        {resources.length > 0 ? (
                            <List>
                                {resources.map(el => (
                                    <ListItem>
                                        <ListItemText
                                            primary={`${el.resourceType} - ${el.manufacturerAndModel}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography p={1}>
                                Potwierdzono odbiór wszystkich zasobów
                            </Typography>
                        )}
                    </Box>
                    <Box textAlign={'right'}>
                        <Button component={RouterLink} to='/app/moje-zasoby'>
                            Przejdź do zasobów
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default MyView
