import Typography from '@mui/material/Typography/Typography'
import moment from 'moment/moment'
import IconButton from '@mui/material/IconButton/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Tooltip,
    ListItemText,
} from '@mui/material'

const columns = props => [
    { name: 'firstName', label: 'Imię' },
    { name: 'lastName', label: 'Nazwisko' },
    {
        name: 'hireDate',
        label: 'Data zatrudnienia',
        options: {
            customBodyRender: value => (
                <Typography>{moment(value).format('DD.MM.YYYY')}</Typography>
            ),
        },
    },
    { name: 'position', label: 'Stanowisko' },
    {
        name: 'organizationUnits',
        label: 'Jednostka Organizacyjna',
        options: {
            customBodyRender: function (value) {
                return value.join(', ')
            },
        },
    },
    {
        name: 'departmens',
        label: 'Dział',
        options: {
            customBodyRender: function (value) {
                return value.join(', ')
            },
        },
    },
    { name: 'contractType', label: 'Umowa' },
    {
        name: 'isFired',
        label: '',
        options: {
            display: 'excluded',
            filter: true,
            filterType: 'custom',
            sort: false,
            customFilterListOptions: {
                render: v => (v[0] === 'true' ? ['Tak'] : ['Nie']),
            },
            filterOptions: {
                display: (filterList, onChange, index, column) => {
                    const optionValues = [
                        { label: 'Tak', value: 'true' },
                        { label: 'Nie', value: 'false' },
                    ]

                    return (
                        <FormControl variant='outlined'>
                            <InputLabel id='filter-label'>Zwolniony</InputLabel>
                            <Select
                                labelId={'filter-label'}
                                input={
                                    <OutlinedInput
                                        label='Zwolniony'
                                        id='filter-label'
                                    />
                                }
                                value={filterList[index] || ''}
                                onChange={event => {
                                    filterList[index] = [event.target.value]
                                    onChange(filterList[index], index, column)
                                }}
                            >
                                {optionValues.map(item => (
                                    <MenuItem
                                        key={item.label}
                                        value={item.value}
                                    >
                                        <Tooltip title={item.label}>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    noWrap: true,
                                                    maxWidth: '300px',
                                                }}
                                                primary={item.label}
                                            />
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                },
            },
            customBodyRender: value => {
                return value ? 'Tak' : 'Nie'
            },
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </>
                )
            },
        },
    },
    { name: 'email', label: 'E-mail', options: { display: false } },
    {
        name: 'phoneNumber',
        label: 'Numer telefonu',
        options: { display: false },
    },
]

export default columns
