import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { useForm } from 'react-hook-form'
import Divider from '@mui/material/Divider/Divider'
import { Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { forwardRef, useEffect, useState } from 'react'
import { parseError } from '../../../Helpers/formValidation'
import { POST, GET } from '../../../Helpers/fetch'
import { AutocompleteElement } from 'react-hook-form-mui'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const EditResourceRegisterModal = forwardRef(
    ({ close, setSnackbarInfo, data, refreshData }, ref) => {
        const formContext = useForm({ defaultValues: data })

        const [loading, setLoading] = useState(false)
        const [loadingEmployees, setLoadingEmployees] = useState(true)
        const [employeeList, setEmployeeList] = useState([])

        const getEmployees = async () => {
            setLoadingEmployees(true)
            const { data: Items } = await GET('employee/get')
            const employees = Items.data.map(el => ({
                label: `${el.firstName} ${el.lastName} (${el.email})`,
                id: el._id,
            }))
            setEmployeeList([...employees])
            setLoadingEmployees(false)
        }

        useEffect(() => {
            getEmployees().catch(err => console.error(err))
        }, [])

        const editResRegHandler = data => {
            setLoading(true)
            console.log(data.attributedPerson)
            console.log(data.employeeId)

            const editResource = async () => {
                await POST('resourceRegister/edit', data)
            }
            editResource()
                .then(() => {
                    setSnackbarInfo({
                        open: true,
                        type: 'success',
                        message: 'Dodano wpis do rejestru',
                    })
                    setLoading(false)
                    refreshData()
                    close(true)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                    setSnackbarInfo({
                        open: true,
                        type: 'error',
                        message: 'Błąd: Nie dodano wpisu',
                    })
                })
        }

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant='h6'>
                    Dodawanie wpisu do rejestru zasobów
                </Typography>
                <Divider />
                <FormContainer
                    formContext={formContext}
                    onSuccess={editResRegHandler}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={4}>
                            <TextFieldElement
                                name={'resourceType'}
                                parseError={parseError}
                                fullWidth
                                autoFocus
                                label='Rodzaj zasobu'
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextFieldElement
                                name={'manufacturerAndModel'}
                                parseError={parseError}
                                fullWidth
                                required
                                label='Producent/Model'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                name={'serialNumber'}
                                parseError={parseError}
                                fullWidth
                                label='Numer seryjny'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                name={'inventoryNumber'}
                                parseError={parseError}
                                fullWidth
                                label='Numer inwentaryzacyjny'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldElement
                                name={'value'}
                                parseError={parseError}
                                fullWidth
                                label='Wartość'
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <AutocompleteElement
                                loading={loadingEmployees}
                                size={'small'}
                                label={'Osoba przypisana'}
                                fullWidth
                                name={'attributedPerson'}
                                options={employeeList}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldElement
                                name={'remarks'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                minRows={2}
                                label='Uwagi'
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={() => close(false)}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Zapisz
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default EditResourceRegisterModal
