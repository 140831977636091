import { useContext, useState, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import TokenContext from './store/token-context'
import './App.css'

import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
} from '@mui/material/styles'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import 'moment/locale/pl'

import Layout from './containers/layout/Layout'
import SignIn from './containers/SignIn/SignIn'
import ForgotPassword from './containers/ForgotPassword/ForgotPassword'
import ResetEmailSent from './containers/ResetEmailSent/ResetEmailSent'
import PasswordReset from './containers/PasswordReset/PasswordReset'
import PasswordResetSuccess from './containers/PasswordResetSuccess/PasswordResetSuccess'
import { GET } from './Helpers/fetch'
import '@fontsource-variable/montserrat'

moment.locale('pl')

const App = () => {
    const [primaryColor, setPrimaryColor] = useState('#10403B')
    const [secondaryColor, setSecondaryColor] = useState('#ff914d')
    const [loading, setLoading] = useState(true)

    const { isLoggedIn } = useContext(TokenContext)

    useEffect(() => {
        const getColor = async () => {
            const { data: result } = await GET('companySettings/getColors')
            const color = result.data[0]
            setPrimaryColor(color.mainColor)
            setSecondaryColor(color.secondaryColor)
        }
        if (isLoggedIn) {
            getColor()
        }
        setLoading(false)
    }, [isLoggedIn])

    const colors = createTheme({
        palette: {
            alert: {
                light: '#ffef62',
                main: '#ffeb3b',
                dark: '#b2a429',
                contrastText: 'rgba(0, 0, 0, 0.87)',
            },
            primary: {
                main: primaryColor,
            },
            secondary: {
                main: secondaryColor,
            },
            text: {
                primary: '#000000',
                white: '#ffffff',
            },
        },
        typography: {
            fontFamily: [
                'Montserrat Variable',
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
        },
    })

    let theme = createTheme(colors, {
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: colors.palette.primary.main,
                        color: colors.palette.text.white,
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small',
                },
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small',
                },
            },
        },
    })

    theme = responsiveFontSizes(theme)

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {!loading ? (
                <ThemeProvider theme={theme}>
                    <div className='App'>
                        <Routes>
                            {!isLoggedIn && (
                                <Route path='/signin' element={<SignIn />} />
                            )}
                            {isLoggedIn && (
                                <Route path='/app/*' element={<Layout />} />
                            )}
                            {!isLoggedIn && (
                                <Route
                                    path='/forgotpassword'
                                    element={<ForgotPassword />}
                                />
                            )}
                            {!isLoggedIn && (
                                <Route
                                    path='/resetemailsent'
                                    element={<ResetEmailSent />}
                                />
                            )}
                            {!isLoggedIn && (
                                <Route
                                    path='/passwordreset'
                                    element={<PasswordReset />}
                                />
                            )}
                            {!isLoggedIn && (
                                <Route
                                    path='/passwordresetsuccess'
                                    element={<PasswordResetSuccess />}
                                />
                            )}
                            {!isLoggedIn && (
                                <Route
                                    path='*'
                                    element={<Navigate to='/signin' />}
                                />
                            )}
                            {isLoggedIn && (
                                <Route
                                    path='*'
                                    element={<Navigate to='/app' />}
                                />
                            )}
                        </Routes>
                    </div>
                </ThemeProvider>
            ) : (
                <div></div>
            )}
        </LocalizationProvider>
    )
}

export default App
