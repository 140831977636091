import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { TokenContextProvider } from './store/token-context'

ReactDOM.render(
    <TokenContextProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </TokenContextProvider>,
    document.getElementById('root')
)
