import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import UserMenu from '../UserMenu/UserMenu'
import MenuIcon from '@mui/icons-material/Menu'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SupportIcon from '@mui/icons-material/Support'
import { Link } from 'react-router-dom'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import TokenContext from '../../store/token-context'
import { checkAdmin } from '../../Helpers/authCheck'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import { GET, GET_WITH_DOWNLOAD } from '../../Helpers/fetch'
import AskExpert from '../Modals/AskExpert/AskExpert'

const drawerWidth = 240

const Header = ({ changeOpen, open }) => {
    const [img, setImg] = useState()
    const [openModal, setOpenModal] = useState(false)

    const tokenData = useContext(TokenContext)

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: prop => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }))

    useEffect(() => {
        GET_WITH_DOWNLOAD('companySettings/getLogo').then(res => {
            const fileURL = URL.createObjectURL(res.data)
            setImg(fileURL)
        })
    }, [])

    return (
        <AppBar color='inherit' elevation={1} position='fixed' open={open}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <IconButton
                        size='large'
                        edge='start'
                        sx={{ mr: 2 }}
                        onClick={changeOpen}
                    >
                        <MenuIcon sx={{ color: 'primary.main' }} />
                    </IconButton>

                    <Box sx={{ flexGrow: 1 }} />
                    <img src={img} alt='logo' width={200} height={64} />
                    <Box sx={{ px: '20px' }}>
                        <Tooltip title='Pytanie do eksperta'>
                            <IconButton
                                onClick={() => setOpenModal(true)}
                                size='large'
                                edge='start'
                            >
                                <QuestionAnswerIcon
                                    sx={{ color: 'primary.main' }}
                                />
                            </IconButton>
                        </Tooltip>
                        {checkAdmin(tokenData.data.role) && (
                            <IconButton
                                component={Link}
                                to='ustawienia'
                                size='large'
                                color='inherit'
                            >
                                <SettingsIcon sx={{ color: 'primary.main' }} />
                            </IconButton>
                        )}
                    </Box>
                    <UserMenu logout={tokenData.logout} />
                </Toolbar>
            </Container>
            <AskExpert isOpen={openModal} setOpen={setOpenModal} />
        </AppBar>
    )
}

export default Header
