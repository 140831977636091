import { useEffect, useState, useContext } from 'react'
import { DELETE, GET, PATCH } from '../../../Helpers/fetch'
import columns from './columns'
import moment from 'moment'

import AddEmployeeAuthorizationsModal from '../../Modals/AddEmployeeAuthorizationsModal/AddEmployeeAuthorizationsModal'
import EditAuthorizationsModal from '../../Modals/EditAuthorizationsModal/EditAuthorizationsModal'
import DataTable from '../../DataTable/DataTable'

import Dialog from '@mui/material/Dialog/Dialog'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogContentText from '@mui/material/DialogContentText/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import Modal from '@mui/material/Modal/Modal'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { TableCell, TableRow, Typography } from '@mui/material'
import Box from '@mui/material/Box/Box'
import CustomToolbar from '../CustomToolbar/CustomToolbar'
import Snackbar from '../../Snackbar/Snackbar'
import TokenContext from '../../../store/token-context'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'employeeName', direction: 'asc' },
}

const AuthorizationsTable = () => {
    const [data, setData] = useState([])
    const [count, setCount] = useState(1)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [deleteDataLoading, setDeleteDataLoading] = useState(false)
    const [editedRow, setEditedRow] = useState(0)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [optionValues, setOptionValues] = useState([])
    const [departmentsValues, setDepartmentsValues] = useState([])
    const [actionsAnchor, setActionsAnchor] = useState(null)
    const [snackbarInfo, setSnackbarInfo] = useState({})

    const tokenData = useContext(TokenContext)

    const actionsIsOpen = Boolean(actionsAnchor)

    const actionsClickHandler = (event, index) => {
        setActionsAnchor(event.currentTarget)
        setEditedRow(index)
    }

    const actionsCloseHandler = () => {
        setActionsAnchor(null)
    }

    const addModalCloseHandler = refresh => {
        setOpenAddModal(false)
        if (refresh) setRefreshData(!refreshData)
    }

    const editHandler = () => {
        actionsCloseHandler()
        setOpenEditModal(true)
    }

    const deleteHandler = () => {
        actionsCloseHandler()
        setDeleteDataLoading(true)
        const documentId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('employeeAuthorizations/delete/' + documentId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteDataLoading(false)
                setOpenDeleteDialog(false)
            })
            .catch(err => {
                setDeleteDataLoading(false)
                console.error(err)
            })
    }

    const archiveHandler = () => {
        actionsCloseHandler()
        const documentId = data[editedRow]._id

        const archiveData = async () => {
            await PATCH('employeeAuthorizations/archive/' + documentId)
        }
        archiveData()
            .then(() => {
                setRefreshData(!refreshData)
            })
            .catch(err => {
                console.error(err)
            })
    }
    const unarchiveHandler = () => {
        actionsCloseHandler()
        const documentId = data[editedRow]._id

        const archiveData = async () => {
            await PATCH('employeeAuthorizations/unarchive/' + documentId)
        }
        archiveData()
            .then(() => {
                setRefreshData(!refreshData)
            })
            .catch(err => {
                console.error(err)
            })
    }
    const confirmHandler = () => {
        actionsCloseHandler()
        const documentId = data[editedRow]._id

        const confirmData = async () => {
            await PATCH('employeeAuthorizations/confirm/' + documentId)
        }
        confirmData()
            .then(() => {
                setRefreshData(!refreshData)
            })
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        const getOptionValues = async () => {
            const { data: Items } = await GET('register/get')
            const optionValues = Items.data.map(el => el.name)
            setOptionValues([...optionValues])
        }
        const getDepartmentsValues = async () => {
            const { data: Items } = await GET('employee/getDepartments')
            const values = Items.data.map(el => el.name)
            setDepartmentsValues([...values])
        }
        getDepartmentsValues().catch(console.error)
        getOptionValues().catch(console.error)
    }, [refreshData])

    const importedColumns = columns({
        actionsClickHandler,
        actionsAnchor,
        actionsIsOpen,
        isArchive: data[editedRow]?.isArchive,
        isUnconfirmed: data[editedRow]?.isUnconfirmed,
        optionValues,
        departmentsValues,
        actionsCloseHandler,
        editHandler,
        archiveHandler,
        unarchiveHandler,
        confirmHandler,
        setOpenDeleteDialog,
        role: tokenData.data.role,
    })

    const getCsvData = async () => {
        const result = await GET('employeeAuthorizations/getCsv')
        const { data: resultData } = result.data
        const csvData = resultData.map(el => ({
            ...el,
            authorizations: el.authorizations.map(val => val.authName),
            departments: el?.departmens.map(val => val.id),
            zones: el.zones.map(val => val.id),
            addDate: moment(el.addDate).format('DD-MM-YYYY'),
            expiryDate: moment(el.expiryDate).format('DD-MM-YYYY'),
            untilFurtherNotice: el.untilFurtherNotice ? 'Tak' : 'Nie',
            isArchive: el.isArchive ? 'Tak' : 'Nie',
        }))
        return csvData
    }

    const csvHeaders = [
        { label: 'Pracownik', key: 'employeeName' },
        { label: 'Podmiot upoważniający', key: 'authorizing' },
        { label: 'Dział', key: 'departments' },
        { label: 'Upoważnienia', key: 'authorizations' },
        { label: 'Aplikacje', key: 'apps' },
        { label: 'Lokalizacje', key: 'zones' },
        { label: 'Data nadania', key: 'addDate' },
        { label: 'Data wygaśnięcia', key: 'expiryDate' },
        { label: 'Do odwołania', key: 'untilFurtherNotice' },
        { label: 'Dodane przez', key: 'addedBy' },
        { label: 'Archiwalne', key: 'isArchive' },
    ]

    const expandableRow = (rowData, rowMeta, data, columns) => {
        const colSpan = rowData.length + 1
        const currentRowData = data[rowMeta.dataIndex]
        return columns
            .filter(col => col?.options?.display === false)
            .map(
                (el, ix) =>
                    ((el.name === 'cancellationDate' &&
                        currentRowData[el.name] !== null) ||
                        el.name !== 'cancellationDate') && (
                        <TableRow key={el.label + rowMeta.dataIndex + ix}>
                            <TableCell colSpan={1}></TableCell>
                            <TableCell align='right' colSpan={2}>
                                {' '}
                                {el.label}
                            </TableCell>
                            <TableCell colSpan={colSpan}>
                                {' '}
                                {el?.options?.customBodyRender
                                    ? el.options.customBodyRender(
                                          currentRowData[el.name]
                                      )
                                    : currentRowData[el.name]}
                            </TableCell>
                        </TableRow>
                    )
            )
    }

    const options = {
        customToolbar: () => (
            <CustomToolbar
                onClick={() => setOpenAddModal(true)}
                download
                getCsvData={getCsvData}
                headers={csvHeaders}
            />
        ),
    }

    const legend = (
        <Box sx={{ padding: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        height: 25,
                        width: 25,
                        borderRadius: 1,
                        backgroundColor: '#ffe3e2',
                        mr: 1,
                    }}
                />
                <Typography> - zarchiwizowane</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <Box
                    sx={{
                        height: 25,
                        width: 25,
                        borderRadius: 1,
                        backgroundColor: '#fff8e3',
                        mr: 1,
                    }}
                />
                <Typography> - niepotwierdzone</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <Box
                    sx={{
                        height: 25,
                        width: 25,
                        borderRadius: 1,
                        backgroundColor: '#d23030',
                        mr: 1,
                    }}
                />
                <Typography> - wygasło</Typography>
            </Box>
        </Box>
    )

    return (
        <>
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            <DataTable
                title={'Upoważnienia'}
                url={'employeeAuthorizations/get'}
                data={data}
                setData={setData}
                expandableRows={true}
                expandableRowsHeader={false}
                renderExpandableRow={expandableRow}
                count={count}
                setCount={setCount}
                initalOptionsState={optionsState}
                pdf
                legend={legend}
                passedOptions={options}
                columns={importedColumns}
                firstLoading={loading}
                setFirstLoading={setLoading}
                refreshData={refreshData}
            />
            {!loading && (
                <Modal
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                >
                    <DialogContent>
                        <EditAuthorizationsModal
                            data={data[editedRow]}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenEditModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            {!loading && (
                <Modal open={openAddModal} onClose={addModalCloseHandler}>
                    <DialogContent>
                        <AddEmployeeAuthorizationsModal
                            setSnackbarInfo={setSnackbarInfo}
                            close={addModalCloseHandler}
                        />
                    </DialogContent>
                </Modal>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>
                    Czy na pewno usunąć upoważnienia tego pracownika?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie danych. Czy
                        na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDeleteDialog(false)}
                        autofocus
                    >
                        Anuluj
                    </Button>
                    <LoadingButton
                        loading={deleteDataLoading}
                        onClick={deleteHandler}
                    >
                        Potwierdź
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AuthorizationsTable
