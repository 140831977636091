import { useContext, useState } from 'react'
import TokenContext from '../../store/token-context'
import { checkAdmin, checkEdit, moduleCheck } from '../../Helpers/authCheck'
import logo from '../../assets/images/logo_5.png'

//icons
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ShieldIcon from '@mui/icons-material/ShieldOutlined'
// import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import ApprovalIcon from '@mui/icons-material/ApprovalOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import SchoolIcon from '@mui/icons-material/SchoolOutlined'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import DevicesIcon from '@mui/icons-material/Devices'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined'
import KeyIcon from '@mui/icons-material/Key'
// import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'

//mui
import { styled } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import SideMenuButton from './SideMenuButton/SideMenuButton'
import Box from '@mui/material/Box/Box'

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}))

const SideMenu = ({ open }) => {
    const [politicsOpen, setPoliticsOpen] = useState(false)
    const [registersOpen, setRegistersOpen] = useState(false)
    const [trainingsOpen, setTrainingsOpen] = useState(false)

    const { data: tokenData } = useContext(TokenContext)

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant='persistent'
            anchor='left'
            open={open}
        >
            <DrawerHeader>
                <Box sx={{ pt: '5px', pb: '5px', height: '54px' }}>
                    <img src={logo} height={'100%'} alt={'Aplikacja Rodo.pl'} />
                </Box>
            </DrawerHeader>
            <Divider />
            <List>
                <SideMenuButton
                    to='moje-view'
                    icon={
                        <DesktopWindowsOutlinedIcon
                            sx={{ color: 'text.white' }}
                        />
                    }
                    text='Moje View'
                />
                {checkEdit(tokenData.role) && (
                    <SideMenuButton
                        to='menadzer'
                        icon={<DashboardIcon sx={{ color: 'text.white' }} />}
                        text='Menadżer'
                    />
                )}
                <SideMenuButton
                    to='moje-szkolenia'
                    icon={<SchoolIcon sx={{ color: 'text.white' }} />}
                    text='Moje szkolenia'
                />
                <SideMenuButton
                    to='moje-upowaznienia'
                    icon={
                        <AssignmentTurnedInOutlinedIcon
                            sx={{ color: 'text.white' }}
                        />
                    }
                    text='Moje upoważnienia'
                />
                <SideMenuButton
                    to='moje-zasoby'
                    icon={<DevicesIcon sx={{ color: 'text.white' }} />}
                    text='Moje zasoby'
                />
                <SideMenuButton
                    to='baza-wiedzy'
                    icon={<LiveHelpOutlinedIcon sx={{ color: 'text.white' }} />}
                    text='Baza wiedzy'
                />

                <Divider />

                <SideMenuButton
                    to='rejestr-zasobow'
                    icon={<DevicesIcon sx={{ color: 'text.white' }} />}
                    text='Zasoby'
                    module='Rejestr zasobów'
                />
                <SideMenuButton
                    to='zgody'
                    icon={<ApprovalIcon sx={{ color: 'text.white' }} />}
                    text='Zgody'
                    module='Zgody'
                />
                <SideMenuButton
                    to='analiza-incydentu'
                    icon={<CrisisAlertIcon sx={{ color: 'text.white' }} />}
                    text='Analiza incydentu'
                    module='Analiza incydentu'
                />
                <SideMenuButton
                    to='analiza-ryzyka'
                    icon={
                        <QueryStatsOutlinedIcon sx={{ color: 'text.white' }} />
                    }
                    text='Analiza ryzyka'
                    module='Analiza ryzyka'
                />

                <Divider />

                {/* <SideMenuButton
                    to='kandydaci'
                    icon={
                        <PersonAddAltOutlinedIcon
                            sx={{ color: 'text.white' }}
                        />
                    }
                    text='Kandydaci'
                    module='Kandydaci'
                /> */}

                <SideMenuButton
                    to='upowaznienia'
                    icon={<BadgeOutlinedIcon sx={{ color: 'text.white' }} />}
                    text='Upoważnienia'
                    module='Upoważnienia'
                />
                <SideMenuButton
                    to='klucze'
                    icon={<KeyIcon sx={{ color: 'text.white' }} />}
                    text='Klucze'
                    module='Klucze'
                />

                {moduleCheck(
                    ['Polityka bezpieczenstwa', 'Polityka informacyjna'],
                    tokenData.moduleAccess,
                    tokenData.role
                ) && (
                    <>
                        <ListItem
                            onClick={() => setPoliticsOpen(!politicsOpen)}
                        >
                            <ListItemIcon>
                                <ShieldIcon sx={{ color: 'text.white' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: '0.9em' }}
                                primary='Polityki'
                            />
                            {politicsOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Collapse
                            in={politicsOpen}
                            timeout='auto'
                            unmountOnExit
                        >
                            <List component='div' disablePadding>
                                <SideMenuButton
                                    to='polityka-bezpieczenstwa'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Polityka bezpieczeństwa'
                                    module='Polityka bezpieczenstwa'
                                    nested
                                />
                                <SideMenuButton
                                    to='polityka-informacyjna'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Polityka informacyjna'
                                    module='Polityka informacyjna'
                                    nested
                                />
                            </List>
                        </Collapse>
                    </>
                )}
                {moduleCheck(
                    [
                        'Rejestr czynności przetwarzania',
                        'Rejestr incydentów',
                        'Rejestr kategorii przetwarzania',
                        'Rejestr umów powierzenia',
                        'Rejestr żądań',
                        'Rejestr systemów informatycznych',
                    ],
                    tokenData.moduleAccess,
                    tokenData.role
                ) && (
                    <>
                        <ListItemButton
                            onClick={() => setRegistersOpen(!registersOpen)}
                        >
                            <ListItemIcon>
                                <AssignmentOutlinedIcon
                                    sx={{ color: 'text.white' }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: '0.9em' }}
                                primary='Rejestry'
                            />
                            {registersOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse
                            in={registersOpen}
                            timeout='auto'
                            unmountOnExit
                        >
                            <List component='div' disablePadding>
                                <SideMenuButton
                                    to='rejestr-czynnosci-przetwarzania'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Rejestr czynności przetwarzania'
                                    module='Rejestr czynności przetwarzania'
                                    nested
                                />
                                <SideMenuButton
                                    to='rejestr-incydentow'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Rejestr incydentów'
                                    module='Rejestr incydentów'
                                    nested
                                />
                                <SideMenuButton
                                    to='rejestr-kategorii-przetwarzania'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Rejestr kategorii przetwarzania'
                                    module='Rejestr kategorii przetwarzania'
                                    nested
                                />
                                <SideMenuButton
                                    to='rejestr-umow-powierzenia'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Rejestr umów powierzenia'
                                    module='Rejestr umów powierzenia'
                                    nested
                                />
                                <SideMenuButton
                                    to='rejestr-zadan'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Rejestr żądań'
                                    module='Rejestr żądań'
                                    nested
                                />

                                <SideMenuButton
                                    to='rejestr-systemow-informatycznych'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Rejestr systemów informatycznych'
                                    module='Rejestr systemów informatycznych'
                                    nested
                                />
                            </List>
                        </Collapse>
                    </>
                )}
                <SideMenuButton
                    to='sprawdzenia-planowe'
                    icon={<CheckCircleIcon sx={{ color: 'text.white' }} />}
                    text='Sprawdzenia planowe'
                    module='Sprawdzenia planowe'
                />
                <SideMenuButton
                    to='sprawozdania-z-audytu'
                    icon={
                        <SummarizeOutlinedIcon sx={{ color: 'text.white' }} />
                    }
                    text='Sprawozdania z audytu'
                    module='Sprawozdania z audytu'
                />

                <SideMenuButton
                    to='uchwaly-i-zarzadzenia'
                    icon={<GavelOutlinedIcon sx={{ color: 'text.white' }} />}
                    text='Uchwały i zarządzenia'
                    module='Uchwały i zarządzenia'
                />

                <Divider />

                {moduleCheck(
                    ['Szkolenia pracowników', 'Lista szkoleń'],
                    tokenData.moduleAccess,
                    tokenData.role
                ) && (
                    <>
                        <ListItemButton
                            onClick={() => setTrainingsOpen(!trainingsOpen)}
                        >
                            <ListItemIcon>
                                <MenuBookOutlinedIcon
                                    sx={{ color: 'text.white' }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: '0.9em' }}
                                primary='Szkolenia'
                            />
                            {trainingsOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse
                            in={trainingsOpen}
                            timeout='auto'
                            unmountOnExit
                        >
                            <List component='div' disablePadding>
                                <SideMenuButton
                                    to='szkolenia-pracownikow'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Szkolenia pracowników'
                                    module='Szkolenia pracowników'
                                    nested
                                />
                                <SideMenuButton
                                    to='lista-szkolen'
                                    icon={
                                        <ChevronRightIcon
                                            sx={{ color: 'text.white' }}
                                        />
                                    }
                                    text='Lista szkoleń'
                                    module='Lista szkoleń'
                                    nested
                                />
                            </List>
                        </Collapse>
                    </>
                )}
                {checkAdmin(tokenData.role) && (
                    <SideMenuButton
                        to='uzytkownicy'
                        icon={
                            <PersonOutlineIcon sx={{ color: 'text.white' }} />
                        }
                        text='Użytkownicy'
                        module='Użytkownicy'
                    />
                )}
                <SideMenuButton
                    to='pracownicy'
                    icon={<GroupOutlinedIcon sx={{ color: 'text.white' }} />}
                    text='Pracownicy'
                    module='Pracownicy'
                />
            </List>
            <Divider />
        </Drawer>
    )
}

export default SideMenu
