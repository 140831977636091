import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import {
    FormContainer,
    DatePickerElement,
    TextFieldElement,
} from 'react-hook-form-mui'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import Button from '@mui/material/Button/Button'
import { Input } from '@mui/material'
import { forwardRef, useState } from 'react'
import { parseError } from '../../../Helpers/formValidation'
import { POST_WITH_FILE } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const EditIncidentRegisterModal = forwardRef(
    ({ close, setSnackbarInfo, data, refreshData }, ref) => {
        const [loading, setLoading] = useState(false)
        const [file, setFile] = useState()

        const uploadFileHandler = event => {
            const fileData = event.target.files[0]
            setFile(fileData)
        }

        const editIncRegHandler = data => {
            const formData = new FormData()
            formData.append('data', JSON.stringify({ ...data }))
            formData.append('file', file)
            const editRegister = async () => {
                await POST_WITH_FILE('incidentRegister/edit', formData)
            }
            editRegister()
                .then(() => {
                    setSnackbarInfo({
                        open: true,
                        type: 'success',
                        message: 'Dokonano edycji rejestru',
                    })
                    setLoading(false)
                    refreshData()
                    close(true)
                })
                .catch(() => {
                    setLoading(false)
                    setSnackbarInfo({
                        open: true,
                        type: 'error',
                        message: 'Błąd: Nie dokonano edycji rejestru',
                    })
                })
        }

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant='h6'>
                    Edycja wpisu w rejestrze incydentów
                </Typography>
                <Divider />
                <FormContainer
                    onSuccess={editIncRegHandler}
                    defaultValues={data}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={10}>
                            <DatePickerElement
                                name={'violationDate'}
                                parseError={parseError}
                                fullWidth
                                autoFocus
                                label='Data stwierdzenia naruszenia'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'violationNature'}
                                parseError={parseError}
                                fullWidth
                                minRows={3}
                                maxRows={4}
                                multiline
                                label='Charakter naruszenia'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'injuredPersonsCategories'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                label='Kategorie poszkodowanych osób'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'numberOfPeople'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                label='Ilość osób'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'numberOfViolatedEntries'}
                                parseError={parseError}
                                fullWidth
                                minRows={3}
                                maxRows={4}
                                multiline
                                label='Ilość naruszonych wpisów/rekordów'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <DatePickerElement
                                name={'uodoDate'}
                                parseError={parseError}
                                fullWidth
                                minRows={3}
                                maxRows={4}
                                multiline
                                label='Data zgłoszenia do UODO'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'breachConsequences'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                label='Możliwe konsekwencje naruszenia'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'remedies'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                label='Środki zaradcze'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'incidentsCircumstances'}
                                parseError={parseError}
                                fullWidth
                                minRows={3}
                                maxRows={4}
                                multiline
                                label='Okoliczności incydentu'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                border={1}
                                borderRadius={'4px'}
                                borderColor='#c4c4c4'
                            >
                                <label htmlFor='contained-button-file'>
                                    <Input
                                        sx={{ display: 'none' }}
                                        onChange={uploadFileHandler}
                                        accept='.pdf'
                                        id='contained-button-file'
                                        multiple
                                        type='file'
                                    />

                                    <Button
                                        sx={{
                                            borderRight: 1,
                                            borderRadius: '4px',
                                            borderColor: '#c4c4c4',
                                            pr: 2,
                                            pl: 2,
                                        }}
                                        variant='text'
                                        component='span'
                                    >
                                        Historia korespondencji (PDF)
                                    </Button>
                                </label>
                                <Typography
                                    noWrap
                                    sx={{
                                        ml: 1,
                                        color: 'text',
                                        width: '50%',
                                    }}
                                >
                                    {file?.name
                                        ? file.name
                                        : data?.fileName
                                        ? 'załącznik.pdf'
                                        : ''}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={close}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Zapisz
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default EditIncidentRegisterModal
