import moment from 'moment'
import Box from '@mui/material/Box/Box'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import { GET_WITH_DOWNLOAD_NAMED } from '../../../Helpers/fetch'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'

const downloadFile = id => {
    GET_WITH_DOWNLOAD_NAMED(`/informationPolicies/download/${id}`)
}

const columns = props => [
    { name: 'documentName', label: 'Nazwa dokumentu' },
    {
        name: 'effectiveDate',
        label: 'Data wprowadzenia w życie',
        options: {
            customBodyRender: value => moment(value).format('D.MM.YYYY'),
        },
    },
    {
        name: 'expiryDate',
        label: 'Data wygaśnięcia',
        options: {
            customBodyRender: value =>
                value ? moment(value).format('D.MM.YYYY') : 'Do odwołania',
        },
    },
    {
        name: 'additionDate',
        label: 'Data dodania',
        options: {
            customBodyRender: value => moment(value).format('D.MM.YYYY'),
        },
    },
    {
        name: 'fileName',
        label: 'Załącznik',
        options: {
            customBodyRender: (value, tableMeta) => {
                return (
                    value && (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Tooltip title='Pobierz załącznik'>
                                <IconButton
                                    onClick={() =>
                                        downloadFile(
                                            props.data[tableMeta.rowIndex]._id
                                        )
                                    }
                                >
                                    <SimCardDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                )
            },
        },
    },
    { name: 'remarks', label: 'Uwagi', options: { filter: false } },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={() => props.editHandler()}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
