import { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import Logo from '../../assets/svg/rodopl-logo.svg'
import BackgroundImage from '../../assets/svg/prism.svg'
import { POST } from '../../Helpers/fetch'

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                },
            },
        },
    },
})

export default function PasswordReset() {
    const [firstPassword, setFirstPassword] = useState('')
    const [, setLoading] = useState(false)
    const [secondPassword, setSecondPassword] = useState('')
    const [active, setActive] = useState(true)
    const [error, setError] = useState('')
    const [code, setCode] = useState('')
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const changePasswordHandler = async event => {
        setLoading(true)
        event.preventDefault()
        var error = ''
        if (firstPassword === '') {
            error = 'Nie podano hasła'
        }
        if (firstPassword !== secondPassword) {
            error = 'Podane hasła są różne'
        }
        if (error === '') {
            const { status } = await POST('auth/setnewpassword', {
                newPassword: firstPassword,
                code: code,
            }).catch(console.error)
            if (status === 200) {
                navigate('/passwordresetsuccess')
            } else {
                error = 'Wystąpił nieznany bład'
            }
        }
        setError(error)
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        const code = searchParams.get('code')
        setCode(code)
        const getData = async code => {
            const { data: Res } = await POST(
                'auth/requestpasswordresetstatus/' + code
            ).catch(err => err.response)
            if (Res.status === 'active') {
                setActive(true)
            } else {
                setActive(false)
            }
        }
        getData(code).catch(console.error)
        setLoading(false)
    }, [searchParams])

    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Box sx={{ height: 80 }}>
                        <img
                            style={{ height: '100%' }}
                            src={Logo}
                            alt={'Logo'}
                        />
                    </Box>
                    {active ? (
                        <Box
                            component='form'
                            onSubmit={event => changePasswordHandler(event)}
                            noValidate
                            sx={{ mt: 1 }}
                        >
                            Ustaw nowe hasło.
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                id='firstPassword'
                                label='Nowe hasło'
                                type='password'
                                name='firstPassword'
                                value={firstPassword}
                                autoFocus
                                onChange={e => setFirstPassword(e.target.value)}
                            />
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                name='seconPassword'
                                label='Powtórz nowe hasło'
                                type='password'
                                id='secondPassword'
                                value={secondPassword}
                                onChange={e =>
                                    setSecondPassword(e.target.value)
                                }
                            />
                            {error}
                            <LoadingButton
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Zmień hasło
                            </LoadingButton>
                        </Box>
                    ) : (
                        <p> link wygasł </p>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    )
}
