import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'
import moment from 'moment'

const columns = props => [
    { name: 'keyCode', label: 'Kod klucza' },
    {
        name: 'rooms',
        label: 'Pomieszczenie',
    },
    {
        name: 'handingPerson',
        label: 'Osoba podejmująca klucz',
    },
    {
        name: 'dateOfHanding',
        label: 'Data podjęcia klucza',
        options: {
            filter: false,
            customBodyRender: value =>
                value ? moment(value).format('DD.MM.YYYY HH:mm') : '',
        },
    },
    {
        name: 'returnPerson',
        label: 'Osoba zdająca klucz',
    },
    {
        name: 'dateOfReturn',
        label: 'Data zdania klucza',
        options: {
            filter: false,
            customBodyRender: value =>
                value ? moment(value).format('DD.MM.YYYY HH:mm') : '',
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={() => props.editHandler()}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkEdit(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.handingHandler(props.isReturned)
                                    }
                                >
                                    {props.isReturned
                                        ? 'Wydaj klucz'
                                        : 'Zdaj klucz'}
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
