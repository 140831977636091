export const moduleCheck = (module, data, role) => {
    let isAuth

    if (Array.isArray(module)) {
        isAuth = module.some(r => data.includes(r))
    } else {
        isAuth = data.includes(module)
    }

    if (isAuth || role === 'Administrator') {
        return true
    } else return false
}

export const checkAdmin = role => {
    if (role === 'Administrator') return true
    else return false
}

export const checkEdit = role => {
    if (role === 'Administrator' || role === 'Moderator') return true
    else return false
}

export const checkDelete = role => {
    if (role === 'Administrator') return true
    else return false
}
