export const parseError = fieldError => {
    if (fieldError.type === 'required') {
        return 'Pole jest wymagane'
    }
    if (fieldError.type === 'pattern' && fieldError.ref.name === 'email') {
        return 'Niepoprawny adres e-mail'
    } else if (fieldError.type === 'pattern') {
        return fieldError.message
    }
    if (fieldError.type === 'maxLength') {
        return fieldError.message
    }
}

export const numberValidation = {
    pattern: {
        value: /^\d+$/,
        message: 'Niepoprawna liczba',
    },
}

export const phoneValidation = {
    pattern: {
        value: /^\d{9}$/,
        message: 'Niepoprawny numer telefonu',
    },
}

export const secondPhoneValidation = {
    pattern: {
        value: /^\d{9}$/,
        message: 'Niepoprawny numer telefonu',
    },
}

export const postalCodeValidation = {
    required: false,
    pattern: {
        value: /^\d{2}-\d{3}$/,
        message: 'Niepoprawny kod pocztowy',
    },
}
