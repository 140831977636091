import { useState } from 'react'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { POST } from '../../../Helpers/fetch'

export default function AskExpert({ isOpen, setOpen }) {
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const sendMessage = () => {
        setLoading(true)
        POST('users/askExpert', { subject, message })
            .then(res => {
                handleClose()
                setLoading(false)
            })
            .catch(err => {
                handleClose()
                setLoading(false)
            })
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='sm'
            >
                <DialogTitle>Wyślij pytanie do eksperta</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin='dense'
                        id='name'
                        label='Temat'
                        fullWidth
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label='Treść'
                        required
                        fullWidth
                        multiline
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        minRows={3}
                        maxRows={5}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Zamknij</Button>
                    <Button onClick={sendMessage}>Wyślij</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
