import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import Divider from '@mui/material/Divider/Divider'
import { Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { forwardRef, useState } from 'react'
import { parseError } from '../../../Helpers/formValidation'
import { POST } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const AddKeysRegisterModal = forwardRef(({ close, setSnackbarInfo }, ref) => {
    const [loading, setLoading] = useState(false)

    const addKeysRegHandler = data => {
        setLoading(true)
        const addKey = async () => {
            await POST('keysRegister/add', { data })
        }
        addKey()
            .then(() => {
                setSnackbarInfo({
                    open: true,
                    type: 'success',
                    message: 'Dodano wpis do rejestru',
                })
                setLoading(false)
                close(true)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setSnackbarInfo({
                    open: true,
                    type: 'error',
                    message: 'Błąd: Nie dodano wpisu',
                })
            })
    }

    return (
        <Box sx={style} ref={ref}>
            <Typography gutterBottom variant='h6'>
                Dodawanie wpisu do rejestru kluczy
            </Typography>
            <Divider />
            <FormContainer onSuccess={addKeysRegHandler}>
                <Grid container sx={{ mt: 1 }} spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldElement
                            name={'keyCode'}
                            parseError={parseError}
                            fullWidth
                            autoFocus
                            required
                            label='Kod Klucza'
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextFieldElement
                            name={'rooms'}
                            parseError={parseError}
                            fullWidth
                            required
                            label='Pomieszczenie'
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                    }}
                >
                    <Button
                        sx={{ mr: 1 }}
                        variant='outlined'
                        onClick={() => close(false)}
                    >
                        Anuluj
                    </Button>
                    <LoadingButton
                        loading={loading}
                        type={'submit'}
                        variant='contained'
                    >
                        Dodaj
                    </LoadingButton>
                </Box>
            </FormContainer>
        </Box>
    )
})

export default AddKeysRegisterModal
