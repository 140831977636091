import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import ReactPlayer from 'react-player'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { ListItemButton, ListSubheader } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

//mui
import Box from '@mui/material/Box/Box'
import Paper from '@mui/material/Paper/Paper'
import TokenContext from '../../store/token-context'

import { GET_WITH_DOWNLOAD, GET } from '../../Helpers/fetch'

const Materials = () => {
    const [loading, setLoading] = useState(false)
    const [materials, setMaterials] = useState([])
    const [list, setList] = useState([])
    const [active, setActive] = useState(1)
    const [videoUrl, setVideoUrl] = useState('')
    const { trainingId } = useParams()

    const { token } = useContext(TokenContext)

    const changeActive = number => {
        setActive(number)
    }

    useEffect(() => {
        const getMaterials = async () => {
            const response = await GET('materials/getMaterials/' + trainingId)
            setMaterials(response.data.data)
        }

        getMaterials()
    }, [])

    useEffect(() => {
        setLoading(true)
        const url = `${
            window.location.origin + '/api'
        }/materials/getVideo?id=${trainingId}&number=${active}`
        const fetchVideo = async () => {
            const response = await GET_WITH_DOWNLOAD(url)

            const videoBlob = new Blob([response.data], { type: 'video/mp4' })

            const videoObjectUrl = URL.createObjectURL(videoBlob)
            setVideoUrl(videoObjectUrl)
            setLoading(false)
        }
        fetchVideo()
    }, [active])

    return (
        <Box
            sx={{
                height: '80vh',
                textAlign: 'left',
                padding: 2,
            }}
        >
            {loading ? (
                <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                    <CircularProgress />
                </Paper>
            ) : (
                <>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                        >
                            <ReactPlayer controls url={videoUrl} />

                            <Box
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    borderLeft: '1px solid #bababa',
                                    maxHeight: '40vh',
                                    overflow: 'auto',
                                }}
                            >
                                <List
                                    subheader={
                                        <ListSubheader
                                            component='div'
                                            id='nested-list-subheader'
                                        >
                                            Materiały
                                        </ListSubheader>
                                    }
                                >
                                    {materials.map((item, index) => (
                                        <ListItem
                                            key={index}
                                            // secondaryAction={
                                            //     <IconButton
                                            //         edge='end'
                                            //         aria-label='delete'
                                            //     >
                                            //         <DeleteIcon />
                                            //     </IconButton>
                                            // }
                                        >
                                            <ListItemButton
                                                selected={
                                                    active === item.number
                                                }
                                                onClick={() =>
                                                    changeActive(item.number)
                                                }
                                            >
                                                <ListItemText
                                                    primary={item.title}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    </Paper>
                </>
            )}
        </Box>
    )
}

export default Materials
