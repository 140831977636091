import DataTable from '../../DataTable/DataTable'
import { useState, useContext, useRef } from 'react'
import Modal from '@mui/material/Modal/Modal'
import moment from 'moment'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Typography,
} from '@mui/material'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { TableCell, TableRow } from '@mui/material'
import EditEmployeeModal from '../../Modals/EditEmployeeModal/EditEmployeeModal'
import AddEmployeeModal from '../../Modals/AddEmployeeModal/AddEmployeeModal'
import AddUserModal from '../../Modals/AddUserModal/AddUserModal'
import CustomToolbar from '../CustomToolbar/CustomToolbar'
import Snackbar from '../../Snackbar/Snackbar'
import {
    DELETE,
    DELETE_WITH_DOWNLOAD,
    GET,
    GET_WITH_DOWNLOAD,
} from '../../../Helpers/fetch'
import columns from './columns'
import TokenContext from '../../../store/token-context'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'
import fileDownload from 'js-file-download'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'firstName', direction: 'asc' },
}

const filtersAutocomplete = {
    contractType: [
        'Umowa o pracę',
        'Umowa zlecenie',
        'Umowa o dzieło',
        'B2B',
        'Staż',
        'Praktyka ',
    ],
}

const EmployeeTable = () => {
    const [data, setData] = useState([])
    const [count, setCount] = useState(1)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [deleteDataLoading, setDeleteDataLoading] = useState(false)
    const [editedRow, setEditedRow] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [openFireEmployeeDialog, setOpenFireEmployeeDialog] = useState(false)
    const [fireEmployeeLoading, setFireEmployeeLoading] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [userOpen, setUserOpen] = useState(false)
    const [employeeData, setEmployeeData] = useState({})
    const [snackbarInfo, setSnackbarInfo] = useState({})
    const [actionsAnchor, setActionsAnchor] = useState(null)

    const tokenData = useContext(TokenContext)

    const actionsIsOpen = Boolean(actionsAnchor)

    const actionsClickHandler = (event, index) => {
        setActionsAnchor(event.currentTarget)
        setEditedRow(index)
    }
    const actionsCloseHandler = () => {
        setActionsAnchor(null)
    }
    const editHandler = () => {
        actionsCloseHandler()
        setOpenModal(true)
    }
    const fireHandler = () => {
        actionsCloseHandler()
        setFireEmployeeLoading(true)
        const documentId = data[editedRow]._id

        const fire = async () => {
            await DELETE_WITH_DOWNLOAD('employee/fire/' + documentId).then(
                res => {
                    const fileURL = URL.createObjectURL(res.data)
                    window.open(fileURL)
                }
            )
        }
        fire()
            .then(() => {
                setRefreshData(!refreshData)
                setFireEmployeeLoading(false)
                setOpenFireEmployeeDialog(false)
            })
            .catch(err => {
                setFireEmployeeLoading(false)
                console.log(err)
            })
    }

    const downloadFirePdf = () => {
        actionsCloseHandler()
        const documentId = data[editedRow]._id

        GET_WITH_DOWNLOAD('employee/getFirePdf/' + documentId).then(res => {
            //const fileURL = URL.createObjectURL(res.data)
            //window.open(fileURL)
            fileDownload(res.data, 'Karta obiegowa.pdf')
        })
    }

    const addModalCloseHandler = (isCreateAccount, employeeData, refresh) => {
        setOpenAddModal(false)
        if (isCreateAccount) {
            setEmployeeData(employeeData)
            setUserOpen(true)
        }
        if (refresh) setRefreshData(!refreshData)
    }

    const deleteHandler = () => {
        actionsCloseHandler()
        setDeleteDataLoading(true)
        const documentId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('/employee/delete/' + documentId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteDataLoading(false)
                setOpenDeleteDialog(false)
            })
            .catch(err => {
                setDeleteDataLoading(false)
                console.error(err)
            })
    }

    const importedColumns = columns({
        actionsClickHandler,
        actionsAnchor,
        actionsCloseHandler,
        actionsIsOpen,
        editHandler,
        editedRow,
        setOpenFireEmployeeDialog,
        setOpenDeleteDialog,
        downloadFirePdf,
        isFired: data[editedRow]?.isFired,
        role: tokenData.data.role,
    })

    const getCsvData = async () => {
        const result = await GET('employee/getCsv')
        const { data: resultData } = result.data
        const csvData = resultData.map(el => ({
            ...el,
            haveAccount: el.haveAccount ? 'Tak' : 'Nie',
            hireDate: moment(el.hireDate).format('DD-MM-YYYY'),
        }))
        return csvData
    }

    const csvHeaders = [
        { label: 'Imię', key: 'firstName' },
        { label: 'Nazwisko', key: 'lastName' },
        { label: 'E-mail', key: 'email' },
        { label: 'Numer telefonu', key: 'phoneNumber' },
        { label: 'Stanowisko', key: 'position' },
        { label: 'Jednostki organizacyjne', key: 'organizationUnits' },
        { label: 'Działy', key: 'departmens' },
        { label: 'Data zatrudnienia', key: 'hireDate' },
        { label: 'Rodzaj umowy', key: 'contractType' },
    ]

    const expandableRow = (rowData, rowMeta, data, columns) => {
        const colSpan = rowData.length + 1
        const currentRowData = data[rowMeta.dataIndex]
        return columns
            .filter(col => col?.options?.display === false)
            .map((el, ix) => (
                <TableRow key={el.label + rowMeta.dataIndex + ix}>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell align='right' colSpan={2}>
                        {' '}
                        {el.label}
                    </TableCell>
                    <TableCell colSpan={colSpan}>
                        {' '}
                        {el?.options?.customBodyRender
                            ? el.options.customBodyRender(
                                  currentRowData[el.name]
                              )
                            : currentRowData[el.name]}
                    </TableCell>
                </TableRow>
            ))
    }

    const options = {
        customToolbar: () => (
            <CustomToolbar
                onClick={() => setOpenAddModal(true)}
                download
                getCsvData={getCsvData}
                headers={csvHeaders}
            />
        ),
    }

    const legend = (
        <Box sx={{ padding: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        height: 25,
                        width: 25,
                        borderRadius: 1,
                        backgroundColor: '#ffe3e2',
                        mr: 1,
                    }}
                />
                <Typography> - zwolniony</Typography>
            </Box>
        </Box>
    )

    // Using Ref for optimization
    const optionsRef = useRef(options)
    const expandableRowRef = useRef(expandableRow)
    const importedColumnsRef = useRef(importedColumns)

    return (
        <>
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}

            <DataTable
                title={'Pracownicy'}
                url={'employee/get'}
                data={data}
                setData={setData}
                expandableRows={true}
                expandableRowsHeader={false}
                renderExpandableRow={expandableRowRef.current}
                count={count}
                legend={legend}
                setCount={setCount}
                initalOptionsState={optionsState}
                passedOptions={optionsRef.current}
                columns={importedColumnsRef.current}
                firstLoading={loading}
                setFirstLoading={setLoading}
                filtersAutocomplete={filtersAutocomplete}
                refreshData={refreshData}
            />

            {!loading && (
                <Modal
                    open={openAddModal}
                    onClose={() => addModalCloseHandler()}
                >
                    <DialogContent>
                        <AddEmployeeModal
                            setSnackbarInfo={setSnackbarInfo}
                            close={addModalCloseHandler}
                        />
                    </DialogContent>
                </Modal>
            )}

            <Modal open={userOpen} onClose={() => setUserOpen(false)}>
                <AddUserModal
                    closeAndRefresh={() => setUserOpen(false)}
                    employeeData={employeeData}
                />
            </Modal>

            {!loading && (
                <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <DialogContent>
                        <EditEmployeeModal
                            data={data[editedRow]}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}

            <Dialog
                open={openFireEmployeeDialog}
                onClose={() => setOpenFireEmployeeDialog(false)}
            >
                <DialogTitle>
                    Czy na pewno chcesz zwolnić dane tego pracownika?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite wycofanie upoważnień
                        pracownika. Czy na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenFireEmployeeDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={fireEmployeeLoading}
                        onClick={fireHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>
                    Czy na pewno usunąć dane tego pracownika?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie danych
                        pracownika. Czy na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={deleteDataLoading}
                        onClick={deleteHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* Action Menu */}
            <Menu
                id={`action-menu-${editedRow}`}
                anchorEl={actionsAnchor}
                open={actionsIsOpen}
                onClose={actionsCloseHandler}
                elevation={1}
            >
                {checkEdit(tokenData.data.role) && (
                    <MenuItem onClick={editHandler}>Edytuj</MenuItem>
                )}
                {checkEdit(tokenData.data.role) && (
                    <MenuItem
                        disabled={data[editedRow]?.isFired}
                        onClick={() => setOpenFireEmployeeDialog(true)}
                    >
                        Zwolnij
                    </MenuItem>
                )}
                {checkEdit(tokenData.data.role) && (
                    <MenuItem
                        disabled={!data[editedRow]?.isFired}
                        onClick={downloadFirePdf}
                    >
                        Pobierz kartę obiegową
                    </MenuItem>
                )}
                {checkDelete(tokenData.data.role) && (
                    <MenuItem onClick={() => setOpenDeleteDialog(true)}>
                        Usuń
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default EmployeeTable
