import IconButton from '@mui/material/IconButton/IconButton'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'
import {
    GET_WITH_DOWNLOAD,
    GET_WITH_DOWNLOAD_NAMED,
} from '../../../Helpers/fetch'
import { FormControlLabel, Tooltip } from '@mui/material'
import Button from '@mui/material/Button/Button'
import Box from '@mui/material/Box/Box'
import Link from '@mui/material/Link/Link'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'

const downloadFile = async trainingId => {
    await GET_WITH_DOWNLOAD_NAMED(`/training/download/${trainingId}`)
}

const getCertficate = trainingId => {
    GET_WITH_DOWNLOAD('/training/getCertificate/' + trainingId).then(res => {
        var fileURL = URL.createObjectURL(res.data)
        window.open(fileURL)
        // fileDownload(res.data, 'Certyfikat.pdf')
    })
}

const columns = props => [
    { name: 'name', label: 'Nazwa szkolenia' },
    { name: 'description', label: 'Opis' },
    {
        name: 'isOnline',
        label: 'Typ',
        options: {
            customBodyRender: value => {
                return value ? 'Online' : 'Stacjonarnie'
            },
        },
    },
    // {
    //     name: 'materials',
    //     label: 'Materiały',
    //     options: {
    //         customBodyRenderLite: rowIndex => {
    //             const { videoLink, fileName } = props.data[rowIndex]
    //             return (
    //                 <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    //                     {fileName && (
    //                         <Tooltip title='Pobierz szkolenie'>
    //                             <IconButton
    //                                 onClick={() =>
    //                                     downloadFile(
    //                                         props.data[rowIndex].trainingId
    //                                     )
    //                                 }
    //                             >
    //                                 <SimCardDownloadOutlinedIcon />
    //                             </IconButton>
    //                         </Tooltip>
    //                     )}
    //                     {videoLink && (
    //                         <Tooltip title='Materiały wideo'>
    //                             <IconButton target='_blank' href={videoLink}>
    //                                 <OndemandVideoOutlinedIcon />
    //                             </IconButton>
    //                         </Tooltip>
    //                     )}
    //                 </Box>
    //             )
    //         },
    //     },
    // },
    {
        name: 'materials',
        label: 'Materiały',
        options: {
            customBodyRenderLite: rowIndex => (
                <Button
                    disabled={!props.data[rowIndex].isMaterials}
                    component={RouterLink}
                    to={`materialy/${props.data[rowIndex].trainingId}`}
                >
                    Materiały
                </Button>
            ),
        },
    },
    {
        name: 'test',
        label: 'Test',
        options: {
            customBodyRenderLite: rowIndex => (
                <Button
                    disabled={!props.data[rowIndex].isTest}
                    onClick={() => props.openTestModalHandler(rowIndex)}
                >
                    Test
                </Button>
            ),
        },
    },
    {
        name: 'testResult.score',
        label: 'Wynik Testu',
        options: {
            customBodyRender: value => (value ? value : 'Brak'),
        },
    },
    {
        name: 'testResult.isPassed',
        label: 'Czy zdany',
        options: {
            customBodyRender: value => (value ? 'Tak' : 'Nie'),
        },
    },
    {
        name: 'trainer',
        label: 'Prowadzący',
        options: {
            customBodyRender: value => (value ? value : 'Brak'),
        },
    },
    {
        name: 'trainingDate',
        label: 'Data szkolenia',
        options: {
            customBodyRender: (value, tableMeta) => {
                if (value) {
                    return moment(value).format('DD.MM.YYYY')
                } else {
                    return 'Brak'
                }
            },
        },
    },
    {
        name: 'completeDate',
        label: 'Data ukończenia',
        options: {
            customBodyRender: (value, tableMeta) => {
                const lastCol = tableMeta.rowData.length - 1 //isCompleted column number
                if (value) {
                    return moment(value).format('DD.MM.YYYY')
                } else if (tableMeta.rowData[lastCol]) {
                    return moment().format('DD.MM.YYYY')
                } else {
                    return 'Nie ukończono'
                }
            },
        },
    },
    {
        name: 'isCompleted',
        label: ' ',
        options: {
            customBodyRender: (value, tableMeta) => {
                const onClickHandler = () =>
                    value
                        ? getCertficate(
                              props.data[tableMeta.rowIndex].trainingId
                          )
                        : props.openDialogHandler(
                              props.data[tableMeta.rowIndex].trainingId
                          )

                return (
                    <Button onClick={onClickHandler}>
                        {value ? 'Certyfikat' : 'Potwierdź'}
                    </Button>
                )
            },
        },
    },
]

export default columns
