import { forwardRef, useState } from 'react'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { POST } from '../../../../Helpers/fetch'
import { parseError } from '../../../../Helpers/formValidation'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import { useForm, useFieldArray } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button/Button'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    bgcolor: 'background.paper',
    maxHeight: '90%',
    overflow: 'scroll',
    boxShadow: 24,
    p: 4,
}

const EditTestModal = forwardRef(
    ({ close, setSnackbarInfo, refreshData, trainingId, defaultData }, ref) => {
        const formContext = useForm({
            defaultValues: {
                test: defaultData,
            },
        })
        const { fields, append, remove } = useFieldArray({
            control: formContext.control,
            name: 'test',
        })

        const [loading, setLoading] = useState(false)

        const addQuestion = () =>
            append({
                text: '',
                answers: [
                    { text: '', isCorrect: true },
                    { text: '', isCorrect: false },
                    { text: '', isCorrect: false },
                    { text: '', isCorrect: false },
                ],
            })

        const editTestHandler = data => {
            setLoading(true)

            const editTest = async () => {
                await POST('training/editTest/' + trainingId, data)
            }
            editTest()
                .then(() => {
                    setSnackbarInfo({
                        open: true,
                        type: 'success',
                        message: 'Edytowano test',
                    })
                    setLoading(false)
                    if (!!refreshData) {
                        refreshData()
                    }
                    close()
                })
                .catch(() => {
                    setLoading(false)
                    setSnackbarInfo({
                        open: true,
                        type: 'error',
                        message: 'Błąd: Nie edytowano testu',
                    })
                })
        }

        return (
            <Box ref={ref} sx={style}>
                <Typography gutterBottom variant={'h5'}>
                    Edytuj test
                </Typography>
                <Divider />
                <FormContainer
                    formContext={formContext}
                    onSuccess={editTestHandler}
                >
                    {fields.map((item, index) => (
                        <Grid
                            key={item.id}
                            container
                            sx={{ mt: 1 }}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <Typography variant='subtitle1'>
                                        Pytanie {index + 1}
                                    </Typography>
                                    {fields.length > 1 && (
                                        <IconButton
                                            onClick={() => remove(index)}
                                            size='small'
                                        >
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldElement
                                    parseError={parseError}
                                    name={`test.${index}.text`}
                                    fullWidth
                                    required
                                    label={'Treść'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    parseError={parseError}
                                    name={`test.${index}.answers.0.text`}
                                    fullWidth
                                    required
                                    label={'Odpowiedź poprawna'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    parseError={parseError}
                                    name={`test.${index}.answers.1.text`}
                                    fullWidth
                                    required
                                    label={'Odpowiedź błędna'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    parseError={parseError}
                                    name={`test.${index}.answers.2.text`}
                                    fullWidth
                                    required
                                    label={'Odpowiedź błędna'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    parseError={parseError}
                                    name={`test.${index}.answers.3.text`}
                                    fullWidth
                                    required
                                    label={'Odpowiedź błędna'}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Box sx={{ textAlign: 'center', mt: 1 }}>
                        <Button variant='text' onClick={addQuestion}>
                            Dodaj następne pytanie
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={() => close(false)}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Zapisz
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default EditTestModal
