import {
    Chip,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    ListItemText,
} from '@mui/material'
import moment from 'moment'

const columns = ({ selectTraining }) => [
    {
        name: 'employeeName',
        label: 'Pracownik',
    },
    {
        name: 'trainings',
        label: 'Szkolenia',
        options: {
            sort: false,
            customBodyRender: (value, tableMeta) => {
                const data = value.map((el, index) => {
                    let color = 'default'
                    if (!el.isCompleted) {
                        color = 'alert'
                    } else if (
                        moment(el.expiryDate).toDate() < moment().toDate()
                    ) {
                        color = 'error'
                    } else if (
                        moment(el.expiryDate).toDate() <
                        moment().add(1, 'M').toDate()
                    ) {
                        color = 'warning'
                    }

                    return (
                        <Tooltip title='Kliknij po szczegóły'>
                            <Chip
                                key={index}
                                color={color}
                                onClick={() =>
                                    selectTraining(tableMeta.rowIndex, index)
                                }
                                sx={{ mr: 1, mb: 1 }}
                                label={el.name}
                            />
                        </Tooltip>
                    )
                })
                return data
            },
        },
    },
    {
        name: 'trainings.isCompleted',
        label: '',
        options: {
            display: 'excluded',
            filter: true,
            filterType: 'custom',
            sort: false,
            customFilterListOptions: {
                render: v => (v[0] === 'true' ? ['Tak'] : ['Nie']),
            },
            filterOptions: {
                display: (filterList, onChange, index, column) => {
                    const optionValues = [
                        { label: 'Tak', value: 'true' },
                        { label: 'Nie', value: 'false' },
                    ]

                    return (
                        <FormControl variant='outlined'>
                            <InputLabel id='filter-label'>Ukończone</InputLabel>
                            <Select
                                labelId={'filter-label'}
                                input={
                                    <OutlinedInput
                                        label='Ukończone'
                                        id='filter-label'
                                    />
                                }
                                value={filterList[index] || ''}
                                onChange={event => {
                                    filterList[index] = [event.target.value]
                                    onChange(filterList[index], index, column)
                                }}
                            >
                                {optionValues.map(item => (
                                    <MenuItem
                                        key={item.label}
                                        value={item.value}
                                    >
                                        <Tooltip title={item.label}>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    noWrap: true,
                                                    maxWidth: '300px',
                                                }}
                                                primary={item.label}
                                            />
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                },
            },
            customBodyRender: value => {
                return console.log(value)
            },
        },
    },
]

export default columns
