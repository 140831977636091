import Typography from '@mui/material/Typography/Typography'
import Box from '@mui/material/Box/Box'
import {
    FormContainer,
    AutocompleteElement,
    DateTimePickerElement,
} from 'react-hook-form-mui'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { forwardRef, useState, useEffect } from 'react'
import { POST, GET } from '../../../Helpers/fetch'
import Divider from '@mui/material/Divider/Divider'
import Grid from '@mui/material/Grid/Grid'
import moment from 'moment'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const HandingKeysModal = forwardRef(({ close, data, isReturn }, ref) => {
    const [loading, setLoading] = useState(false)
    const [employeeList, setEmployeeList] = useState([])
    const [loadingEmployees, setLoadingEmployees] = useState(false)

    const getEmployees = async () => {
        setLoadingEmployees(true)
        const { data: Items } = await GET('employee/get')
        const employees = Items.data.map(el => ({
            label: `${el.firstName} ${el.lastName} (${el.email})`,
            id: el._id,
        }))

        setEmployeeList([...employees])
        setLoadingEmployees(false)
    }

    const handingKeyHandler = userData => {
        setLoading(true)
        console.log(data)
        const edit = async () => {
            const link = isReturn
                ? 'keysRegister/returnKey'
                : 'keysRegister/handingKey'
            await POST(link, {
                _id: data._id,
                handingHistoryLastId: isReturn
                    ? data.handingHistoryLastId
                    : null,
                ...userData,
            })
        }
        edit()
            .then(() => {
                setLoading(false)
                close(true)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        getEmployees().catch(err => console.error(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={style} ref={ref}>
            <Typography gutterBottom variant={'h5'}>
                {isReturn ? 'Zdaj klucz ' : 'Wydaj klucz'}
            </Typography>
            <Divider />
            <FormContainer onSuccess={handingKeyHandler}>
                <Grid container sx={{ mt: 1 }} spacing={2}>
                    <Grid item xs={12}>
                        <AutocompleteElement
                            rules={{ required: 'Pole jest wymagane' }}
                            loading={loadingEmployees}
                            size={'small'}
                            required
                            label={'Pracownik'}
                            fullWidth
                            name={isReturn ? 'returnPerson' : 'handingPerson'}
                            options={employeeList}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimePickerElement
                            label={isReturn ? 'Data zdania' : 'Data wydania'}
                            name={isReturn ? 'dateOfReturn' : 'dateOfHanding'}
                            minDateTime={moment().subtract(1, 'days')}
                            disableFuture
                            required
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        sx={{ mr: 1 }}
                        variant='outlined'
                        onClick={() => close()}
                    >
                        Anuluj
                    </Button>
                    <LoadingButton
                        loading={loading}
                        type={'submit'}
                        variant='contained'
                    >
                        Zapisz
                    </LoadingButton>
                </Box>
            </FormContainer>
        </Box>
    )
})

export default HandingKeysModal
