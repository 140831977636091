import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState, useContext } from 'react'
import { DELETE } from '../../../Helpers/fetch'
import CustomToolbar from '../CustomToolbar/CustomToolbar'
import Modal from '@mui/material/Modal/Modal'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import AddKeysRegisterModal from '../../Modals/AddKeysRegisterModal/AddKeysRegisterModal'
import HandingKeysModal from '../../Modals/HandingKeysModal/HandingKeysModal'
import Snackbar from '../../Snackbar/Snackbar'
import TokenContext from '../../../store/token-context'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'keyCode', direction: 'asc' },
}

const KeysRegisterTable = () => {
    const [data, setData] = useState([])
    const [count, setCount] = useState(1)
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [snackbarInfo, setSnackbarInfo] = useState({})
    const [actionAnchor, setActionAnchor] = useState(null)
    const [editedRow, setEditedRow] = useState(0)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [isReturn, setIsReturn] = useState(false)
    const [handingModal, setHandingModal] = useState(false)

    const [openAddModal, setOpenAddModal] = useState(false)
    const [deleteDataLoading, setDeleteDataLoading] = useState(false)

    const tokenData = useContext(TokenContext)

    const actionsIsOpen = Boolean(actionAnchor)

    const actionsClickHandler = (event, index) => {
        setActionAnchor(event.currentTarget)
        setEditedRow(index)
    }

    const actionsCloseHandler = () => {
        setActionAnchor(null)
    }

    const addModalCloseHandler = refresh => {
        setOpenAddModal(false)
        if (refresh) setRefreshData(!refreshData)
    }
    const handingModalCloseHandler = refresh => {
        setHandingModal(false)
        if (refresh) setRefreshData(!refreshData)
    }

    const editHandler = () => {
        actionsCloseHandler()
        setOpenEditModal(true)
    }
    const handingHandler = isReturned => {
        actionsCloseHandler()
        setIsReturn(!isReturned)
        setHandingModal(true)
    }

    const deleteHandler = () => {
        actionsCloseHandler()
        setDeleteDataLoading(true)
        const documentId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('/keysRegister/delete/' + documentId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteDataLoading(false)
                setOpenDeleteDialog(false)
            })
            .catch(err => {
                setDeleteDataLoading(false)
                console.error(err)
            })
    }

    const importedColumns = columns({
        actionsClickHandler,
        actionsIsOpen,
        actionsCloseHandler,
        actionAnchor,
        editHandler,
        handingHandler,
        setOpenDeleteDialog,
        role: tokenData.data.role,
        isReturned:
            !!data[editedRow]?.dateOfHanding ===
            !!data[editedRow]?.dateOfReturn,
        setIsReturn,
    })

    const options = {
        customToolbar: () => (
            <CustomToolbar onClick={() => setOpenAddModal(true)} />
        ),
    }

    return (
        <>
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            <DataTable
                title={'Rejestr kluczy'}
                url={'keysRegister/get'}
                data={data}
                setData={setData}
                count={count}
                setCount={setCount}
                initalOptionsState={optionsState}
                passedOptions={options}
                columns={importedColumns}
                firstLoading={loading}
                setFirstLoading={setLoading}
                refreshData={refreshData}
            />
            {!loading && (
                <Modal open={openAddModal} onClose={addModalCloseHandler}>
                    <DialogContent>
                        <AddKeysRegisterModal
                            setSnackbarInfo={setSnackbarInfo}
                            close={addModalCloseHandler}
                        />
                    </DialogContent>
                </Modal>
            )}

            {!loading && (
                <Modal open={handingModal} onClose={handingModalCloseHandler}>
                    <DialogContent>
                        <HandingKeysModal
                            isReturn={isReturn}
                            data={data[editedRow]}
                            close={handingModalCloseHandler}
                        />
                    </DialogContent>
                </Modal>
            )}
            {/* {!loading && (
                <Modal
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                >
                    <DialogContent>
                        <EditKeysRegisterModal
                            data={data[editedRow]}
                            setSnackbarInfo={setSnackbarInfo}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenEditModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )} */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                {' '}
                <DialogTitle>Czy na pewno usunąć dany klucz</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie klucza. Czy
                        na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={deleteDataLoading}
                        onClick={deleteHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default KeysRegisterTable
