import { useState } from 'react'
import  { useNavigate } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import Logo from '../../assets/svg/rodopl-logo.svg'
import BackgroundImage from '../../assets/svg/prism.svg'
import { POST } from '../../Helpers/fetch'

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                },
            },
        },
    },
})

export default function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [userExists, setUserExists] = useState(true)

    const navigate = useNavigate()

    const resetHandler = async event => {
        setLoading(true)
        event.preventDefault()
        const { status } = await POST('auth/requestpasswordreset', {
            email: email,
        }).catch(err => err.response)
        if (status === 201) {
            setUserExists(true)
            navigate('/resetemailsent')
        } else {
            setUserExists(false)
        }
        setLoading(false)
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Box sx={{ height: 80 }}>
                        <img
                            style={{ height: '100%' }}
                            src={Logo}
                            alt={'Logo'}
                        />
                    </Box>
                    <Box
                        component='form'
                        onSubmit={event => resetHandler(event)}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        Podaj swój login
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id='email'
                            label='Email'
                            name='email'
                            value={email}
                            autoComplete='email'
                            autoFocus
                            onChange={e => setEmail(e.target.value)}
                        />
                        { userExists ? '' : 'Użytkownik o podanym loginie nie istnieje.' }
                        <LoadingButton
                            type='submit'
                            fullWidth
                            loading={loading}
                            variant='contained'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Resetuj hasło
                        </LoadingButton>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}
