import { useState } from 'react'
import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import Modal from '@mui/material/Modal/Modal'
import TrainingsDetails from '../../Modals/TrainingsModal/TrainingsDetails/TrainingsDetails'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'employeeName', direction: 'asc' },
}

const AllEmployeesTrainingsTable = () => {
    const [data, setData] = useState([])
    const [count, setCount] = useState(1)
    const [refreshData, setRefreshData] = useState(false)
    const [openDetails, setOpenDetails] = useState(false)
    const [selectedRow, setSelectedRow] = useState(0)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [loading, setLoading] = useState(true)

    const closeAndRefresh = () => {
        setRefreshData(!refreshData)
        setSelectedRow(0)
        setOpenDetails(false)
    }

    const closeModalHandler = () => {
        setSelectedRow(0)
        setOpenDetails(false)
    }

    const selectTraining = (row, index) => {
        setSelectedRow(row)
        setSelectedIndex(index)
        setOpenDetails(true)
    }

    const importedColumns = columns({ selectTraining })

    const dataForDetails = !loading &&
        !!data.length > 0 && {
            ...data[selectedRow].trainings[selectedIndex],
            documentId: data[selectedRow]._id,
        }

    const legend = (
        <Box sx={{ padding: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        height: 25,
                        width: 25,
                        borderRadius: 1,
                        backgroundColor: '#ffeb3b',
                        mr: 1,
                    }}
                />
                <Typography> - nieukończone</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <Box
                    sx={{
                        height: 25,
                        width: 25,
                        borderRadius: 1,
                        backgroundColor: '#c62828',
                        mr: 1,
                    }}
                />
                <Typography> - po terminie</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <Box
                    sx={{
                        height: 25,
                        width: 25,
                        borderRadius: 1,
                        backgroundColor: '#ed6c02',
                        mr: 1,
                    }}
                />
                <Typography> - kończąca się ważność</Typography>
            </Box>
        </Box>
    )

    return (
        <>
            <DataTable
                title={'Szkolenia pracowników'}
                url={'training/getForAllEmployees'}
                data={data}
                setData={setData}
                count={count}
                setCount={setCount}
                initalOptionsState={optionsState}
                columns={importedColumns}
                legend={legend}
                firstLoading={loading}
                setFirstLoading={setLoading}
                refreshData={refreshData}
            />
            {!loading && (
                <Modal open={openDetails} onClose={closeModalHandler}>
                    <TrainingsDetails
                        closeAndRefresh={closeAndRefresh}
                        data={dataForDetails}
                    />
                </Modal>
            )}
        </>
    )
}

export default AllEmployeesTrainingsTable
