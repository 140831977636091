import * as React from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card/Card'
import CardHeader from '@mui/material/CardHeader/CardHeader'
import { useFormContext } from 'react-hook-form'
import { Divider } from '@mui/material'
import { useEffect } from 'react'

function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1)
}

const CustomTransferList = props => {
    const { register, setValue, getValues } = useFormContext()
    register(props.name)

    const [checked, setChecked] = React.useState([])
    const [left, setLeft] = React.useState(props.list)
    const [right, setRight] = React.useState([])

    const leftChecked = intersection(checked, left)
    const rightChecked = intersection(checked, right)

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const handleAllRight = () => {
        setRight(right.concat(left))
        setLeft([])
    }

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked))
        setLeft(not(left, leftChecked))
        setChecked(not(checked, leftChecked))
    }

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked))
        setRight(not(right, rightChecked))
        setChecked(not(checked, rightChecked))
    }

    const handleAllLeft = () => {
        setLeft(left.concat(right))
        setRight([])
    }

    React.useEffect(() => {
        setValue(props.name, right)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [right])

    useEffect(() => {
        const onRender = () => {
            const data = getValues(props.name)
            if (data && data.length > 0) {
                setRight(right.concat(data))
                setLeft(not(left, data))
            }
        }
        onRender()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                titleTypographyProps={{ variant: 'subtitle2' }}
                title={title}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 200,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component='div'
                role='list'
            >
                {items.map(value => {
                    const labelId = `transfer-list-item-${value}-label`

                    return (
                        <ListItem
                            key={value}
                            role='listitem'
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Card>
    )

    return (
        <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
        >
            <Grid item>{customList('Wyłączone', left)}</Grid>
            <Grid item>
                <Grid container direction='column' alignItems='center'>
                    <Button
                        sx={{ my: 0.5 }}
                        variant='outlined'
                        size='small'
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label='move all right'
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant='outlined'
                        size='small'
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label='move selected right'
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant='outlined'
                        size='small'
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label='move selected left'
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant='outlined'
                        size='small'
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label='move all left'
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList('Aktywne', right)}</Grid>
        </Grid>
    )
}

export default CustomTransferList
