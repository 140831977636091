import { parseError } from '../../../Helpers/formValidation'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import Switch from '@mui/material/Switch/Switch'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Input from '@mui/material/Input/Input'
import Button from '@mui/material/Button/Button'
import { forwardRef, useState } from 'react'
import {
    DatePickerElement,
    FormContainer,
    TextFieldElement,
} from 'react-hook-form-mui'
import { useForm } from 'react-hook-form'
import { POST_WITH_FILE, POST } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const EditInformationPoliciesModal = forwardRef(
    ({ close, setSnackbarInfo, data }, ref) => {
        const formContext = useForm({ defaultValues: data })

        const [loading, setLoading] = useState(false)
        const [ufn, setUfn] = useState(data.untilFurtherNotice)
        const [file, setFile] = useState({ name: data.fileName })
        const [isFileChanged, setIsFileChanged] = useState(false)

        const uploadFileHandler = event => {
            const fileData = event.target.files[0]
            setIsFileChanged(true)
            setFile(fileData)
        }

        const changeUfnHandler = () => {
            setUfn(!ufn)
            formContext.resetField('expiryDate')
        }

        const editInformationPoliciesHandler = data => {
            setLoading(true)

            const formData = new FormData()
            formData.append(
                'data',
                JSON.stringify({ ...data, untilFurtherNotice: ufn })
            )
            formData.append('file', file)

            const editInformationPolicies = async () => {
                if (isFileChanged) {
                    await POST_WITH_FILE(
                        'informationPolicies/editWithFile',
                        formData
                    )
                } else {
                    await POST('informationPolicies/edit', {
                        ...data,
                        untilFurtherNotice: ufn,
                    })
                }
            }
            editInformationPolicies()
                .then(() => {
                    setSnackbarInfo({
                        open: true,
                        type: 'success',
                        message: 'Edytowano politykę',
                    })
                    setLoading(false)
                    close()
                })
                .catch(() => {
                    setLoading(false)
                    setSnackbarInfo({
                        open: true,
                        type: 'error',
                        message: 'Błąd: Nie edytowano polityki',
                    })
                })
        }

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant={'h5'}>
                    Edytuj politykę informacyjną
                </Typography>
                <Divider />
                <FormContainer
                    formContext={formContext}
                    onSuccess={editInformationPoliciesHandler}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'documentName'}
                                autoFocus
                                required
                                fullWidth
                                label={'Nazwa dokumentu'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePickerElement
                                required
                                inputProps={{ fullWidth: true }}
                                parseError={parseError}
                                label='Data wprowadzenia w życie'
                                name={'effectiveDate'}
                            />
                        </Grid>
                        <Grid item xs />
                        <Grid item xs={6}>
                            <DatePickerElement
                                required={!ufn}
                                disabled={ufn}
                                inputProps={{ fullWidth: true }}
                                parseError={parseError}
                                validation={{
                                    disabled: ufn,
                                }}
                                label='Data wygaśnięcia'
                                name={'expiryDate'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={ufn}
                                        onChange={changeUfnHandler}
                                    />
                                }
                                label='Do odwołania'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'remarks'}
                                multiline
                                maxRows={5}
                                autoFocus
                                fullWidth
                                label={'Uwagi'}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                border={1}
                                borderRadius={'4px'}
                                borderColor='#c4c4c4'
                            >
                                <label htmlFor='contained-button-file'>
                                    <Input
                                        sx={{ display: 'none' }}
                                        onChange={uploadFileHandler}
                                        accept='.pdf'
                                        id='contained-button-file'
                                        multiple
                                        type='file'
                                    />

                                    <Button
                                        sx={{
                                            borderRight: 1,
                                            borderRadius: '4px',
                                            borderColor: '#c4c4c4',
                                            pr: 2,
                                            pl: 2,
                                        }}
                                        variant='text'
                                        component='span'
                                    >
                                        Dodaj załącznik (PDF)
                                    </Button>
                                </label>
                                <Typography
                                    noWrap
                                    sx={{
                                        ml: 1,
                                        color: 'text.secondary',
                                        width: '50%',
                                    }}
                                >
                                    {file?.name}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={close}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Dodaj
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default EditInformationPoliciesModal
