import CardContent from '@mui/material/CardContent/CardContent'
import Typography from '@mui/material/Typography/Typography'
import Divider from '@mui/material/Divider/Divider'
import CardActions from '@mui/material/CardActions/CardActions'
import Button from '@mui/material/Button/Button'
import Modal from '@mui/material/Modal/Modal'
import Card from '@mui/material/Card/Card'
import Tooltip from '@mui/material/Tooltip'

const CustomCard = ({ title, text, onClick, open, onClose, children }) => {
    return (
        <Card sx={{ height: 220, display: 'flex', flexDirection: 'column' }}>
            <CardContent
                sx={{ maxHeight: '65%', flexGrow: 1, textAlign: 'left' }}
            >
                <Typography gutterBottom variant='h6'>
                    {title}
                </Typography>
                <Divider />
                <Tooltip title={text}>
                    <Typography variant={'body2'} sx={{ mt: 2 }}>
                        {text}
                    </Typography>
                </Tooltip>
            </CardContent>
            <CardActions>
                <Button size='small' onClick={onClick}>
                    Dodaj
                </Button>
                <Modal open={open} onClose={onClose}>
                    {children}
                </Modal>
            </CardActions>
        </Card>
    )
}

export default CustomCard
