import { useEffect, useState } from 'react'
import AddTwoFactorModal from '../Modals/AddTwoFactorModal/AddTwoFactorModal'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import Switch from '@mui/material/Switch/Switch'
import Modal from '@mui/material/Modal/Modal'
import Snackbar from '@mui/material/Snackbar/Snackbar'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'

//mui
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Box from '@mui/material/Box/Box'
import Paper from '@mui/material/Paper/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import Alert from '@mui/material/Alert/Alert'

import { Divider } from '@mui/material'
import ChangePasswordModal from '../Modals/ChangePasswordModal/ChangePasswordModal'
import { GET } from '../../Helpers/fetch'

const AccountSettings = () => {
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [isTwoFaActive, setIsTwoFaActive] = useState(false)
    const [openTwoFa, setOpenTwoFa] = useState(false)
    const [openDisableTwoFa, setOpenDisableTwoFa] = useState(false)
    const [openChange, setOpenChange] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarText, setSnackbarText] = useState('Hasło zmienione')
    const [data, setData] = useState({})

    const switchChangeHandler = event => {
        if (isTwoFaActive) {
            setOpenDisableTwoFa(true)
        } else {
            setOpenTwoFa(true)
        }
    }

    const disableTwoFaHandler = () => {
        setButtonLoading(true)
        GET('auth/disableTwoFa').then(() => {
            setIsTwoFaActive(false)
            setOpenDisableTwoFa(false)
            setButtonLoading(false)
        })
    }

    const closeModal = ({ isSuccess }) => {
        if (isSuccess) {
            setIsTwoFaActive(true)
        }
        setOpenTwoFa(false)
    }

    useEffect(() => {
        setLoading(true)
        GET('users/getUser').then(res => {
            setData(res.data)
            setIsTwoFaActive(res.data.twoFactor.isSet)
            setLoading(false)
        })
    }, [])

    return (
        <Box
            sx={{
                height: '80vh',
                textAlign: 'left',
                padding: 2,
            }}
        >
            {loading ? (
                <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                    <CircularProgress />
                </Paper>
            ) : (
                <>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={() => setOpenSnackbar(false)}
                    >
                        <Alert
                            onClose={() => setOpenSnackbar(false)}
                            severity='success'
                            variant='filled'
                            sx={{ width: '100%' }}
                        >
                            {snackbarText}
                        </Alert>
                    </Snackbar>
                    <Modal open={openTwoFa} onClose={() => setOpenTwoFa(false)}>
                        <DialogContent>
                            <AddTwoFactorModal
                                setSnackbarText={setSnackbarText}
                                setOpenSnackbar={setOpenSnackbar}
                                close={closeModal}
                            />
                        </DialogContent>
                    </Modal>
                    <Modal
                        open={openChange}
                        onClose={() => setOpenChange(false)}
                    >
                        <DialogContent>
                            <ChangePasswordModal
                                setSnackbarText={setSnackbarText}
                                setOpenSnackbar={setOpenSnackbar}
                                close={() => setOpenChange(false)}
                            />
                        </DialogContent>
                    </Modal>

                    <Dialog
                        open={openDisableTwoFa}
                        onClose={() => setOpenDisableTwoFa(false)}
                    >
                        <DialogTitle>
                            Czy na pewno chcesz zrezygnować z weryfikacji
                            dwustopniowej?
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Weryfikacja dwustopniowa zwiększa Twoje
                                bezpieczeństwo. Czy na pewno chcesz to zrobić?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDisableTwoFa(false)}>
                                Anuluj
                            </Button>
                            <LoadingButton
                                onClick={disableTwoFaHandler}
                                loading={buttonLoading}
                                autoFocus
                            >
                                Zatwierdź
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>

                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant='h5'>Konto</Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography>Imię: {data.firstName}</Typography>
                        <Typography>Nazwisko: {data.lastName}</Typography>
                        <Typography>E-mail: {data.email}</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography variant='h6'>Zmiana hasła</Typography>
                            <Button
                                variant={'outlined'}
                                onClick={() => setOpenChange(true)}
                            >
                                Zmień
                            </Button>
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography variant='h6'>
                                    Weryfikacja dwustopniowa
                                </Typography>
                            </Box>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isTwoFaActive}
                                        onChange={switchChangeHandler}
                                        name='twoFaSwitch'
                                    />
                                }
                                labelPlacement='start'
                                label='Włącz weryfikację dwustopniową'
                            />
                        </Box>
                    </Paper>
                </>
            )}
        </Box>
    )
}

export default AccountSettings
