import { useState } from 'react'
import Box from '@mui/material/Box/Box'
import Accordion from '@mui/material/Accordion/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails'
import Typography from '@mui/material/Typography/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import List from '@mui/material/List/List'
import ListItem from '@mui/material/ListItem/ListItem'

const MuiAccordion = ({ name, expanded, handleChange, title, children }) => {
    return (
        <Accordion expanded={expanded === name} onChange={handleChange(name)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${name}-content`}
                id={`${name}-header`}
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ fontFamily: 'Montserrat Variable' }}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

const Faq = () => {
    const [expanded, setExpanded] = useState(false)

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Box
            sx={{
                textAlign: 'left',
                padding: 2,
            }}
        >
            <MuiAccordion
                name='panel1'
                expanded={expanded}
                handleChange={handleChange}
                title='CO OZNACZA RODO?'
            >
                <Typography>
                    Rozporządzenie o Ochronie Danych Osobowych Rozporządzenie
                    Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
                    kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku
                    z przetwarzaniem danych osobowych i w sprawie swobodnego
                    przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                    (ogólne rozporządzenie o ochronie danych). RODO = GDPR =
                    General Data Protection Regulation Poza RODO polski rząd
                    przyjął Ustawę z dnia 10 maja 2018 r. o ochronie danych
                    osobowych.
                </Typography>
            </MuiAccordion>
            <MuiAccordion
                name='panel2'
                expanded={expanded}
                handleChange={handleChange}
                title='Kim jest ADMINISTRATOR DANYCH (ADO)?'
            >
                <Typography>
                    To podmiot, który decyduje o celach (po co) i sposobach
                    (jak) przetwarzania danych, np. Twój pracodawca jest
                    Administratorem Twoich danych osobowych.
                </Typography>
            </MuiAccordion>
            <MuiAccordion
                name='panel3'
                expanded={expanded}
                handleChange={handleChange}
                title='Kim jest PODMIOT PRZETWARZAJĄCY?'
            >
                <Typography>
                    To podmiot działający na podstawie umowy z administratorem
                    danych, który wykonuje dla niego określone usługi, np.
                    zewnętrzna księgowość. Podmiot przetwarzający dane na
                    zlecenie powinien zawrzeć z administratorem danych
                    odpowiednią umowę, tzw. umowę powierzenia, w której
                    określone zostaną zasady przetwarzania danych.
                </Typography>
            </MuiAccordion>
            <MuiAccordion
                name='panel4'
                expanded={expanded}
                handleChange={handleChange}
                title='Kogo dotyczy RODO?'
            >
                <Typography>
                    Każdego kto przetwarza dane osobowe obywateli UE w celach
                    innych niż czysto osobiste lub domowe. Każdej
                    instytucji/firmy, która korzysta z danych w jakimkolwiek
                    zakresie lub choćby przechowuje dane osobowe, niezależnie od
                    branży.
                </Typography>
            </MuiAccordion>
            <MuiAccordion
                name='panel5'
                expanded={expanded}
                handleChange={handleChange}
                title='Jakie są zasady dotyczące przetwarzania danych osobowych?'
            >
                <Typography>
                    Administrator jest odpowiedzialny za przestrzeganie zasad
                    wskazanych w RODO i musi być w stanie wykazać ich realizacje
                    przed organem nadzorczym (w Polsce UODO) (rozliczalność)
                </Typography>
                <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        przetwarzane zgodnie z prawem, rzetelnie i w sposób
                        przejrzysty dla osoby, której dane dotyczą (zgodność z
                        prawem, rzetelność i przejrzystość);
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        zbierane w konkretnych, wyraźnych i prawnie
                        uzasadnionych celach i nieprzetwarzane dalej w sposób
                        niezgodny z tymi celami (ograniczenie celu);
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        adekwatnie stosowne oraz ograniczone do tego co
                        niezbędne, do celów, w których są przetwarzane
                        (minimalizacja danych);
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        prawidłowe i w razie potrzeby uaktualniane, a dane
                        osobowe, które są nieprawidłowe w świetle celów ich
                        przetwarzania, muszą być niezwłocznie usunięte lub
                        sprostowane (prawidłowość);
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        przechowywane w formie umożliwiającej identyfikację
                        osoby, której dotyczą, przez okres nie dłuższy, niż jest
                        to niezbędne do celów, w których dane te są przetwarzane
                        (ograniczenie przechowywania);
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        przetwarzane w sposób zapewniający odpowiednie
                        bezpieczeństwo danych osobowych, w tym ochronę przed
                        niedozwolonym lub niezgodnym z prawem przetwarzaniem
                        oraz przypadkową utratą, zniszczeniem lub uszkodzeniem,
                        za pomocą odpowiednich środków technicznych lub
                        organizacyjnych (integralność i poufność).
                    </ListItem>
                </List>
            </MuiAccordion>
            <MuiAccordion
                name='panel6'
                expanded={expanded}
                handleChange={handleChange}
                title='Co oznacza skrót UODO?'
            >
                <Typography>
                    Urząd ochrony danych osobowych, który jest organem
                    nadzorczym ds. ochrony danych osobowych w Polsce.
                </Typography>
            </MuiAccordion>
            <MuiAccordion
                name='panel7'
                expanded={expanded}
                handleChange={handleChange}
                title='Kim jest Inspektor Ochrony Danych (IOD)?'
            >
                <Typography>
                    To osoba wybierana i zgłaszana do UODO przez Administratora
                    lub podmiot przetwarzający do pomocy przy przestrzeganiu
                    przepisów o ochronie danych osobowych. IOD pełni rolę
                    pośrednika pomiędzy zainteresowanymi podmiotami (Urzędem
                    Ochrony Danych Osobowych, podmiotem przetwarzającym dane
                    oraz osobą, której dane są przetwarzane). Ponadto Inspektor
                    Ochrony Danych Osobowych zapewnia realizację zasady
                    rozliczalności - pomaga przy sporządzaniu oceny ryzyka, czy
                    oceny skutku ochrony danych osobowych.
                </Typography>
                <Typography mt={3}>
                    Do zadań Inspektora Ochrony Danych Osobowych należy:
                </Typography>
                <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        informowanie administratora, podmiotu przetwarzającego
                        oraz pracowników o obowiązkach w zakresie ochrony danych
                        osobowych wynikających z RODO,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        doradzanie, jak przestrzegać przepisów o ochronie danych
                        osobowych,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        monitorowanie przestrzegania przepisów, polityk w
                        zakresie ochrony danych osobowych,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        pomaganie przy sporządzaniu oceny ryzyka lub oceny
                        skutków dla ochrony danych osobowych,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        zachowanie poufności względem wykonywanych zadań w
                        ramach ochrony danych osobowych,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        pełnienie funkcji punktu kontaktowego dla organu
                        nadzorczego.
                    </ListItem>
                </List>
            </MuiAccordion>
            <MuiAccordion
                name='panel8'
                expanded={expanded}
                handleChange={handleChange}
                title='Kto musi powołać IOD?'
            >
                <Typography>
                    Podmioty publiczne, podmioty przetwarzające na dużą skalę
                    dane wrażliwe oraz podmioty, których główna działalność
                    polega na monitorowaniu osób na dużą skalę. Pozostałe
                    podmioty również mogą wyznaczyć IOD, jednak nie jest to dla
                    nich obowiązkowe.
                </Typography>
            </MuiAccordion>
            <MuiAccordion
                name='panel9'
                expanded={expanded}
                handleChange={handleChange}
                title='Czym są dane osobowe?'
            >
                <Typography>
                    To wszelkiego rodzaju informacje, które pozwalają na
                    identyfikację konkretnej osoby.
                </Typography>
            </MuiAccordion>
            <MuiAccordion
                name='panel10'
                expanded={expanded}
                handleChange={handleChange}
                title='Czym są dane wrażliwe?'
            >
                <Typography>
                    Dane szczególnych kategorii. Wrażliwe dane, określane też
                    jako dane sensytywne, zostały wskazane w art. 9 RODO i są
                    to:
                </Typography>
                <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        dane ujawniające pochodzenie rasowe lub etniczne,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        poglądy polityczne,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        przekonania religijne lub światopoglądowe,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        przynależność do związków zawodowych,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        dane genetyczne, dane biometryczne przetwarzane w celu
                        jednoznacznego zidentyfikowania osoby fizycznej,
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        dane dotyczące zdrowia, seksualności lub orientacji
                        seksualnej danej osoby.
                    </ListItem>
                </List>
            </MuiAccordion>
            <MuiAccordion
                name='panel11'
                expanded={expanded}
                handleChange={handleChange}
                title='Jakie są podstawy prawne przetwarzania danych osobowych?'
            >
                <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        ZGODA – a. osoba, której dane dotyczą wyraziła zgodę na
                        przetwarzanie swoich danych osobowych w jednym lub
                        większej liczbie określonych celów;
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        UMOWA – b. przetwarzanie jest niezbędne do wykonania
                        umowy, której stroną jest osoba, której dane dotyczą,
                        lub do podjęcia działań na żądanie osoby, której dane
                        dotyczą, przed zawarciem umowy;
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        OBOWIĄZEK PRAWNY – c. przetwarzanie jest niezbędne do
                        wypełnienia obowiązku prawnego ciążącego na
                        administratorze;
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        OCHRONA ŻYWOTNYCH INTERESÓW OSOBY – d. przetwarzanie
                        jest niezbędne do ochrony żywotnych interesów osoby,
                        której dane dotyczą, lub innej osoby fizycznej;
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        WYKONANIE ZADANIA REALIZOWANEGO W INTERESIE PUBLICZNYM –
                        e. przetwarzanie jest niezbędne do wykonania zadania
                        realizowanego w interesie publicznym lub w ramach
                        sprawowania władzy publicznej powierzonej
                        administratorowi;
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        PRAWNIE UZASADNIONY INTERES ADMINISTRATORA – f.
                        przetwarzanie jest niezbędne do celów wynikających z
                        prawnie uzasadnionych interesów realizowanych przez
                        administratora lub przez stronę trzecią, z wyjątkiem
                        sytuacji, w których nadrzędny charakter wobec tych
                        interesów mają interesy lub podstawowe prawa i wolności
                        osoby, której dane dotyczą, wymagające ochrony danych
                        osobowych, w szczególności gdy osoba, której dane
                        dotyczą, jest dzieckiem.
                    </ListItem>
                </List>
            </MuiAccordion>
            <MuiAccordion
                name='panel12'
                expanded={expanded}
                handleChange={handleChange}
                title='Jak powinno wyglądać udzielenie zgody zgodnie z RODO?'
            >
                <Typography>
                    Zgoda na przetwarzanie danych powinna być:
                </Typography>
                <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        Dobrowolna
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Konkretna</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        Jednoznaczna
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Świadoma</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        Może być wyrażona w dowolnej formie, ale to
                        administrator danych powinien wykazać, że zgoda została
                        udzielona.
                    </ListItem>
                </List>
            </MuiAccordion>
        </Box>
    )
}

export default Faq
