import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import {
    FormContainer,
    DatePickerElement,
    TextFieldElement,
} from 'react-hook-form-mui'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Input } from '@mui/material'
import Button from '@mui/material/Button/Button'
import { forwardRef, useState } from 'react'
import { parseError } from '../../../Helpers/formValidation'
import { POST_WITH_FILE } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const AddAuditReportModal = forwardRef(({ close, setSnackbarInfo }, ref) => {
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState()

    const uploadFileHandler = event => {
        const fileData = event.target.files[0]
        setFile(fileData)
    }

    const addAuditReportHandler = data => {
        setLoading(true)

        const formData = new FormData()
        formData.append('data', JSON.stringify({ ...data }))
        formData.append('file', file)

        const addRegister = async () => {
            await POST_WITH_FILE('auditReport/add', formData)
        }
        addRegister()
            .then(() => {
                setSnackbarInfo({
                    open: true,
                    type: 'success',
                    message: 'Dodano wpis',
                })
                setLoading(false)
                close(true)
            })
            .catch(() => {
                setLoading(false)
                setSnackbarInfo({
                    open: true,
                    type: 'error',
                    message: 'Błąd: Nie dodano wpisu',
                })
            })
    }

    return (
        <Box sx={style} ref={ref}>
            <Typography gutterBottom variant='h6'>
                Dodawanie sprawozdania z audytu
            </Typography>
            <Divider />
            <FormContainer onSuccess={addAuditReportHandler}>
                <Grid container sx={{ mt: 1 }} spacing={2}>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'documentName'}
                            parseError={parseError}
                            fullWidth
                            minRows={1}
                            maxRows={2}
                            multiline
                            label='Nazwa dokumentu'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <DatePickerElement
                            name={'addDate'}
                            parseError={parseError}
                            fullWidth
                            autoFocus
                            label='Data dodania'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'remarks'}
                            parseError={parseError}
                            fullWidth
                            minRows={2}
                            maxRows={4}
                            multiline
                            label='Uwagi'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='center'
                            border={1}
                            borderRadius={'4px'}
                            borderColor='#c4c4c4'
                        >
                            <label htmlFor='contained-button-file'>
                                <Input
                                    sx={{ display: 'none' }}
                                    onChange={uploadFileHandler}
                                    accept='.pdf'
                                    id='contained-button-file'
                                    multiple
                                    type='file'
                                />

                                <Button
                                    sx={{
                                        borderRight: 1,
                                        borderRadius: '4px',
                                        borderColor: '#c4c4c4',
                                        pr: 2,
                                        pl: 2,
                                    }}
                                    variant='text'
                                    component='span'
                                >
                                    Dodaj załącznik (PDF)
                                </Button>
                            </label>
                            <Typography
                                noWrap
                                sx={{
                                    ml: 1,
                                    color: 'text.secondary',
                                    width: '50%',
                                }}
                            >
                                {file?.name}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                    }}
                >
                    <Button
                        sx={{ mr: 1 }}
                        variant='outlined'
                        onClick={() => close(false)}
                    >
                        Anuluj
                    </Button>
                    <LoadingButton
                        loading={loading}
                        type={'submit'}
                        variant='contained'
                    >
                        Dodaj
                    </LoadingButton>
                </Box>
            </FormContainer>
        </Box>
    )
})

export default AddAuditReportModal
