import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import { DELETE } from '../../../Helpers/fetch'
import { useState, useContext } from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import EditTrainingModal from '../../Modals/TrainingsModal/EditTrainingsModal/EditTrainingsModal'
import Snackbar from '../../Snackbar/Snackbar'
import Modal from '@mui/material/Modal/Modal'
import AddTraining from '../../Modals/TrainingsModal/AddTraining/AddTraining'
import AddTestModal from '../../Modals/TrainingsModal/AddTestModal/AddTestModal.jsx'
import EditTestModal from '../../Modals/TrainingsModal/EditTestModal/EditTestModal.jsx'
import AddMaterialsToTraining from '../../Modals/TrainingsModal/AddMaterialsToTrainingModal/AddMaterialsToTrainingModal.jsx'
import TokenContext from '../../../store/token-context'
import CustomToolbar from '../CustomToolbar/CustomToolbar'

import { useEffect } from 'react'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'name', direction: 'asc' },
}

const TrainingsRegisterTable = () => {
    const [data, setData] = useState()
    const [count, setCount] = useState(1)
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [editedRow, setEditedRow] = useState(0)
    const [deleteDataLoading, setDeleteDataLoading] = useState(false)
    const [deleteTestLoading, setDeleteTestLoading] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openDeleteTestDialog, setOpenDeleteTestDialog] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [openAddMaterialModal, setOpenAddMaterialModal] = useState(false)
    const [openAddTestModal, setOpenAddTestModal] = useState(false)
    const [openEditTestModal, setOpenEditTestModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [actionsAnchor, setActionsAnchor] = useState(null)
    const [snackbarInfo, setSnackbarInfo] = useState({})

    const tokenData = useContext(TokenContext)

    const actionsIsOpen = Boolean(actionsAnchor)

    const actionsClickHandler = (event, index) => {
        setActionsAnchor(event.currentTarget)
        setEditedRow(index)
    }
    const actionsCloseHandler = () => {
        setActionsAnchor(null)
    }

    const editHandler = () => {
        actionsCloseHandler()
        setOpenEditModal(true)
    }
    const addMaterialHandler = () => {
        actionsCloseHandler()
        setOpenAddMaterialModal(true)
    }
    const addTestHandler = () => {
        actionsCloseHandler()
        setOpenAddTestModal(true)
    }
    const editTestHandler = () => {
        actionsCloseHandler()
        setOpenEditTestModal(true)
    }

    const deleteHandler = () => {
        actionsCloseHandler()
        setDeleteDataLoading(true)
        const trainingId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('/training/delete/' + trainingId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteDataLoading(false)
                setOpenDeleteDialog(false)
            })
            .catch(err => {
                setDeleteDataLoading(false)
                console.error(err)
            })
    }

    const deleteTestHandler = () => {
        actionsCloseHandler()
        setDeleteTestLoading(true)
        const trainingId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('/training/deleteTest/' + trainingId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteTestLoading(false)
                setOpenDeleteTestDialog(false)
            })
            .catch(err => {
                setDeleteTestLoading(false)
                console.error(err)
            })
    }

    const importedColumns = columns({
        data: data?.map(el => ({
            fileName: el.fileName,
            videoLink: el.videoLink,
        })),
        isTest: !loading && data[editedRow].test.length > 0,
        isMaterials: !loading && data[editedRow].materials.length > 0,
        trainingId: !loading && data[editedRow]._id,
        actionsClickHandler,
        actionsAnchor,
        actionsCloseHandler,
        actionsIsOpen,
        deleteHandler,
        setOpenDeleteDialog,
        setOpenDeleteTestDialog,
        addMaterialHandler,
        editTestHandler,
        addTestHandler,
        editHandler,
        role: tokenData.data.role,
    })

    const options = {
        customToolbar: () => (
            <CustomToolbar onClick={() => setOpenAddModal(true)} />
        ),
    }

    return (
        <>
            <DataTable
                title={'Lista szkoleń'}
                url={'training/get'}
                data={data}
                setData={setData}
                count={count}
                setCount={setCount}
                initalOptionsState={optionsState}
                passedOptions={options}
                columns={importedColumns}
                firstLoading={loading}
                setFirstLoading={setLoading}
                refreshData={refreshData}
            />
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            {!loading && (
                <Modal
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                >
                    <DialogContent>
                        <EditTrainingModal
                            data={data[editedRow]}
                            setSnackbarInfo={setSnackbarInfo}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenEditModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            {!loading && (
                <Modal
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                >
                    <DialogContent>
                        <AddTraining
                            setSnackbarInfo={setSnackbarInfo}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenAddModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            {!loading && (
                <Modal
                    open={openAddMaterialModal}
                    onClose={() => setOpenAddMaterialModal(false)}
                >
                    <DialogContent>
                        <AddMaterialsToTraining
                            trainingId={data[editedRow]._id}
                            setSnackbarInfo={setSnackbarInfo}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenAddMaterialModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}

            {!loading && (
                <Modal
                    open={openAddTestModal}
                    onClose={() => setOpenAddTestModal(false)}
                >
                    <DialogContent>
                        <AddTestModal
                            trainingId={data[editedRow]._id}
                            setSnackbarInfo={setSnackbarInfo}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenAddTestModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            {!loading && (
                <Modal
                    open={openEditTestModal}
                    onClose={() => setOpenEditTestModal(false)}
                >
                    <DialogContent>
                        <EditTestModal
                            trainingId={data[editedRow]._id}
                            defaultData={data[editedRow].test}
                            setSnackbarInfo={setSnackbarInfo}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenEditTestModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>Czy na pewno usunąć dane szkolenie?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie szkolenia.
                        Czy na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={deleteDataLoading}
                        onClick={deleteHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteTestDialog}
                onClose={() => setOpenDeleteTestDialog(false)}
            >
                <DialogTitle>
                    Czy na pewno usunąć test do szkolenia?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie testu. Czy
                        na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenDeleteTestDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={deleteDataLoading}
                        onClick={deleteTestHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TrainingsRegisterTable
