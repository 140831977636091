import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'

const columns = props => [
    { name: 'processingCategories', label: 'Kategorie przetwarzań' },
    { name: 'adminData', label: 'Nazwa i dane kontaktowe administratora' },
    {
        name: 'dataProtectionOfficer',
        label: 'Inspektor ochrony danych administratora',
    },
    {
        name: 'measuresDescription',
        label: 'Ogólny opis technicznych i organizacyjnych środków bezpieczeństwa',
        options: { display: false },
    },
    {
        name: 'coadminData',
        label: 'Nazwa i dane kontaktowe współadministratora',
        options: { display: false },
    },
    {
        name: 'adminRepData',
        label: 'Nazwa i dane kontaktowe przedstawiciela administratora',
        options: { display: false },
    },
    {
        name: 'thirdCountryNames',
        label: 'Nazwy państw trzecich lub organizacji międzynarodowych, do których dane są przekazywane',
        options: { display: false },
    },
    {
        name: 'safeguardsDocumentation',
        label: 'Dokumentacja odpowiednich zabezpieczeń danych osobowych',
        options: { display: false },
    },
    {
        name: 'safeguards',
        label: 'Zabezpieczenia',
        options: {
            customBodyRender: function (value) {
                return value.join(', ')
            },
            display: false,
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={() => props.editHandler()}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
