import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import Divider from '@mui/material/Divider/Divider'
import { Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { forwardRef, useState } from 'react'
import { parseError } from '../../../Helpers/formValidation'
import { POST } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const AddAppsRegisterModal = forwardRef(({ close, setSnackbarInfo }, ref) => {
    const [loading, setLoading] = useState(false)
    // const [loadingEmployees, setLoadingEmployees] = useState(true)
    // const [employeeList, setEmployeeList] = useState([])

    // const getEmployees = async () => {
    //     setLoadingEmployees(true)
    //     const { data: Items } = await GET('employee/get')
    //     const employees = Items.data.map(el => ({
    //         label: `${el.firstName} ${el.lastName} (${el.email})`,
    //         id: el._id,
    //     }))
    //     setEmployeeList([...employees])
    //     setLoadingEmployees(false)
    // }

    // useEffect(() => {
    //     getEmployees().catch(err => console.error(err))
    // }, [])

    const addAppRegHandler = data => {
        setLoading(true)
        const addResource = async () => {
            await POST('appsRegister/add', {
                ...data,
                //attributedPerson: data.attributedPerson.id,
            })
        }
        addResource()
            .then(() => {
                setSnackbarInfo({
                    open: true,
                    type: 'success',
                    message: 'Dodano wpis do rejestru',
                })
                setLoading(false)
                close(true)
            })
            .catch(() => {
                setLoading(false)
                setSnackbarInfo({
                    open: true,
                    type: 'error',
                    message: 'Błąd: Nie dodano wpisu',
                })
            })
    }

    return (
        <Box sx={style} ref={ref}>
            <Typography gutterBottom variant='h6'>
                Dodawanie wpisu do rejestru systemów
            </Typography>
            <Divider />
            <FormContainer onSuccess={addAppRegHandler}>
                <Grid container sx={{ mt: 1 }} spacing={2}>
                    <Grid item xs={8}>
                        <TextFieldElement
                            name={'name'}
                            parseError={parseError}
                            fullWidth
                            autoFocus
                            label='Nazwa aplikacji'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldElement
                            name={'appType'}
                            parseError={parseError}
                            fullWidth
                            label='Typ Aplikacji'
                        />
                    </Grid>
                    {/* <Grid item xs={8}>
                        <AutocompleteElement
                            loading={loadingEmployees}
                            size={'small'}
                            label={'Osoba przypisana'}
                            fullWidth
                            rules={{ required: 'Pole jest wymagane' }}
                            name={'attributedPerson'}
                            options={employeeList}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextFieldElement
                            name={'description'}
                            parseError={parseError}
                            fullWidth
                            multiline
                            minRows={2}
                            label='Opis'
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                    }}
                >
                    <Button
                        sx={{ mr: 1 }}
                        variant='outlined'
                        onClick={() => close(false)}
                    >
                        Anuluj
                    </Button>
                    <LoadingButton
                        loading={loading}
                        type={'submit'}
                        variant='contained'
                    >
                        Dodaj
                    </LoadingButton>
                </Box>
            </FormContainer>
        </Box>
    )
})

export default AddAppsRegisterModal
