import { useFieldArray, useFormContext } from 'react-hook-form'
import Grid from '@mui/material/Grid/Grid'
import { forwardRef, useImperativeHandle } from 'react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { InputLabel } from '@mui/material'

const SelectedAuth = forwardRef((props, ref) => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'authorizations',
    })

    useImperativeHandle(ref, () => ({
        appendHandler(authName) {
            append({ authName: authName })
        },
    }))

    const removeHandler = index => {
        remove(index)
    }

    return (
        <>
            {fields.length === 0 ? (
                <Grid item xs={12} textAlign={'center'}>
                    <InputLabel>Nie dodano upoważnień</InputLabel>
                </Grid>
            ) : (
                fields.map((field, index) => (
                    <Grid container item>
                        <Grid item xs={1}>
                            <Typography variant='subtitle1'>
                                {index + 1}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant='subtitle1'>
                                {field.authName}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => removeHandler(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))
            )}
        </>
    )
})

export default SelectedAuth
