import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Tooltip,
    ListItemText,
} from '@mui/material'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'
import { FilterList } from '@mui/icons-material'

const columns = props => [
    { name: 'resourceType', label: 'Rodzaj zasobu' },
    {
        name: 'manufacturerAndModel',
        label: 'Producent/Model',
    },
    {
        name: 'serialNumber',
        label: 'Numer seryjny',
    },
    {
        name: 'inventoryNumber',
        label: 'Numer inwentaryzacyjny',
    },
    {
        name: 'value',
        label: 'Wartość',
    },
    {
        name: 'attributedPerson',
        label: 'Osoba przypisana',
    },
    {
        name: 'remarks',
        label: 'Uwagi',
    },
    {
        name: 'resourceReceiptConfirmed',
        label: 'Czy odebrano',
        options: {
            filter: true,
            filterType: 'custom',
            sort: false,
            customFilterListOptions: {
                render: v => (v[0] === 'true' ? ['Tak'] : ['Nie']),
            },
            filterOptions: {
                display: (filterList, onChange, index, column) => {
                    const optionValues = [
                        { label: 'Tak', value: 'true' },
                        { label: 'Nie', value: 'false' },
                    ]

                    return (
                        <FormControl variant='outlined'>
                            <InputLabel id='filter-label'>
                                Czy odebrano
                            </InputLabel>
                            <Select
                                labelId={'filter-label'}
                                input={
                                    <OutlinedInput
                                        label='Czy odebrano'
                                        id='filter-label'
                                    />
                                }
                                value={filterList[index] || ''}
                                onChange={event => {
                                    filterList[index] = [event.target.value]
                                    onChange(filterList[index], index, column)
                                }}
                            >
                                {optionValues.map(item => (
                                    <MenuItem
                                        key={item.label}
                                        value={item.value}
                                    >
                                        <Tooltip title={item.label}>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    noWrap: true,
                                                    maxWidth: '300px',
                                                }}
                                                primary={item.label}
                                            />
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                },
            },
            customBodyRender: value => {
                return value ? 'Tak' : 'Nie'
            },
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={() => props.editHandler()}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
