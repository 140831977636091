import { useState } from 'react'
import  { useNavigate } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import Logo from '../../assets/svg/rodopl-logo.svg'
import BackgroundImage from '../../assets/svg/prism.svg'

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                },
            },
        },
    },
})

export default function PasswordResetSuccess() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const goBackHandler = async event => {
        setLoading(true)
        navigate('/signin')
        setLoading(false)
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Box sx={{ height: 80 }}>
                        <img
                            style={{ height: '100%' }}
                            src={Logo}
                            alt={'Logo'}
                        />
                    </Box>
                    <Box
                        component='form'
                        onSubmit={event => goBackHandler(event)}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        Ustawiono nowe hasło.
                        <LoadingButton
                            type='submit'
                            fullWidth
                            loading={loading}
                            variant='contained'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Wróć
                        </LoadingButton>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}
