import { useState, useEffect, memo } from 'react'
import MUIDataTable from 'mui-datatables'
import Box from '@mui/material/Box'
import GeneratePdfButton from './GeneratePdfButton/GeneratePdfButton'
import LegendButton from './LegendButton/LegendButton'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Typography from '@mui/material/Typography/Typography'
import Button from '@mui/material/Button/Button'
import { GET } from '../../Helpers/fetch'
import { Autocomplete, TextField, Chip } from '@mui/material'

const DataTable = ({
    columns,
    filtersAutocomplete,
    data,
    legend,
    setData,
    expandableRows = false,
    expandableRowsHeader = false,
    renderExpandableRow = () => <></>,
    title,
    pdf,
    filter = true,
    firstLoading,
    setFirstLoading,
    passedOptions,
    initalOptionsState,
    count,
    setCount,
    url,
    refreshData,
}) => {
    const [optionsState, setOptionsState] = useState(initalOptionsState)
    const [filters, setFilters] = useState({})
    const [loading, setLoading] = useState(false)

    console.log('refresh')

    const changePage = async page => {
        setLoading(true)
        const result = await GET(url, {
            rows: optionsState.rowsPerPage,
            page: page + 1,
            sortOrder: optionsState.sortOrder,
            filters: filters,
        })
        const { data: Items, total } = result.data
        if (Items) {
            setData([...Items])
            setOptionsState({
                ...optionsState,
                page: page + 1,
            })
            setCount(total)
            setLoading(false)
        }
    }

    const sort = async sortOrder => {
        setLoading(true)
        const result = await GET(url, {
            rows: optionsState.rowsPerPage,
            page: optionsState.page,
            sortOrder: sortOrder,
            filters: filters,
        })
        const { data: Items, total } = result.data
        if (Items) {
            setData([...Items])
            setOptionsState({
                ...optionsState,
                sortOrder: sortOrder,
            })
            setCount(total)
            setLoading(false)
        }
    }
    const changeRowsPerPage = async rowsPerPage => {
        setLoading(true)
        const result = await GET(url, {
            rows: rowsPerPage,
            page: optionsState.page,
            sortOrder: optionsState.sortOrder,
            filters: filters,
        })
        const { data: Items, total } = result.data
        if (Items) {
            setData([...Items])
            setOptionsState({
                ...optionsState,
                rowsPerPage: rowsPerPage,
            })
            setCount(total)
            setLoading(false)
        }
    }

    const handleFilterSubmit = async applyFilters => {
        setLoading(true)
        let filterList = applyFilters()
        const filterArr = {}
        filterList.forEach((el, index) => {
            if (el.length > 0) filterArr[columns[index].name] = el
        })
        setFilters(filterArr)
        const result = await GET(url, {
            rows: optionsState.rowsPerPage,
            page: 1,
            sortOrder: optionsState.sortOrder,
            filters: filterArr,
        })
        const { data: Items, total } = result.data
        if (Items) {
            setData([...Items])
            setOptionsState({
                ...optionsState,
                page: 1,
            })
            setCount(total)
            setLoading(false)
        }
    }

    useEffect(() => {
        setFirstLoading(true)
        const getData = async () => {
            const result = await GET(url, {
                rows: optionsState.rowsPerPage,
                page: optionsState.page,
                sortOrder: optionsState.sortOrder,
                filters: filters,
            })
            const { data: Items, total } = result.data
            if (Items) {
                setData([...Items])
                setCount(total)
                setFirstLoading(false)
            }
        }
        getData().catch(console.error)
        return () => {
            setData([])
        }
        // eslint-disable-next-line
    }, [refreshData])

    const options = {
        ...passedOptions,
        draggableColumns: {
            enabled: true,
        },
        expandableRows: expandableRows,
        expandableRowsHeader: expandableRowsHeader,
        search: false,
        download: false,
        responsive: 'vertical',
        elevation: 1,
        enableNestedDataAccess: '.',
        filterType: 'custom',
        fixedHeader: true,
        filter: filter,
        rowHover: true,
        tableBodyMaxHeight: '60vh',
        print: false,
        renderExpandableRow: (rowData, rowMeta) => {
            return renderExpandableRow(rowData, rowMeta, data, columns)
        },
        setRowProps: (row, dataIndex) => {
            return {
                // Set backgroundColor of archive auths
                style: {
                    backgroundColor:
                        data[dataIndex].isArchive || data[dataIndex].isFired
                            ? '#ffe3e3'
                            : data[dataIndex].isUnconfirmed ||
                              !!data[dataIndex].dateOfReturn !==
                                  !!data[dataIndex].dateOfHanding
                            ? '#fff8e3'
                            : '#ffffff',
                },
            }
        },
        customToolbar: () => {
            return (
                <>
                    {legend && <LegendButton legend={legend} />}
                    {passedOptions?.customToolbar()}
                </>
            )
        },
        customToolbarSelect: (selectedRows, displayData) => {
            const rowsToGenerate = selectedRows.data.map(
                id =>
                    data.flatMap((el, index) =>
                        index === id.dataIndex ? el : []
                    )
                // displayData.flatMap(el =>
                //     el.dataIndex === id.dataIndex ? el.data : []
                // )
            )
            if (pdf) {
                return (
                    <>
                        <GeneratePdfButton rows={rowsToGenerate} />
                    </>
                )
            }
        },
        //Server handling
        serverSide: true,
        count: count,
        sortOrder: optionsState.sortOrder,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page)
                    break
                case 'sort':
                    sort(tableState.sortOrder)
                    break
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage)
                    break
                default:
            }
        },
        confirmFilters: true,
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
                <div style={{ marginTop: '40px', minWidth: 500 }}>
                    <Button
                        variant='contained'
                        onClick={() => handleFilterSubmit(applyNewFilters)}
                    >
                        Filtruj
                    </Button>
                </div>
            )
        },
        onFilterConfirm: filterList => {},
        onFilterChange: (column, filterList, type) => {
            if (type === 'chip') {
                const newFilters = () => filterList
                handleFilterSubmit(newFilters)
            }
        },
        filterOptions: {
            display: (filterList, onChange, index, column) => {
                return (
                    <Autocomplete
                        multiple
                        freeSolo
                        options={
                            !!filtersAutocomplete &&
                            !!filtersAutocomplete[column.name]
                                ? filtersAutocomplete[column.name]
                                : []
                        }
                        limitTags={2}
                        defaultValue={filterList[index]}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant='outlined'
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        onChange={(event, newValue, reason) => {
                            filterList[index] = [...newValue]
                        }}
                        renderInput={params => (
                            <TextField {...params} label={column.label} />
                        )}
                    />
                )
            },
        },
        //Translation
        textLabels: {
            body: {
                noMatch: firstLoading
                    ? 'Trwa ładowanie...'
                    : 'Nie znaleziono pasujących rekordów',
                toolTip: 'Sortuj',
            },
            pagination: {
                next: 'Następna strona',
                previous: 'Poprzednia strona',
                rowsPerPage: 'Wierszy na stronie',
                displayRows: 'z',
            },
            toolbar: {
                search: 'Szukaj',
                downloadCsv: 'Pobierz CSV',
                viewColumns: 'Widoczne kolumny',
                filterTable: 'Filtruj',
            },
            filter: {
                all: 'Wszystko',
                title: 'FILTRY',
            },
            viewColumns: {
                title: 'Pokaż kolumny',
                titleAria: 'Pokaż/ukryj kolumny',
            },
            selectedRows: {
                text: 'wybranych wierszy',
                delete: 'Usuń',
                deleteAria: 'Usuń wybrane wiersze',
            },
        },
    }

    return (
        <Box sx={{ mt: 3 }}>
            <MUIDataTable
                columns={columns}
                data={data}
                options={options}
                title={
                    <Typography variant='h6'>
                        {title}
                        {loading && (
                            <CircularProgress
                                size={24}
                                style={{
                                    marginLeft: 15,
                                    position: 'relative',
                                    top: 4,
                                }}
                            />
                        )}
                    </Typography>
                }
            />
        </Box>
    )
}

export default memo(DataTable)
