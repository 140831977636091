const TabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`modal-tabpanel-${index}`}
            aria-labelledby={`modal-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    )
}

export default TabPanel
