import React from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import TextField from '@mui/material/TextField/TextField'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import Button from '@mui/material/Button/Button'
import { useState } from 'react'

const OneTimeAuthDialog = ({ open, onConfirm, onClose }) => {
    const [fullName, setFullName] = useState()

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Jednorazowe upoważnienie</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    label='Imię i nazwisko'
                    type='text'
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    fullWidth
                    variant='standard'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Zamknij</Button>
                <Button onClick={() => onConfirm(fullName)}>Zatwierdź</Button>
            </DialogActions>
        </Dialog>
    )
}

export default OneTimeAuthDialog
