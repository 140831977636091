import { useState, forwardRef } from 'react'
import './AuthCode.css'

import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import Stepper from '@mui/material/Stepper/Stepper'
import Step from '@mui/material/Step/Step'
import StepLabel from '@mui/material/StepLabel/StepLabel'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Divider from '@mui/material/Divider/Divider'

import TextField from '@mui/material/TextField'
import { POST } from '../../../Helpers/fetch'
import AuthCode from 'react-auth-code-input'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    maxHeight: '90%',
    overflow: 'auto',
    boxShadow: 24,
    p: 4,
}

const steps = ['Podaj hasło', 'Wygeneruj kod QR', 'Potwierdź kodem']

const AddTwoFactorModal = forwardRef(({ close }, ref) => {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [errorText, setErrorText] = useState('')
    const [authCode, setAuthCode] = useState('')
    const [authInputClass, setAuthInputClass] = useState('input')
    const [qrCode, setQrCode] = useState('')
    const [activeStep, setActiveStep] = useState(0)

    const passwordOnChange = event => {
        setPassword(event.target.value)
    }

    const getQrHandler = () => {
        setLoading(true)
        POST('auth/generateTwoFactor', { password })
            .then(res => {
                if (res.status === 200) {
                    setQrCode(res.data)
                    setLoading(false)
                    setActiveStep(prevActiveStep => prevActiveStep + 1)
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    setErrorText('Błędne hasło')
                    setLoading(false)
                }
            })
    }

    const confirmAuthCodeHandler = () => {
        setLoading(true)
        POST('auth/confirmAuthCode', { authCode })
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setActiveStep(prevActiveStep => prevActiveStep + 1)
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    setAuthInputClass('inputError')
                    setLoading(false)
                }
            })
    }

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    return (
        <Box sx={style} ref={ref}>
            <Typography gutterBottom variant={'h5'}>
                Zmień hasło
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {activeStep === steps.length && (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        Dodawanie dwustopniowej weryfikacji ukończone.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            pt: 2,
                        }}
                    >
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={() => close({ isSuccess: true })}>
                            Zamknij
                        </Button>
                    </Box>
                </>
            )}{' '}
            {activeStep === 0 && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant='h6' sx={{ mt: 2, mb: 1 }}>
                            Podaj hasło
                        </Typography>
                        <TextField
                            error={!!errorText}
                            helperText={errorText}
                            label='Hasło'
                            type='password'
                            value={password}
                            onChange={passwordOnChange}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            pt: 2,
                        }}
                    >
                        <Button
                            color='inherit'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        <LoadingButton loading={loading} onClick={getQrHandler}>
                            Dalej
                        </LoadingButton>
                    </Box>
                </>
            )}
            {activeStep === 1 && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant='h6' sx={{ mt: 2, mb: 1 }}>
                            Zeskanuj kod QR
                        </Typography>
                        {!!qrCode && <img alt='qr' src={qrCode} />}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            pt: 2,
                        }}
                    >
                        <Button
                            color='inherit'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        ></Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button onClick={handleNext}>Dalej</Button>
                    </Box>
                </>
            )}
            {activeStep === 2 && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant='h6' sx={{ mt: 2, mb: 1 }}>
                            Potwierdź kodem z aplikacji
                        </Typography>
                        <AuthCode
                            allowedCharacters='numeric'
                            onChange={val => setAuthCode(val)}
                            containerClassName='container'
                            inputClassName={authInputClass}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            pt: 2,
                        }}
                    >
                        <Button
                            color='inherit'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        ></Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button onClick={confirmAuthCodeHandler}>
                            Potwierdź
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    )
})

export default AddTwoFactorModal
