import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './DraftEditor.css'

const DraftEditor = ({ data, setData }) => {
    const blocksFromHtml = htmlToDraft(data)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    )

    const [editorState, setEditorState] = useState(() =>
        data
            ? EditorState.createWithContent(contentState)
            : EditorState.createEmpty()
    )

    const onEditorStateChange = newEditorState => {
        setEditorState(newEditorState)
        const contentState = editorState.getCurrentContent()
        const rawContentState = convertToRaw(contentState)
        const newData = draftToHtml(rawContentState)
        setData(newData)
    }

    const options = [
        'fontFamily',
        'fontSize',
        'colorPicker',
        'inline',
        'textAlign',
        'list',
        'remove',
    ]

    return (
        <div>
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbarClassName='editor-class'
                editorClassName='editor-class'
                toolbar={{
                    options: options,
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                    },
                    list: { options: ['unordered', 'ordered'] },
                }}
                localization={{
                    locale: 'pl',
                }}
            />
        </div>
    )
}

export default DraftEditor
