import { useState, useContext } from 'react'
import './AuthCode.css'
import TokenContext from '../../store/token-context'
import AuthCode from 'react-auth-code-input'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import Logo from '../../assets/images/logo_5.png'
import BackgroundImage from '../../assets/svg/prism.svg'
import { POST } from '../../Helpers/fetch'

function Copyright(props) {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}
        >
            {'Copyright © '}
            <Link color='inherit' href='#'>
                View
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#10403B',
            lighter: 'rgba(16, 65, 60, 0.1)',
        },
        secondary: {
            main: '#ff914d',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                },
            },
        },
    },
})

export default function SignIn() {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [password, setPassword] = useState('')
    const [authInputClass, setAuthInputClass] = useState('input')
    const [authCode, setAuthCode] = useState('')
    const [twoFa, setTwoFa] = useState(false)
    const [loginSessionId, setLoginSessionId] = useState(false)

    const tokenCtx = useContext(TokenContext)

    const loginHandler = async event => {
        setLoading(true)
        event.preventDefault()
        const { status, data } = await POST('auth/login', {
            email: email,
            password: password,
        }).catch(err => err.response)
        if (status === 200) {
            setErrorText('')
            tokenCtx.login(data.token)
        } else if (status === 401) {
            setErrorText('Błędny email lub hasło')
        } else if (status === 403 && data.message === 'User blocked.') {
            setErrorText('Konto zablokowane')
        } else if (status === 403) {
            setLoginSessionId(data.data)
            setTwoFa(true)
        }
        setLoading(false)
    }

    const passwordChangeHandler = pass => {
        setPassword(pass)
        if (errorText) {
            setErrorText('')
        }
    }
    const emailChangeHandler = email => {
        setEmail(email)
        if (errorText) {
            setErrorText('')
        }
    }

    const confirmAuthCodeHandler = () => {
        setLoading(true)
        POST('auth/loginConfirmAuthCode', { authCode, loginSessionId })
            .then(res => {
                if (res.status === 200) {
                    tokenCtx.login(res.data.token)
                    setLoading(false)
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    setAuthInputClass('inputError')
                    setLoading(false)
                }
            })
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Box sx={{ height: 150 }}>
                        <img style={{ height: 150 }} src={Logo} alt={'Logo'} />
                    </Box>
                    {!twoFa ? (
                        <>
                            <Typography component='h1' variant='h5' mt={1}>
                                Zaloguj się
                            </Typography>
                            <Box
                                component='form'
                                onSubmit={event => loginHandler(event)}
                                noValidate
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='email'
                                    label='Email'
                                    name='email'
                                    value={email}
                                    autoComplete='email'
                                    autoFocus
                                    onChange={e =>
                                        emailChangeHandler(e.target.value)
                                    }
                                />
                                <TextField
                                    margin='normal'
                                    required
                                    error={errorText}
                                    fullWidth
                                    helperText={errorText}
                                    name='password'
                                    label='Hasło'
                                    type='password'
                                    id='password'
                                    value={password}
                                    autoComplete='current-password'
                                    onChange={e =>
                                        passwordChangeHandler(e.target.value)
                                    }
                                />
                                <LoadingButton
                                    type='submit'
                                    fullWidth
                                    color='secondary'
                                    loading={loading}
                                    variant='contained'
                                    sx={{ mt: 3, mb: 2, color: '#ffe8d9' }}
                                >
                                    Zaloguj
                                </LoadingButton>
                            </Box>
                            <Link color='inherit' href='/forgotpassword'>
                                Nie pamiętasz hasła?
                            </Link>{' '}
                        </>
                    ) : (
                        <>
                            <Typography component='h1' variant='h5' mt={1}>
                                Podaj kod weryfikacji dwustopniowej
                            </Typography>
                            <AuthCode
                                allowedCharacters='numeric'
                                onChange={val => setAuthCode(val)}
                                containerClassName='container'
                                inputClassName={authInputClass}
                            />
                            <LoadingButton
                                fullWidth
                                color='secondary'
                                loading={loading}
                                variant='contained'
                                sx={{ mt: 3, mb: 2, color: '#ffe8d9' }}
                                onClick={confirmAuthCodeHandler}
                            >
                                Zatwierdź
                            </LoadingButton>
                        </>
                    )}
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    )
}
