import { useState, useContext } from 'react'
import TokenContext from '../../store/token-context'
import { checkEdit, moduleCheck } from '../../Helpers/authCheck'
import { Routes, Route, Navigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import Header from '../../components/Header/Header'
import SideMenu from '../../components/SideMenu/SideMenu'
import Content from '../../components/Content/Content'
import Settings from '../../components/Settings/Settings'
import Panel from '../Panel/Panel'

// import CandidateTable from '../../components/Tables/CandidateTable/CandidateTable'
import UsersTable from '../../components/Tables/UsersTable/UsersTable'
import AuthorizationsTable from '../../components/Tables/AuthorizationsTable/AuthorizationsTable'
import EmployeeTable from '../../components/Tables/EmployeeTable/EmployeeTable'
import RegistersTable from '../../components/Tables/RegisterTable/RegistersTable'
import IncidentRegisterTable from '../../components/Tables/IncidentRegisterTable/IncidentRegisterTable'
import DemandRegisterTable from '../../components/Tables/DemandRegisterTable/DemandRegisterTable'
import EntrustmentRegisterTable from '../../components/Tables/EntrustmentRegisterTable/EntrustmentRegisterTable'
import CategoryRegistersTable from '../../components/Tables/CategoryRegistersTable/CategoryRegistersTable'
import TrainingsRegisterTable from '../../components/Tables/TrainingsRegisterTable/TrainingsRegisterTable'
import EmployeeTrainingsTable from '../../components/Tables/EmployeeTrainingsTable/EmployeeTrainingsTable'
import AllEmployeesTrainingsTable from '../../components/Tables/AllEmployeesTrainingsTable/AllEmployeesTrainingsTable'
import ResolutionsAndOrdersTable from '../../components/Tables/ResolutionsAndOrdersTable/ResolutionsAndOrdersTable'
import SecurityPoliciesTable from '../../components/Tables/SecurityPoliciesTable/SecurityPoliciesTable'
import InformationPoliciesTable from '../../components/Tables/InformationPoliciesTable/InformationPoliciesTable'
import AgreementTable from '../../components/Tables/AgreementTable/AgreementTable'
import AuditReportTable from '../../components/Tables/AuditReportTable/AuditReportTable'
import PlannedCheckingTable from '../../components/Tables/PlannedCheckingTable/PlannedCheckingTable'
import EmployeeAuthorizationsTable from '../../components/Tables/EmployeeAuthorizationsTable/EmployeeAuthorizationsTable'
import AccountSettings from '../../components/AccountSettings/AccountSettings'
import ResourceRegisterTable from '../../components/Tables/ResourceRegisterTable/ResourceRegisterTable'
import AppsRegisterTable from '../../components/Tables/AppsRegisterTable/AppsRegisterTable'
import EmployeeResourcesTable from '../../components/Tables/EmployeeResourcesTable/EmployeeResourcesTable'
import MyView from '../MyView/MyView'
import Faq from '../../components/Faq/Faq'
import Materials from '../../components/Materials/Materials'
import KeysRegisterTable from '../../components/Tables/KeysRegisterTable/KeysRegisterTable'

const Layout = () => {
    const [open, setOpen] = useState(true)

    const { data: tokenData } = useContext(TokenContext)

    const changeOpen = () => {
        setOpen(!open)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Header changeOpen={changeOpen} open={open} />
            <SideMenu changeOpen={changeOpen} open={open} />
            <Content open={open}>
                <Routes>
                    {/* {moduleCheck(
                        'Kandydaci',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route path='kandydaci' element={<CandidateTable />} />
                    )} */}
                    {moduleCheck(
                        'Pracownicy',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route path='pracownicy' element={<EmployeeTable />} />
                    )}
                    {moduleCheck(
                        'Użytkownicy',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && <Route path='uzytkownicy' element={<UsersTable />} />}
                    {moduleCheck(
                        'Polityka bezpieczenstwa',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='polityka-bezpieczenstwa'
                            element={<SecurityPoliciesTable />}
                        />
                    )}
                    {moduleCheck(
                        'Polityka informacyjna',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='polityka-informacyjna'
                            element={<InformationPoliciesTable />}
                        />
                    )}
                    {moduleCheck(
                        'Rejestr czynności przetwarzania',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='rejestr-czynnosci-przetwarzania'
                            element={<RegistersTable />}
                        />
                    )}
                    {moduleCheck(
                        'Lista szkoleń',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <>
                            <Route
                                path='lista-szkolen'
                                element={<TrainingsRegisterTable />}
                            />
                            <Route
                                path='lista-szkolen/materialy/:trainingId'
                                element={<Materials />}
                            />
                        </>
                    )}

                    <Route
                        path='moje-szkolenia'
                        element={<EmployeeTrainingsTable />}
                    />
                    <Route
                        path='moje-szkolenia/materialy/:trainingId'
                        element={<Materials />}
                    />
                    <Route
                        path='moje-upowaznienia'
                        element={<EmployeeAuthorizationsTable />}
                    />
                    <Route
                        path='moje-zasoby'
                        element={<EmployeeResourcesTable />}
                    />

                    {moduleCheck(
                        'Szkolenia pracowników',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='szkolenia-pracownikow'
                            element={<AllEmployeesTrainingsTable />}
                        />
                    )}
                    {moduleCheck(
                        'Rejestr incydentów',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='rejestr-incydentow'
                            element={<IncidentRegisterTable />}
                        />
                    )}
                    {moduleCheck(
                        'Rejestr kategorii przetwarzania',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='rejestr-kategorii-przetwarzania'
                            element={<CategoryRegistersTable />}
                        />
                    )}
                    {moduleCheck(
                        'Rejestr umów powierzenia',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='rejestr-umow-powierzenia'
                            element={<EntrustmentRegisterTable />}
                        />
                    )}
                    {moduleCheck(
                        'Rejestr żądań',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='rejestr-zadan'
                            element={<DemandRegisterTable />}
                        />
                    )}
                    {moduleCheck(
                        'Rejestr zasobów',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='rejestr-zasobow'
                            element={<ResourceRegisterTable />}
                        />
                    )}
                    {moduleCheck(
                        'Klucze',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route path='klucze' element={<KeysRegisterTable />} />
                    )}
                    {moduleCheck(
                        'Rejestr systemów informatycznych',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='rejestr-systemow-informatycznych'
                            element={<AppsRegisterTable />}
                        />
                    )}
                    {moduleCheck(
                        'Sprawdzenia planowe',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='sprawdzenia-planowe'
                            element={<PlannedCheckingTable />}
                        />
                    )}
                    {moduleCheck(
                        'Sprawozdania z audytu',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='sprawozdania-z-audytu'
                            element={<AuditReportTable />}
                        />
                    )}
                    {moduleCheck(
                        'Uchwały i zarządzenia',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='uchwaly-i-zarzadzenia'
                            element={<ResolutionsAndOrdersTable />}
                        />
                    )}
                    {moduleCheck(
                        'Upoważnienia',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && (
                        <Route
                            path='upowaznienia'
                            element={<AuthorizationsTable />}
                        />
                    )}
                    {moduleCheck(
                        'Zgody',
                        tokenData.moduleAccess,
                        tokenData.role
                    ) && <Route path='zgody' element={<AgreementTable />} />}

                    {checkEdit(tokenData.role) && (
                        <Route path='menadzer' element={<Panel />} />
                    )}
                    <Route path='moje-view' element={<MyView />} />
                    <Route path='konto' element={<AccountSettings />} />
                    <Route path='ustawienia' element={<Settings />} />
                    <Route path='baza-wiedzy' element={<Faq />} />
                    <Route
                        path='*'
                        element={
                            <Navigate
                                to={
                                    checkEdit(tokenData.role)
                                        ? 'menadzer'
                                        : 'moje-szkolenia'
                                }
                            />
                        }
                    />
                </Routes>
            </Content>
        </Box>
    )
}

export default Layout
