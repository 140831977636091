import { useState, useContext } from 'react'
import TokenContext from '../../../store/token-context'

import Tooltip from '@mui/material/Tooltip/Tooltip'
import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import Box from '@mui/material/Box'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'

const GeneratePdfButton = ({ rows }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const tokenCtx = useContext(TokenContext)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const generatePdf = (data, pdfType) => {
        handleClose()

        let url
        if (window.location.hostname.includes('localhost')) {
            url = `${process.env.REACT_APP_API_URL}`
        } else {
            url = window.location.origin + '/api'
        }

        data.forEach(el => {
            fetch(`${url}/employee/getAuthPdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenCtx.token,
                },
                body: JSON.stringify({ data: [...el], pdfType: pdfType }),
            }).then(async res => {
                if (res.status === 200) {
                    const blob = await res.blob()
                    const file = new Blob([blob], { type: 'application/pdf' })
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file)
                    //Open the URL on new Window
                    window.open(fileURL)
                }
            })
        })
    }
    return (
        <Box sx={{ mr: 2 }}>
            <Tooltip title='Generuj PDF'>
                <IconButton onClick={handleClick}>
                    <PictureAsPdfOutlinedIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id='basic-menu'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => generatePdf(rows, '1')}>
                    Upoważnienie
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default GeneratePdfButton
