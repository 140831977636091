import Typography from '@mui/material/Typography/Typography'
import Box from '@mui/material/Box/Box'
import { FormContainer, AutocompleteElement } from 'react-hook-form-mui'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { forwardRef, useState, useEffect } from 'react'
import { POST, GET } from '../../../Helpers/fetch'
import Divider from '@mui/material/Divider/Divider'
import Grid from '@mui/material/Grid/Grid'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const ConnectUserToEmployeeModal = forwardRef(
    ({ closeAndRefresh, data }, ref) => {
        const [loading, setLoading] = useState(false)
        const [employeeList, setEmployeeList] = useState([])
        const [currentEmployee, setCurrentEmployee] = useState('')
        const [loadingEmployees, setLoadingEmployees] = useState(false)

        const getEmployees = async () => {
            setLoadingEmployees(true)
            const { data: Items } = await GET('employee/get')
            const employees = Items.data.map(el => ({
                label: `${el.firstName} ${el.lastName} (${el.email})`,
                id: el._id,
            }))

            if (data.employeeId) {
                const employee = employees.find(el => el.id === data.employeeId)
                if (employee) {
                    setCurrentEmployee(employee.label)
                }
            }

            setEmployeeList([...employees])
            setLoadingEmployees(false)
        }

        const editUserHandler = userData => {
            setLoading(true)
            const edit = async () => {
                await POST('users/edit', {
                    _id: data._id,
                    employeeId: userData.employeeId.id,
                })
            }
            edit()
                .then(() => {
                    setLoading(false)
                    closeAndRefresh()
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }

        useEffect(() => {
            getEmployees().catch(err => console.error(err))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant={'h5'}>
                    Połącz konto użytkownika z pracownikiem
                </Typography>
                <Divider />
                <FormContainer onSuccess={editUserHandler}>
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={12}>
                            <AutocompleteElement
                                rules={{ required: 'Pole jest wymagane' }}
                                loading={loadingEmployees}
                                size={'small'}
                                required
                                label={'Pracownik'}
                                fullWidth
                                name={'employeeId'}
                                options={employeeList}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Konto: {data.firstName} {data.lastName} (
                                {data.email})
                            </Typography>
                            <Typography>
                                Aktualny pracownik: {currentEmployee || 'Brak'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={closeAndRefresh}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Zapisz
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default ConnectUserToEmployeeModal
