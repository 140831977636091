import Button from '@mui/material/Button/Button'

const columns = props => [
    { name: 'resourceType', label: 'Rodzaj zasobu' },
    {
        name: 'manufacturerAndModel',
        label: 'Producent/Model',
    },
    {
        name: 'serialNumber',
        label: 'Numer seryjny',
    },
    {
        name: 'inventoryNumber',
        label: 'Numer inwentaryzacyjny',
    },
    {
        name: 'resourceReceiptConfirmed',
        label: ' ',
        options: {
            customBodyRender: (value, tableMeta) => {
                console.log(props.data[tableMeta.rowIndex])
                const onClickHandler = () =>
                    props.openDialogHandler(
                        props.data[tableMeta.rowIndex].resourceId
                    )
                return (
                    <Button disabled={value} onClick={onClickHandler}>
                        {value ? 'Odbiór potwierdzono' : 'Potwierdź odbiór'}
                    </Button>
                )
            },
        },
    },
]

export default columns
