import { forwardRef, useState } from 'react'
import {
    FormContainer,
    TextFieldElement,
    RadioButtonGroup,
} from 'react-hook-form-mui'
import { POST } from '../../../../Helpers/fetch'
import { parseError } from '../../../../Helpers/formValidation'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton'
import { useForm, useEffect } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button/Button'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    bgcolor: 'background.paper',
    maxHeight: '90%',
    overflow: 'scroll',
    boxShadow: 24,
    p: 4,
}

const DoingTest = forwardRef(
    ({ close, setSnackbarInfo, refreshData, trainingId, testData }, ref) => {
        const formContext = useForm()

        const [loading, setLoading] = useState(false)

        function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1))
                ;[array[i], array[j]] = [array[j], array[i]]
            }

            return array
        }

        const sendAnswers = data => {
            setLoading(true)

            const send = async () => {
                await POST('training/sendAnswers/' + trainingId, data)
            }
            send()
                .then(() => {
                    // setSnackbarInfo({
                    //     open: true,
                    //     type: 'success',
                    //     message: 'Dodano test',
                    // })
                    setLoading(false)
                    if (!!refreshData) {
                        refreshData()
                    }
                    close()
                })
                .catch(() => {
                    setLoading(false)
                    // setSnackbarInfo({
                    //     open: true,
                    //     type: 'error',
                    //     message: 'Błąd: Nie dodano testu',
                    // })
                })
        }

        return (
            <Box ref={ref} sx={style}>
                <Typography gutterBottom variant={'h5'}>
                    Wykonaj test
                </Typography>
                <Divider />
                <FormContainer
                    formContext={formContext}
                    onSuccess={sendAnswers}
                >
                    {testData.map((item, index) => (
                        <Grid
                            key={item.id}
                            container
                            sx={{ mt: 1 }}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Box display={'flex'} flexDirection={'column'}>
                                    <Typography variant='subtitle1'>
                                        Pytanie {index + 1}
                                    </Typography>
                                    <Typography variant='subtitle2'>
                                        {testData[index].text}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <RadioButtonGroup
                                    label='Odpowiedź'
                                    name={`answer.${index}`}
                                    options={shuffleArray(
                                        testData[index].answers.map(el => ({
                                            id: el._id,
                                            label: el.text,
                                        }))
                                    )}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={() => close(false)}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Prześlij odpowiedzi
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default DoingTest
