import { forwardRef, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default function CustomSnackbar({
    children,
    type,
    isOpen,
    setSnackbarInfo,
}) {
    const [open, setOpen] = useState(isOpen)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
        setSnackbarInfo({})
    }

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {children}
            </Alert>
        </Snackbar>
    )
}
