import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Typography from '@mui/material/Typography/Typography'
import Box from '@mui/material/Box/Box'
import { Tooltip } from '@mui/material'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import moment from 'moment/moment'
import { GET_WITH_DOWNLOAD_NAMED } from '../../../Helpers/fetch'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'

const downloadFile = incRegId => {
    GET_WITH_DOWNLOAD_NAMED(`/incidentRegister/download/${incRegId}`)
}

const columns = props => [
    {
        name: 'violationDate',
        label: 'Data stwierdzenia naruszenia',
        options: {
            customBodyRender: value =>
                value ? (
                    <Typography>
                        {moment(value).format('DD.MM.YYYY')}
                    </Typography>
                ) : null,
        },
    },
    { name: 'violationNature', label: 'Charakter naruszenia' },
    {
        name: 'injuredPersonsCategories',
        label: 'Kategorie poszkodowanych osób',
    },
    {
        name: 'uodoDate',
        label: 'Data zgłoszenia do UODO',
        options: {
            customBodyRender: value =>
                value ? (
                    <Typography>
                        {moment(value).format('DD.MM.YYYY')}
                    </Typography>
                ) : null,
        },
    },
    {
        name: 'numberOfPeople',
        label: 'Ilość osób',
        options: { display: false },
    },
    {
        name: 'numberOfViolatedEntries',
        label: 'Ilość naruszonych wpisów/rekordów',
        options: { display: false },
    },
    {
        name: 'breachConsequences',
        label: 'Możliwe konsekwencje naruszenia',
        options: { display: false },
    },
    { name: 'remedies', label: 'Środki zaradcze', options: { display: false } },
    {
        name: 'incidentsCircumstances',
        label: 'Okoliczności incydentu',
        options: { display: false },
    },
    {
        name: 'fileName',
        label: 'Załącznik',
        options: {
            customBodyRender: (value, tableMeta) => {
                return (
                    value && (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Tooltip title='Pobierz załącznik'>
                                <IconButton
                                    onClick={() =>
                                        downloadFile(
                                            props.data[tableMeta.rowIndex]._id
                                        )
                                    }
                                >
                                    <SimCardDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                )
            },
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={() => props.editHandler()}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
