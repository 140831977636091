import { useRef, useContext, useState } from 'react'
import TokenContext from '../../../store/token-context'
import { checkEdit } from '../../../Helpers/authCheck'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import { CSVLink } from 'react-csv'

const CustomToolbar = ({ onClick, download, getCsvData, headers }) => {
    const csvLink = useRef()
    const { data: tokenData } = useContext(TokenContext)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const csvHandler = async () => {
        setLoading(true)
        const data = await getCsvData()
        setData(data)
        csvLink.current.link.click()
        setLoading(false)
    }

    return (
        checkEdit(tokenData.role) && (
            <>
                <Tooltip title={'Dodaj'}>
                    <IconButton onClick={onClick}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                {download && (
                    <>
                        <Tooltip title={'Pobierz CSV'}>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <IconButton onClick={csvHandler}>
                                    <DownloadIcon />
                                </IconButton>
                            )}
                        </Tooltip>
                        <CSVLink
                            data={data}
                            filename={'export.csv'}
                            headers={headers}
                            ref={csvLink}
                            target='_blank'
                        />
                    </>
                )}
            </>
        )
    )
}

export default CustomToolbar
