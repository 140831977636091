import { useState, useEffect } from 'react'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Divider from '@mui/material/Divider/Divider'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { FormContainer, DatePickerElement } from 'react-hook-form-mui'
import moment from 'moment'
import { parseError } from '../../../../Helpers/formValidation'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { AutocompleteElement } from 'react-hook-form-mui'
import { GET, POST } from '../../../../Helpers/fetch'
import Tab from '@mui/material/Tab/Tab'
import Tabs from '@mui/material/Tabs/Tabs'
import TabPanel from '../../../CustomMuiComponents/TabPanel'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const AddEmployeeTraining = ({ close }) => {
    const formContextByEmployee = useForm()
    const formContextByTraining = useForm()

    const { id: employeeId } = formContextByEmployee.watch('employeeName') || {
        id: null,
    }

    const { id: trainingId } = formContextByTraining.watch('trainings') || {
        id: null,
    }
    const employeeArray = formContextByTraining.watch('employeeName')

    const [loading, setLoading] = useState(false)
    const [employeeList, setEmployeeList] = useState([])
    const [loadingEmployees, setLoadingEmployees] = useState(false)
    const [trainingList, setTrainingList] = useState([])
    const [loadingTrainings, setLoadingTrainings] = useState(false)
    const [isAllEmployees, setIsAllEmployees] = useState(false)
    const [tab, setTab] = useState(0)

    const getEmployees = async () => {
        setLoadingEmployees(true)
        const { data: Items } = await GET(
            `employee/getForTrainings?trainingId=${trainingId}`
        )
        const employees = Items.data.map(el => ({
            label: `${el.firstName} ${el.lastName} (${el.email})`,
            id: el._id,
        }))
        setEmployeeList([...employees])
        setLoadingEmployees(false)
    }
    const getTrainings = async () => {
        setLoadingTrainings(true)
        const { data: Items } = await GET(
            `training/get?employeeId=${employeeId}`
        )
        const trainings = Items.data.map(el => ({
            label: `${el.name}`,
            id: el._id,
        }))
        setTrainingList([...trainings])
        setLoadingTrainings(false)
    }

    const submitByEmployeeHandler = data => {
        setLoading(true)

        const sendData = {
            employeeId: data.employeeName.id,
            trainings: data.trainings.map(el => ({
                name: el.label,
                _id: el.id,
                trainingDate: data.trainingDate,
            })),
        }
        const submit = async () => {
            await POST('training/send', sendData)
        }
        submit()
            .then(() => {
                setLoading(false)
                close()
            })
            .catch(err => {
                setLoading(false)
                console.error(err)
            })
    }
    const submitByTrainingHandler = data => {
        setLoading(true)
        let sendData
        if (isAllEmployees) {
            sendData = employeeList.map(el => ({
                employeeId: el.id,
                trainings: {
                    name: data.trainings.label,
                    _id: data.trainings.id,
                    trainingDate: data.trainingDate,
                },
            }))
        } else {
            sendData = data.employeeName.map(el => ({
                employeeId: el.id,
                trainings: {
                    name: data.trainings.label,
                    _id: data.trainings.id,
                    trainingDate: data.trainingDate,
                },
            }))
        }

        const submit = async () => {
            await POST('training/sendByTraining', sendData)
        }
        submit()
            .then(() => {
                setLoading(false)
                close()
            })
            .catch(err => {
                setLoading(false)
                console.error(err)
            })
    }

    const changeTabHandler = (event, newValue) => {
        setTab(newValue)
        if (newValue === 0) {
            formContextByTraining.reset({})
        } else {
            formContextByEmployee.reset({})
        }
    }

    useEffect(() => {
        formContextByTraining.setValue('employeeName', [])
        getEmployees().catch(err => console.error(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainingId])

    useEffect(() => {
        formContextByEmployee.setValue('trainings', [])
        getTrainings().catch(err => console.error(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeId])

    useEffect(() => {
        if (employeeArray && employeeArray.some(el => el.id === 'all')) {
            setIsAllEmployees(true)
        } else {
            setIsAllEmployees(false)
        }
    }, [employeeArray])

    return (
        <Box sx={style}>
            <Typography gutterBottom variant='h6'>
                Wysłanie pracownika na szkolenie
            </Typography>
            <Divider />

            <Tabs value={tab} onChange={changeTabHandler} centered>
                <Tab label='Według pracownika' />
                <Tab label='Według szkoleń' />
            </Tabs>

            <TabPanel value={tab} index={0}>
                <FormContainer
                    formContext={formContextByEmployee}
                    onSuccess={submitByEmployeeHandler}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={6}>
                            <AutocompleteElement
                                rules={{ required: 'Pole jest wymagane' }}
                                loading={loadingEmployees}
                                size={'small'}
                                label={'Pracownik'}
                                fullWidth
                                name={'employeeName'}
                                options={employeeList}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePickerElement
                                required
                                parseError={err => {
                                    if (err.type === 'validate') {
                                        return 'Data szkolenia nie może być starsza niż maj 2018'
                                    } else {
                                        return parseError(err)
                                    }
                                }}
                                label='Data szkolenia'
                                {...formContextByEmployee.register(
                                    'trainingDate',
                                    {
                                        validate: value => {
                                            return moment(value).isAfter(
                                                '2018-04-30',
                                                'day'
                                            )
                                        },
                                    }
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteElement
                                rules={{ required: 'Pole jest wymagane' }}
                                loading={loadingTrainings}
                                size={'small'}
                                label={'Szkolenia'}
                                fullWidth
                                multiple
                                autocompleteProps={{ limitTags: 2 }}
                                showCheckbox
                                name={'trainings'}
                                options={trainingList}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={close}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Dodaj
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </TabPanel>

            <TabPanel value={tab} index={1}>
                <FormContainer
                    formContext={formContextByTraining}
                    onSuccess={submitByTrainingHandler}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={12}>
                            <AutocompleteElement
                                rules={{ required: 'Pole jest wymagane' }}
                                loading={loadingTrainings}
                                size={'small'}
                                label={'Szkolenia'}
                                fullWidth
                                name={'trainings'}
                                options={trainingList}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutocompleteElement
                                rules={{ required: 'Pole jest wymagane' }}
                                loading={loadingEmployees}
                                size={'small'}
                                label={'Pracownik'}
                                multiple
                                autocompleteProps={{
                                    limitTags: 2,
                                    getOptionDisabled: option =>
                                        isAllEmployees && option.id !== 'all',
                                }}
                                showCheckbox
                                fullWidth
                                name={'employeeName'}
                                options={
                                    employeeList.length > 0
                                        ? [
                                              { label: 'Wszyscy', id: 'all' },
                                              ...employeeList,
                                          ]
                                        : []
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePickerElement
                                required
                                parseError={err => {
                                    if (err.type === 'validate') {
                                        return 'Data szkolenia nie może być starsza niż maj 2018'
                                    } else {
                                        return parseError(err)
                                    }
                                }}
                                label='Data szkolenia'
                                {...formContextByTraining.register(
                                    'trainingDate',
                                    {
                                        validate: value => {
                                            return moment(value).isAfter(
                                                '2018-04-30',
                                                'day'
                                            )
                                        },
                                    }
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={close}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Dodaj
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </TabPanel>
        </Box>
    )
}

export default AddEmployeeTraining
