import TokenContext from '../../../../store/token-context'
import Typography from '@mui/material/Typography/Typography'
import Box from '@mui/material/Box/Box'
import moment from 'moment'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { forwardRef, useState, useContext } from 'react'
import { PATCH } from '../../../../Helpers/fetch'
import { checkEdit } from '../../../../Helpers/authCheck'
import { Divider } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const TrainingsDetails = forwardRef(({ closeAndRefresh, data }, ref) => {
    const {
        name,
        isCompleted,
        completeDate,
        expiryDate,
        _id,
        documentId,
        trainingDate,
        completionHistory,
        testResult,
    } = data
    const [loading, setLoading] = useState(false)

    const { data: tokenData } = useContext(TokenContext)

    console.log(data)

    const renewTrainingHandler = () => {
        setLoading(true)
        const renew = async () => {
            await PATCH('training/renew', {
                trainingId: _id,
                documentId: documentId,
            })
        }

        renew()
            .then(() => {
                setLoading(false)
                closeAndRefresh()
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    return (
        <Box sx={style} ref={ref}>
            <Typography variant='h5'>{name}</Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Typography>
                Czy ukończono: {isCompleted ? 'Tak' : 'Nie'}
            </Typography>
            <Typography>
                Data szkolenia:{' '}
                {trainingDate
                    ? moment(trainingDate).format('DD-MM-YYYY')
                    : 'Brak'}
            </Typography>
            <Typography>
                Data ukończenia:{' '}
                {completeDate
                    ? moment(completeDate).format('DD-MM-YYYY')
                    : 'Nie ukończono'}
            </Typography>
            <Typography>
                Data wygaśnięcia:{' '}
                {expiryDate ? moment(expiryDate).format('DD-MM-YYYY') : 'Brak'}
            </Typography>
            <Typography>
                Wynik testu: {testResult?.score ? testResult.score : 'Brak'}
            </Typography>
            <Typography>
                Czy test zdany: {testResult?.isPassed ? 'Tak' : 'Nie'}
            </Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Typography variant='subtitle1'>
                Historia ukończenia szkoleń:
            </Typography>
            {completionHistory?.map(el => (
                <Typography variant='body2'>
                    {moment(el).format('DD-MM-YYYY')}
                </Typography>
            ))}
            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'right',
                }}
            >
                <Button
                    sx={{ mr: 1 }}
                    variant='outlined'
                    onClick={closeAndRefresh}
                >
                    Zamknij
                </Button>
                <LoadingButton
                    onClick={renewTrainingHandler}
                    loading={loading}
                    disabled={!isCompleted || !checkEdit(tokenData.role)}
                    variant='contained'
                >
                    Wyślij ponownie
                </LoadingButton>
            </Box>
        </Box>
    )
})

export default TrainingsDetails
