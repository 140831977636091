import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { moduleCheck } from '../../../Helpers/authCheck'
import TokenContext from '../../../store/token-context'

//mui
import ListItemButton from '@mui/material/ListItemButton/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon'
import ListItemText from '@mui/material/ListItemText/ListItemText'

const SideMenuButton = ({ to, icon, text, module, nested = false }) => {
    const { data: tokenData } = useContext(TokenContext)

    const isAuth = module
        ? moduleCheck(module, tokenData.moduleAccess, tokenData.role)
        : true

    return (
        isAuth && (
            <ListItemButton
                component={Link}
                to={to}
                sx={nested ? { pl: 4 } : {}}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{ fontSize: '0.9em' }}
                    primary={text}
                />
            </ListItemButton>
        )
    )
}

export default SideMenuButton
