import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

let logoutTimer

const TokenContext = React.createContext({
    token: '',
    role: '',
    isLoggedIn: false,
    login: token => {},
    logout: () => {},
})

const calculateRemainingTime = expirationTime => {
    const currentTime = new Date().getTime()
    const adjExpirationTime = expirationTime * 1000

    return adjExpirationTime - currentTime
}

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token')
    const storedExpirationTime = localStorage.getItem('expirationTime')

    const remainingTime = calculateRemainingTime(storedExpirationTime)

    if (remainingTime <= 60000) {
        localStorage.removeItem('token')
        localStorage.removeItem('expirationTime')
        return null
    }

    return { token: storedToken, duration: remainingTime }
}

export const TokenContextProvider = props => {
    const tokenData = retrieveStoredToken()

    let initialToken
    let initialData

    if (tokenData) {
        initialToken = tokenData.token
        axios.defaults.headers.common['Authorization'] =
            'Bearer ' + tokenData.token
        initialData = jwt_decode(tokenData.token)
    }

    const [token, setToken] = useState(initialToken)
    const [data, setData] = useState(initialData)

    const userIsLoggedIn = !!token

    const logoutHandler = () => {
        setToken(null)
        delete axios.defaults.headers.common['Authorization']
        localStorage.removeItem('token')
        localStorage.removeItem('expirationTime')

        if (logoutTimer) {
            clearTimeout(logoutTimer)
        }
    }

    const loginHandler = token => {
        const data = jwt_decode(token)
        setData(data)

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        localStorage.setItem('token', token)
        localStorage.setItem('expirationTime', data.exp)

        const remainingTime = calculateRemainingTime(data.exp)

        logoutTimer = setTimeout(logoutHandler, remainingTime)

        setToken(token)
    }

    useEffect(() => {
        if (tokenData) {
            logoutTimer = setTimeout(logoutHandler, tokenData.duration)
        }
    }, [tokenData])

    const contextValue = {
        token: token,
        data: data,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
    }

    return (
        <TokenContext.Provider value={contextValue}>
            {props.children}
        </TokenContext.Provider>
    )
}

export default TokenContext
