import IconButton from '@mui/material/IconButton/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import { checkAdmin } from '../../../Helpers/authCheck'

const columns = props => [
    { name: 'firstName', label: 'Imię' },
    { name: 'lastName', label: 'Nazwisko' },
    { name: 'email', label: 'E-mail' },
    {
        name: 'employeeId',
        label: 'Pracownik',
        options: {
            filter: false,
            sort: false,
            customBodyRender: value => (value ? 'Tak' : 'Nie'),
        },
    },
    {
        name: 'twoFactor.isSet',
        label: 'Weryfikacja dwuetapowa',
        options: {
            filter: false,
            sort: false,
            customBodyRender: value => (value ? 'Tak' : 'Nie'),
        },
    },
    { name: 'role', label: 'Rola' },
    {
        name: 'isBlocked',
        label: 'Zablokowane',
        options: {
            filter: false,
            sort: false,
            customBodyRender: value => (value ? 'Tak' : 'Nie'),
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionsAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkAdmin(props.role) && (
                                <>
                                    <MenuItem onClick={props.editHandler}>
                                        Edytuj
                                    </MenuItem>
                                    <MenuItem
                                        onClick={props.connectToEmployeeHandler}
                                    >
                                        Połącz
                                    </MenuItem>
                                    <MenuItem
                                        onClick={props.disableTwoFactorHandler}
                                        disabled={!props.twoFactor}
                                    >
                                        Wyłącz podwójną weryfikację
                                    </MenuItem>
                                    <MenuItem onClick={props.blockUserHandler}>
                                        {props.isBlocked
                                            ? 'Odblokuj użytkownika'
                                            : 'Zablokuj użytkownika'}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={
                                            props.openChangePasswordHandler
                                        }
                                    >
                                        Zmień hasło
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            props.setOpenDeleteDialog(true)
                                        }
                                    >
                                        Usuń
                                    </MenuItem>
                                </>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
