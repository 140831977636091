import axios from 'axios'
import fileDownload from 'js-file-download'

if (window.location.hostname.includes('netlify')) {
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`
} else if (window.location.hostname.includes('localhost')) {
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`
} else {
    axios.defaults.baseURL = window.location.origin + '/api'
}

export const GET = (url, params) => {
    return axios.get(`${url}`, { params: params })
}

export const GET_WITH_DOWNLOAD = url => {
    return axios.get(`${url}`, { responseType: 'blob' })
}

export const GET_WITH_DOWNLOAD_NAMED = url => {
    return GET_WITH_DOWNLOAD(url).then(res => {
        let filename = res.headers['view-filename']
        if (!filename) {
            filename = 'Załącznik.pdf'
        } else {
            filename = decodeURI(filename)
        }
        fileDownload(res.data, filename)
    })
}

export const POST_WITH_DOWNLOAD = (url, data) => {
    return axios.post(
        `${url}`,
        { data },
        {
            headers: { 'Content-Type': 'application/json' },
            responseType: 'blob',
        }
    )
}

export const POST = (url, data) => {
    return axios.post(
        `${url}`,
        { data },
        { headers: { 'Content-Type': 'application/json' } }
    )
}
export const POST_WITH_FILE = (url, data) => {
    return axios.post(`${url}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

export const PUT = (url, data) => {
    return axios.put(
        `${url}`,
        { data },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

export const PATCH = (url, data) => {
    return axios.patch(
        `${url}`,
        { data },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

export const DELETE = url => {
    return axios.delete(`${url}`)
}
export const DELETE_WITH_DOWNLOAD = url => {
    return axios.delete(`${url}`, { responseType: 'blob' })
}
