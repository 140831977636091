import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import Divider from '@mui/material/Divider/Divider'
import { Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { forwardRef, useEffect, useState } from 'react'
import { parseError } from '../../../Helpers/formValidation'
import { POST, GET } from '../../../Helpers/fetch'
import CustomAutocomplete from '../../CustomMuiComponents/CustomAutocomplete'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const AddCategoryRegisterModal = forwardRef(
    ({ close, setSnackbarInfo }, ref) => {
        const [loading, setLoading] = useState(false)
        const [, setSafeguardsLoading] = useState(false)
        const [safeguards, setSafeguards] = useState([])
        const [refreshSafeguards, setRefreshSafeguards] = useState(false)

        const addSafeguardsHandler = data => {
            const addSafeguards = async () => {
                await POST('categoryRegister/addSafeguards', { name: data })
            }
            addSafeguards().catch(err => console.log(err))
            setRefreshSafeguards(!refreshSafeguards)
        }

        useEffect(() => {
            setSafeguardsLoading(true)
            const getSafeguards = async () => {
                const { data: Items } = await GET(
                    'categoryRegister/getSafeguards'
                )
                if (Items) {
                    const safeguards = Items.data.map(el => ({
                        id: el.name,
                        title: el.name,
                    }))
                    setSafeguards([...safeguards])
                    setSafeguardsLoading(false)
                }
            }
            getSafeguards().catch(console.error)
            return () => {
                setSafeguards([])
            }
        }, [refreshSafeguards])

        const addCatRegHandler = data => {
            data.safeguards = data.safeguards.map(el => el.id ?? el)
            const addRegister = async () => {
                await POST('categoryRegister/add', { ...data })
            }
            addRegister()
                .then(() => {
                    setSnackbarInfo({
                        open: true,
                        type: 'success',
                        message: 'Dodano wpis do rejestru',
                    })
                    setLoading(false)
                    close(true)
                })
                .catch(() => {
                    setLoading(false)
                    setSnackbarInfo({
                        open: true,
                        type: 'error',
                        message: 'Błąd: Nie dodano wpisu',
                    })
                })
        }

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant='h6'>
                    Dodawanie wpisu do rejestru kategorii przetwarzania
                </Typography>
                <Divider />
                <FormContainer onSuccess={addCatRegHandler}>
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'processingCategories'}
                                parseError={parseError}
                                fullWidth
                                autoFocus
                                required
                                label='Kategorie przetwarzań'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'measuresDescription'}
                                parseError={parseError}
                                fullWidth
                                minRows={3}
                                maxRows={4}
                                multiline
                                required
                                label='Ogólny opis technicznych i organizacyjnych środków bezpieczeństwa'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'adminData'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                required
                                label='Nazwa i dane kontaktowe administratora'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'coadminData'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                label='Nazwa i dane kontaktowe współadministratora'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'adminRepData'}
                                parseError={parseError}
                                fullWidth
                                minRows={3}
                                maxRows={4}
                                multiline
                                label='Nazwa i dane kontaktowe przedstawiciela administratora'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'dataProtectionOfficer'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                label='Inspektor ochrony danych administratora'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'thirdCountryNames'}
                                parseError={parseError}
                                fullWidth
                                multiline
                                label='Nazwy państw trzecich lub organizacji międzynarodowych, do których dane są przekazywane'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextFieldElement
                                name={'safeguardsDocumentation'}
                                parseError={parseError}
                                fullWidth
                                minRows={3}
                                maxRows={4}
                                multiline
                                label='Dokumentacja odpowiednich zabezpieczeń danych osobowych przekazywanych na podstawie art. 49 ust. 1 akapit drug'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomAutocomplete
                                name={'safeguards'}
                                addToList={addSafeguardsHandler}
                                dialogTitle='Dodaj nowe zabezpieczenie'
                                label='Zabezpieczenia'
                                multiple
                                options={safeguards}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={() => close(false)}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Dodaj
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default AddCategoryRegisterModal
