import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import {
    AutocompleteElement,
    FormContainer,
    TextFieldElement,
} from 'react-hook-form-mui'
import Divider from '@mui/material/Divider/Divider'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { TextField } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { parseError } from '../../../Helpers/formValidation'
import { GET, POST } from '../../../Helpers/fetch'
import CustomAutocomplete from '../../CustomMuiComponents/CustomAutocomplete'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const AddRegisterModal = forwardRef(({ close, setSnackbarInfo }, ref) => {
    const [, setAppsLoading] = useState(false)
    const [, setTagsLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [apps, setApps] = useState([])
    const [tags, setTags] = useState([])
    // const [refreshApps, setRefreshApps] = useState(false)
    const [refreshTags, setRefreshTags] = useState(false)

    const addROPAHandler = data => {
        const tagsArray = data.tags.map(el => el.id)
        const addRegister = async () => {
            await POST('register/add', {
                ...data,
                tags: tagsArray,
            })
        }
        addRegister()
            .then(() => {
                setSnackbarInfo({
                    open: true,
                    type: 'success',
                    message: 'Dodano wpis do rejestru',
                })
                setLoading(false)
                close(true)
            })
            .catch(() => {
                setLoading(false)
                setSnackbarInfo({
                    open: true,
                    type: 'error',
                    message: 'Błąd: Nie dodano wpisu',
                })
            })
    }

    // const addAppsHandler = data => {
    //     const addApp = async () => {
    //         await POST('register/addApps', { name: data })
    //     }
    //     addApp().catch(err => console.log(err))
    //     setRefreshApps(!refreshApps)
    // }

    const addTagsHandler = data => {
        const addTag = async () => {
            await POST('register/addTags', { name: data })
        }
        addTag().catch(err => console.log(err))
        setRefreshTags(!refreshTags)
    }

    useEffect(() => {
        setAppsLoading(true)
        setTagsLoading(true)
        const getApps = async () => {
            const { data: Items } = await GET('appsRegister/getForAuth')
            if (Items) {
                const apps = Items.result.map(el => ({
                    id: el._id,
                    label: el.name,
                }))
                console.log(apps)
                setApps([...apps])
                setAppsLoading(false)
            }
        }
        const getTags = async () => {
            const { data: Items } = await GET('register/getTags')
            if (Items) {
                const tags = Items.data.map(el => ({
                    id: el.name,
                    title: el.name,
                }))
                setTags([...tags])
                setTagsLoading(false)
            }
        }
        getTags().catch(console.error)
        getApps().catch(console.error)
        return () => {
            setApps([])
            setTags([])
        }
    }, [refreshTags])

    return (
        <Box sx={style} ref={ref}>
            <Typography gutterBottom variant='h6'>
                Dodawanie wpisu do rejestru czynności przetwarzania
            </Typography>
            <Divider />
            <FormContainer onSuccess={addROPAHandler}>
                <Grid container sx={{ mt: 1 }} spacing={2}>
                    <Grid item xs={6}>
                        <TextFieldElement
                            name={'name'}
                            parseError={parseError}
                            fullWidth
                            autoFocus
                            required
                            label='Nazwa'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomAutocomplete
                            name={'tags'}
                            addToList={addTagsHandler}
                            dialogTitle='Dodaj nową grupę'
                            label='Grupy'
                            required
                            multiple
                            options={tags}
                        />
                    </Grid>
                    <Box width='100%' />
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'description'}
                            parseError={parseError}
                            fullWidth
                            minRows={3}
                            maxRows={4}
                            multiline
                            required
                            label='Opis'
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextFieldElement
                            name={'department'}
                            parseError={parseError}
                            fullWidth
                            required
                            label='Dział'
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextFieldElement
                            name={'localization'}
                            parseError={parseError}
                            fullWidth
                            required
                            label='Lokalizacja'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'purpose'}
                            parseError={parseError}
                            fullWidth
                            multiline
                            minRows={2}
                            maxRows={4}
                            required
                            label='Cel przetwarzania'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'personsCategories'}
                            parseError={parseError}
                            fullWidth
                            minRows={2}
                            maxRows={4}
                            multiline
                            required
                            label='Kategorie osób'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'dataCategories'}
                            parseError={parseError}
                            fullWidth
                            minRows={3}
                            maxRows={4}
                            multiline
                            required
                            label='Kategorie danych'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'plannedDeletionDate'}
                            parseError={parseError}
                            fullWidth
                            minRows={3}
                            maxRows={4}
                            multiline
                            required
                            label='Okres przetwarzania danych'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'recipientCategories'}
                            parseError={parseError}
                            fullWidth
                            minRows={3}
                            maxRows={4}
                            multiline
                            required
                            label='Kategorie odbiorców'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'legalBasis'}
                            parseError={parseError}
                            fullWidth
                            minRows={3}
                            maxRows={4}
                            multiline
                            label='Podstawa prawna'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'dataSource'}
                            parseError={parseError}
                            fullWidth
                            minRows={1}
                            maxRows={3}
                            multiline
                            required
                            label='Sposób pozyskania danych'
                        />
                    </Grid>

                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'coAdmin'}
                            parseError={parseError}
                            fullWidth
                            multiline
                            label='Współadministrator'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'processor'}
                            parseError={parseError}
                            fullWidth
                            multiline
                            required
                            label='Podmiot przetwarzający'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <AutocompleteElement
                            label={'Aplikacje'}
                            fullWidth
                            multiple
                            name={'apps'}
                            options={apps}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'securityMeasuresDescription'}
                            parseError={parseError}
                            fullWidth
                            multiline
                            required
                            label='Środki bezpieczeństwa'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'dpia'}
                            parseError={parseError}
                            fullWidth
                            multiline
                            label='DPIA'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'transferToThirdCountry'}
                            parseError={parseError}
                            fullWidth
                            multiline
                            label='Transfer do kraju trzeciego lub org. międzynarodowej'
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextFieldElement
                            name={'comments'}
                            parseError={parseError}
                            fullWidth
                            multiline
                            label='Uwagi'
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                    }}
                >
                    <Button
                        sx={{ mr: 1 }}
                        variant='outlined'
                        onClick={() => close(false)}
                    >
                        Anuluj
                    </Button>
                    <LoadingButton
                        loading={loading}
                        type={'submit'}
                        variant='contained'
                    >
                        Dodaj
                    </LoadingButton>
                </Box>
            </FormContainer>
        </Box>
    )
})

export default AddRegisterModal
