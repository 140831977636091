import { forwardRef, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import SelectedAuth from './SelectedAuth/SelectedAuth'
import moment from 'moment'
import {
    CheckboxButtonGroup,
    DatePickerElement,
    FormContainer,
} from 'react-hook-form-mui'
import { parseError } from '../../../Helpers/formValidation'
import CustomAutocomplete from '../../CustomMuiComponents/CustomAutocomplete'

//mui
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid/Grid'
import { FormControlLabel, InputLabel, Switch } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'
import Divider from '@mui/material/Divider/Divider'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import IconButton from '@mui/material/IconButton/IconButton'
import AddIcon from '@mui/icons-material/Add'
import { GET, POST } from '../../../Helpers/fetch'
import TextField from '@mui/material/TextField/TextField'
import Autocomplete from '@mui/material/Autocomplete/Autocomplete'
import Tooltip from '@mui/material/Tooltip/Tooltip'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const EditAuthorizationsModal = forwardRef(
    ({ close, setSnackbarInfo, refreshData, data }, ref) => {
        const selectedAuthRef = useRef()
        const formContext = useForm({ defaultValues: data })

        //UFN - Until further notice - "do odwołania"
        const [ufn, setUfn] = useState(data.untilFurtherNotice)

        const [selectedAuth, setSelectedAuth] = useState(null)
        const [selectedTag, setSelectedTag] = useState(null)

        const [tags, setTags] = useState([])
        const [appsList, setAppsList] = useState(
            data.apps.map(el => ({ id: el, label: el }))
        )
        const [processes, setProcesses] = useState([])

        const [loading, setLoading] = useState(false)
        const [loadingProcesses, setLoadingProcesses] = useState(false)
        const [loadingTags, setLoadingTags] = useState(false)
        const [zones, setZones] = useState([])
        const [refreshZones, setRefreshZones] = useState(false)
        const [departments, setDepartments] = useState([])
        const [refreshDepartments, setRefreshDepartments] = useState(false)

        const addZonesHandler = data => {
            const addApp = async () => {
                await POST('employeeAuthorizations/addZones', { name: data })
                setRefreshZones(!refreshZones)
            }
            addApp().catch(err => console.log(err))
        }

        const addDepartmentsHandler = data => {
            const addDepartments = async () => {
                await POST('employee/addDepartments', { name: data })
            }
            addDepartments().catch(err => console.log(err))
            setRefreshDepartments(!refreshDepartments)
        }

        const editAuthorizationHandler = data => {
            setLoading(true)
            //data.adoResources = data.adoResources.map(el => el.id ?? el)
            data.zones = data.zones.map(el => el.id ?? el)
            data.departmens = data.departmens.map(el => el.id ?? el)
            const editAuthorization = async () => {
                await POST('employeeAuthorizations/edit', {
                    ...data,
                    untilFurtherNotice: ufn,
                })
            }
            editAuthorization()
                .then(() => {
                    setLoading(false)
                    refreshData()
                    close()
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }

        const getProcesses = async () => {
            setLoadingProcesses(true)
            setProcesses([])
            const { data: Items } = await GET(`register/get?tag=${selectedTag}`)
            const processesList = Items.data.map(el => ({
                apps: el.apps,
                label: el.name,
                id: el._id,
            }))
            setProcesses([...processesList])
            setLoadingProcesses(false)
        }

        const getTags = async () => {
            setLoadingTags(true)
            const { data: Items } = await GET('register/getTags')
            const tagsList = Items.data.map(el => ({
                label: el.name,
                id: el.name,
            }))
            setTags(tagsList)
            setLoadingTags(false)
        }

        const changeUfnHandler = () => {
            setUfn(!ufn)
            formContext.resetField('expiryDate')
        }

        const addAuthToList = () => {
            if (selectedAuth) {
                selectedAuthRef.current.appendHandler(selectedAuth.label)
                const apps = selectedAuth.apps.map(el => ({
                    id: el,
                    label: `${el}`,
                }))
                let list = appsList
                list = [...list, ...apps]
                const uniq = Array.from(new Set(list.map(el => el.id))).map(
                    id => {
                        return list.find(el => el.id === id)
                    }
                )
                setAppsList(uniq)
                setSelectedAuth(null)
            }
        }

        useEffect(() => {
            getProcesses().catch(err => console.error(err))
            getTags().catch(err => console.error(err))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            getProcesses().catch(err => console.error(err))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedTag])

        useEffect(() => {
            const getZones = async () => {
                const { data: Items } = await GET(
                    'employeeAuthorizations/getZones'
                )
                if (Items) {
                    const zones = Items.data.map(el => ({
                        id: el.name,
                        title: el.name,
                    }))
                    setZones([...zones])
                }
            }
            const getDepartments = async () => {
                const { data: Items } = await GET('employee/getDepartments')
                if (Items) {
                    const departments = Items.data.map(el => ({
                        id: el.name,
                        title: el.name,
                    }))
                    setDepartments([...departments])
                }
            }
            getDepartments().catch(console.error)
            getZones().catch(console.error)
            return () => {
                setZones([])
                setDepartments([])
            }
        }, [refreshZones])

        return (
            <Box ref={ref} sx={style}>
                <Typography gutterBottom variant='h6'>
                    Edytowanie upoważnień pracownika
                </Typography>
                <Divider />
                <FormContainer
                    formContext={formContext}
                    onSuccess={editAuthorizationHandler}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                {data.employeeName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                disablePortal
                                options={tags}
                                loading={loadingTags}
                                value={selectedTag}
                                onChange={(event, newValue) => {
                                    setSelectedTag(newValue?.id ?? '')
                                    setSelectedAuth('')
                                }}
                                fullwidth
                                renderInput={params => (
                                    <TextField {...params} label='Grupa' />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                disablePortal
                                loading={loadingProcesses}
                                options={processes}
                                value={selectedAuth}
                                inputValue={selectedAuth?.label ?? ''}
                                onChange={(event, newValue) => {
                                    setSelectedAuth(newValue)
                                }}
                                fullwidth
                                renderInput={params => (
                                    <TextField {...params} label='Proces' />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip title={'Dodaj do listy'}>
                                <IconButton onClick={addAuthToList}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={7}>
                            <CustomAutocomplete
                                name={'departmens'}
                                addToList={addDepartmentsHandler}
                                dialogTitle='Dodaj nowy dział'
                                label='Dział'
                                multiple
                                options={departments}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>Upoważnienia</InputLabel>
                            <Divider />
                        </Grid>

                        <SelectedAuth ref={selectedAuthRef} />

                        <Grid item xs={12}>
                            <InputLabel>Data upoważnień</InputLabel>
                            <Divider />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePickerElement
                                required
                                parseError={err => {
                                    if (err.type === 'validate') {
                                        return 'Data upoważnienia nie może być starsza niż maj 2018'
                                    } else {
                                        return parseError(err)
                                    }
                                }}
                                label='Data nadania'
                                {...formContext.register('addDate', {
                                    validate: value => {
                                        return moment(value).isAfter(
                                            '2018-04-30',
                                            'day'
                                        )
                                    },
                                })}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePickerElement
                                required={!ufn}
                                disabled={ufn}
                                parseError={err => {
                                    if (err.type === 'validate') {
                                        return 'Data wygaśnięcia nie może być mniejsza niż data upoważnienia'
                                    } else {
                                        return parseError(err)
                                    }
                                }}
                                validation={{
                                    disabled: ufn,
                                }}
                                label='Data wygaśnięcia'
                                {...formContext.register('expiryDate', {
                                    validate: value => {
                                        const { addDate } =
                                            formContext.getValues()
                                        const addDateD = moment(addDate)
                                        const expiryDateD = moment(value)
                                        return addDateD.isSameOrBefore(
                                            expiryDateD,
                                            'day'
                                        )
                                    },
                                })}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={ufn}
                                        onChange={changeUfnHandler}
                                    />
                                }
                                label='Do odwołania'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Aplikacje</InputLabel>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxButtonGroup
                                options={appsList}
                                name={'apps'}
                                row
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Dostęp do lokalizacji</InputLabel>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomAutocomplete
                                name={'zones'}
                                multiple
                                addToList={addZonesHandler}
                                dialogTitle='Dodaj nową lokalizację'
                                label='Lokalizacje'
                                options={zones}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={close}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Dodaj
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default EditAuthorizationsModal
