import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import { useState, useContext } from 'react'
import Snackbar from '../../Snackbar/Snackbar'
import { TableCell, TableRow } from '@mui/material'
import TokenContext from '../../../store/token-context'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'additionDate', direction: 'asc' },
}

const EmployeeAuthorizationsTable = () => {
    const [data, setData] = useState([])
    const [count, setCount] = useState(1)
    const [loading, setLoading] = useState(true)
    const [snackbarInfo, setSnackbarInfo] = useState({})
    const [actionAnchor, setActionAnchor] = useState(null)

    const tokenData = useContext(TokenContext)

    const actionsCloseHandler = () => {
        setActionAnchor(null)
    }

    const expandableRow = (rowData, rowMeta, data, columns) => {
        const colSpan = rowData.length + 1
        const currentRowData = data[rowMeta.dataIndex]
        return columns
            .filter(col => col?.options?.display === false)
            .map((el, ix) => (
                <TableRow key={el.label + rowMeta.dataIndex + ix}>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell align='right' colSpan={1}>
                        {' '}
                        {el.label}
                    </TableCell>
                    <TableCell colSpan={colSpan}>
                        {' '}
                        {el?.options?.customBodyRender
                            ? el.options.customBodyRender(
                                  currentRowData[el.name]
                              )
                            : currentRowData[el.name]}
                    </TableCell>
                </TableRow>
            ))
    }

    const importedColumns = columns({
        actionsCloseHandler,
        actionAnchor,
        role: tokenData.data.role,
    })

    return (
        <>
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            <DataTable
                title={'Moje upoważnienia'}
                url={'employeeAuthorizations/getForEmployee'}
                data={data}
                setData={setData}
                expandableRows
                renderExpandableRow={expandableRow}
                count={count}
                setCount={setCount}
                filter={false}
                initalOptionsState={optionsState}
                columns={importedColumns}
                firstLoading={loading}
                setFirstLoading={setLoading}
            />
        </>
    )
}

export default EmployeeAuthorizationsTable
