import {
    parseError,
    phoneValidation,
    secondPhoneValidation,
} from '../../../Helpers/formValidation'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import { InputLabel } from '@mui/material'
import Divider from '@mui/material/Divider/Divider'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { forwardRef, useEffect, useState } from 'react'
import CustomAutocomplete from '../../CustomMuiComponents/CustomAutocomplete'
import {
    AutocompleteElement,
    DatePickerElement,
    FormContainer,
    TextFieldElement,
} from 'react-hook-form-mui'
import { POST, GET } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const EditEmployeeModal = forwardRef(
    ({ close, setSnackbarInfo, data, refreshData }, ref) => {
        const [buttonLoading, setButtonLoading] = useState(false)
        const [, setOrganizationUnitsLoading] = useState(false)
        const [organizationUnits, setOrganizationUnits] = useState([])
        const [refreshOrganizationUnits, setRefreshOrganizationUnits] =
            useState(false)
        const [, setDepartmentsLoading] = useState(false)
        const [departments, setDepartments] = useState([])
        const [refreshDepartments, setRefreshDepartments] = useState(false)

        const addOrganizationUnitsHandler = data => {
            const addOrganizationUnits = async () => {
                await POST('employee/addOrganizationUnits', { name: data })
            }
            addOrganizationUnits().catch(err => console.log(err))
            setRefreshOrganizationUnits(!refreshOrganizationUnits)
        }

        const addDepartmentsHandler = data => {
            const addDepartments = async () => {
                await POST('employee/addDepartments', { name: data })
            }
            addDepartments().catch(err => console.log(err))
            setRefreshDepartments(!refreshDepartments)
        }

        useEffect(() => {
            setOrganizationUnitsLoading(true)
            setDepartments(true)
            const getOrganizationUnits = async () => {
                const { data: Items } = await GET(
                    'employee/getOrganizationUnits'
                )
                if (Items) {
                    const organizationUnits = Items.data.map(el => ({
                        id: el.name,
                        title: el.name,
                    }))
                    setOrganizationUnits([...organizationUnits])
                    setOrganizationUnitsLoading(false)
                }
            }
            const getDepartments = async () => {
                const { data: Items } = await GET('employee/getDepartments')
                if (Items) {
                    const departments = Items.data.map(el => ({
                        id: el.name,
                        title: el.name,
                    }))
                    setDepartments([...departments])
                    setDepartmentsLoading(false)
                }
            }

            getOrganizationUnits().catch(console.error)
            getDepartments().catch(console.error)
            return () => {
                setOrganizationUnits([])
                setDepartments([])
            }
        }, [refreshOrganizationUnits, refreshDepartments])

        const editEmployeeHandler = data => {
            setButtonLoading(true)
            data.organizationUnits = data.organizationUnits.map(
                el => el.id ?? el
            )
            data.departmens = data.departmens.map(el => el.id ?? el)
            const editEmployee = async () => {
                await POST('employee/edit', data)
            }
            editEmployee()
                .then(() => {
                    setButtonLoading(false)
                    refreshData()
                    close()
                })
                .catch(err => {
                    console.error(err)
                    setButtonLoading(false)
                })
        }

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant={'h5'}>
                    Wypełnij dane nowego pracownika
                </Typography>
                <Divider />
                <FormContainer
                    onSuccess={editEmployeeHandler}
                    defaultValues={data}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'firstName'}
                                autoFocus
                                required
                                fullWidth
                                label={'Imię'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'lastName'}
                                required
                                fullWidth
                                label={'Nazwisko'}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'position'}
                                required
                                fullWidth
                                label={'Stanowisko'}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <CustomAutocomplete
                                name={'organizationUnits'}
                                addToList={addOrganizationUnitsHandler}
                                dialogTitle='Dodaj nową jednostkę organizacyjną'
                                label='Jednostka organizacyjna'
                                multiple
                                options={organizationUnits}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <CustomAutocomplete
                                name={'departmens'}
                                addToList={addDepartmentsHandler}
                                dialogTitle='Dodaj nowy dział'
                                label='Dział'
                                multiple
                                options={departments}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePickerElement
                                parseError={parseError}
                                name={`hireDate`}
                                required
                                label={'Data Zatrudnienia'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutocompleteElement
                                parseError={parseError}
                                name={'contractType'}
                                fullWidth
                                label={'Rodzaj umowy'}
                                options={[
                                    {
                                        id: 'Umowa o pracę',
                                        label: 'Umowa o pracę',
                                    },
                                    {
                                        id: 'Umowa zlecenie',
                                        label: 'Umowa zlecenie',
                                    },
                                    {
                                        id: 'Umowa o dzieło',
                                        label: 'Umowa o dzieło',
                                    },
                                    { id: 'B2B', label: 'B2B' },
                                    { id: 'Staż', label: 'Staż' },
                                    { id: 'Praktyka', label: 'Praktyka ' },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Dane kontaktowe</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'email'}
                                required
                                fullWidth
                                label={'Email'}
                                type={'email'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                validation={phoneValidation}
                                name={'phoneNumber'}
                                fullWidth
                                label={'Numer telefonu'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                validation={secondPhoneValidation}
                                name={'secondPhoneNumber'}
                                fullWidth
                                label={'Drugi numer telefonu'}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <InputLabel>Adres</InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'address.city'}
                                fullWidth
                                label={'Miasto'}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldElement
                                parseError={parseError}
                                validation={postalCodeValidation}
                                name={'address.postalCode'}
                                fullWidth
                                label={'Kod Pocztowy'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'address.street'}
                                fullWidth
                                label={'Ulica'}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'address.buildingNumber'}
                                fullWidth
                                label={'Nr budynku'}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'address.apartmentNumber'}
                                fullWidth
                                label={'Nr lokalu'}
                            />
                        </Grid> */}
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={close}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={buttonLoading}
                            type={'submit'}
                            variant='contained'
                        >
                            Zapisz
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default EditEmployeeModal
