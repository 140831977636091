import { useState, useContext, useEffect } from 'react'
import TokenContext from '../../store/token-context'
import { checkEdit } from '../../Helpers/authCheck'
import { GET, POST } from '../../Helpers/fetch'

import Grid from '@mui/material/Grid/Grid'

import AddEmployeeAuthorizationsModal from '../../components/Modals/AddEmployeeAuthorizationsModal/AddEmployeeAuthorizationsModal'
import AddEmployeeModal from '../../components/Modals/AddEmployeeModal/AddEmployeeModal'
import AddRegisterModal from '../../components/Modals/AddRegisterModal/AddRegisterModal'
import AddCategoryRegisterModal from '../../components/Modals/AddCategoryRegisterModal/AddCategoryRegisterModal'
import AddUserModal from '../../components/Modals/AddUserModal/AddUserModal'
import Snackbar from '../../components/Snackbar/Snackbar'
import Modal from '@mui/material/Modal/Modal'
import CustomCard from '../../components/CustomCard/CustomCard'
import AddTraining from '../../components/Modals/TrainingsModal/AddTraining/AddTraining'
import AddEmployeeTraining from '../../components/Modals/TrainingsModal/AddEmployeeTraining/AddEmployeeTraining'
import AddResourceRegisterModal from '../../components/Modals/AddResourceRegisterModal/AddResourceRegister'
import {
    Paper,
    Typography,
    List,
    Box,
    ListItem,
    ListItemText,
    IconButton,
    Tooltip,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ReadMoreIcon from '@mui/icons-material/ReadMore'

const Panel = () => {
    const [employeeOpen, setEmployeeOpen] = useState(false)
    const [employeeAuthorizationsOpen, setEmployeeAuthorizationsOpen] =
        useState(false)
    const [registerOpen, setRegisterOpen] = useState(false)
    const [categoryRegisterOpen, setCategoryRegisterOpen] = useState(false)
    const [trainingsOpen, setTrainingsOpen] = useState(false)
    const [userOpen, setUserOpen] = useState(false)
    const [resourceOpen, setResourceOpen] = useState(false)
    const [employeeData, setEmployeeData] = useState({})
    const [employeeTrainingOpen, setEmployeeTrainingOpen] = useState(false)
    const [snackbarInfo, setSnackbarInfo] = useState({})
    const [tasks, setTasks] = useState([])

    const { data: tokenData } = useContext(TokenContext)

    const closeEmployee = (isCreateAccount, employeeData) => {
        setEmployeeOpen(false)
        if (isCreateAccount) {
            setEmployeeData(employeeData)
            setUserOpen(true)
        }
    }

    const taskDone = async id => {
        const result = await POST('todoTasks/doneTask', { documentId: id })
        console.log(result)
        if (result.status === 200) {
            const getTasks = async () => {
                const result = await GET('todoTasks/get')
                const { data } = result
                if (data) {
                    setTasks(data)
                }
            }

            getTasks().catch(console.error)
        }
    }

    useEffect(() => {
        const getTasks = async () => {
            const result = await GET('todoTasks/get')
            const { data } = result
            if (data) {
                setTasks(data)
            }
        }

        getTasks().catch(console.error)

        return () => {
            setTasks([])
        }
    }, [])

    return (
        <Grid sx={{ mt: 1 }} container spacing={1}>
            {checkEdit(tokenData.role) && (
                <>
                    {snackbarInfo.open && (
                        <Snackbar
                            setSnackbarInfo={setSnackbarInfo}
                            isOpen={snackbarInfo.open}
                            type={snackbarInfo.type}
                        >
                            {snackbarInfo.message}
                        </Snackbar>
                    )}
                    <Modal open={userOpen} onClose={() => setUserOpen(false)}>
                        <AddUserModal
                            closeAndRefresh={() => setUserOpen(false)}
                            employeeData={employeeData}
                        />
                    </Modal>
                    <Grid item xs={12} sm={6} md={4}>
                        <CustomCard
                            title={'Nowy pracownik'}
                            text={'Dodawanie pracownika'}
                            open={employeeOpen}
                            onClose={() => setEmployeeOpen(false)}
                            onClick={() => setEmployeeOpen(true)}
                        >
                            <AddEmployeeModal
                                setSnackbarInfo={setSnackbarInfo}
                                close={closeEmployee}
                            />
                        </CustomCard>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <CustomCard
                            title={'Dodaj upoważnienie'}
                            text={
                                'Dodaj dane dotyczące upoważnienia pracownika'
                            }
                            open={employeeAuthorizationsOpen}
                            onClose={() => setEmployeeAuthorizationsOpen(false)}
                            onClick={() => setEmployeeAuthorizationsOpen(true)}
                        >
                            <AddEmployeeAuthorizationsModal
                                setSnackbarInfo={setSnackbarInfo}
                                close={() =>
                                    setEmployeeAuthorizationsOpen(false)
                                }
                            />
                        </CustomCard>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <CustomCard
                            title={'Czynność przetwarzania'}
                            text={'Dodawanie nowej czynności przetwarzania'}
                            open={registerOpen}
                            onClose={() => setRegisterOpen(false)}
                            onClick={() => setRegisterOpen(true)}
                        >
                            <AddRegisterModal
                                setSnackbarInfo={setSnackbarInfo}
                                close={() => setRegisterOpen(false)}
                            />
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CustomCard
                            title={'Kategoria przetwarzania'}
                            text={'Dodawanie nowej kategorii przetwarzania'}
                            open={categoryRegisterOpen}
                            onClose={() => setCategoryRegisterOpen(false)}
                            onClick={() => setCategoryRegisterOpen(true)}
                        >
                            <AddCategoryRegisterModal
                                setSnackbarInfo={setSnackbarInfo}
                                close={() => setCategoryRegisterOpen(false)}
                            />
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CustomCard
                            title={'Szkolenie'}
                            text={'Dodawanie nowego szkolenia'}
                            open={trainingsOpen}
                            onClose={() => setTrainingsOpen(false)}
                            onClick={() => setTrainingsOpen(true)}
                        >
                            <AddTraining
                                setSnackbarInfo={setSnackbarInfo}
                                close={() => setTrainingsOpen(false)}
                            />
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CustomCard
                            title={'Wyślij na szkolenie'}
                            text={'Wyślij pracownika na szkolenie'}
                            open={employeeTrainingOpen}
                            onClose={() => setEmployeeTrainingOpen(false)}
                            onClick={() => setEmployeeTrainingOpen(true)}
                        >
                            <AddEmployeeTraining
                                setSnackbarInfo={setSnackbarInfo}
                                close={() => setEmployeeTrainingOpen(false)}
                            />
                        </CustomCard>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <CustomCard
                            title={'Dodaj zasób'}
                            text={'Dodaj wpis do rejestru zasobów'}
                            open={resourceOpen}
                            onClose={() => setResourceOpen(false)}
                            onClick={() => setResourceOpen(true)}
                        >
                            <AddResourceRegisterModal
                                setSnackbarInfo={setSnackbarInfo}
                                close={() => setResourceOpen(false)}
                            />
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Paper
                            sx={{
                                height: '220px',
                                boxSizing: 'border-box',
                                overflow: 'hidden',
                                p: 2,
                            }}
                        >
                            <Typography
                                textAlign={'left'}
                                color='primary'
                                variant='h5'
                                fontWeight={'bold'}
                            >
                                Zadania do wykonania
                            </Typography>
                            <Box
                                sx={{
                                    overflow: 'auto',
                                    height: 'calc(100% - 67px)',
                                }}
                            >
                                {tasks.length > 0 ? (
                                    <List>
                                        {tasks.map(el => (
                                            <ListItem>
                                                <ListItemText
                                                    primary={el.name}
                                                />
                                                {/* <Tooltip title='Szczegóły'>
                                                    <IconButton>
                                                        <ReadMoreIcon />
                                                    </IconButton>
                                                </Tooltip> */}
                                                <Tooltip title='Potwierdź'>
                                                    <IconButton
                                                        onClick={() =>
                                                            taskDone(el._id)
                                                        }
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : (
                                    <Typography p={1} textAlign={'left'}>
                                        Brak zadań do wykonania
                                    </Typography>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default Panel
