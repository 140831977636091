import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { withStyles } from 'tss-react/mui'
const defaultToolbarStyles = {
    iconButton: {},
}

class LegendButton extends React.Component {
    render() {
        const { classes, legend } = this.props

        return (
            <>
                <Tooltip title={legend}>
                    <IconButton className={classes.iconButton}>
                        <InfoOutlinedIcon className={classes.deleteIcon} />
                    </IconButton>
                </Tooltip>
            </>
        )
    }
}

export default withStyles(LegendButton, defaultToolbarStyles, {
    name: 'LegendButton',
})
