import { useState } from 'react'
import {
    FormContainer,
    SelectElement,
    TextFieldElement,
} from 'react-hook-form-mui'

import { parseError } from '../../../Helpers/formValidation'
import { POST } from '../../../Helpers/fetch'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider/Divider'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { forwardRef } from 'react'
import CustomTransferList from '../../CustomMuiComponents/CustomTransferList'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    maxHeight: '90%',
    overflow: 'auto',
    boxShadow: 24,
    p: 4,
}

const EditUserModal = forwardRef(
    ({ setSnackbarInfo, data, closeAndRefresh }, ref) => {
        const [loading, setLoading] = useState(false)

        const modulesList = [
            'Szkolenia pracowników',
            'Lista szkoleń',
            // 'Kandydaci',
            'Pracownicy',
            'Polityka bezpieczenstwa',
            'Polityka informacyjna',
            'Rejestr czynności przetwarzania',
            'Rejestr incydentów',
            'Rejestr kategorii przetwarzania',
            'Rejestr umów powierzenia',
            'Rejestr żądań',
            'Rejestr zasobów',
            'Sprawdzenia planowe',
            'Sprawozdania z audytu',
            'Uchwały i zarządzenia',
            'Upoważnienia',
            'Zgody',
        ]
        const rolesList = [
            'Administrator',
            'Moderator',
            'Pracownik',
            'Tylko odczyt',
        ]

        const editUserHandler = data => {
            setLoading(true)
            const addUser = async () => {
                await POST('users/edit', data)
            }
            addUser()
                .then(res => {
                    setLoading(false)
                    closeAndRefresh()
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant={'h5'}>
                    Edytuj dane użytkownika
                </Typography>
                <Divider />
                <FormContainer defaultValues={data} onSuccess={editUserHandler}>
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'email'}
                                autoFocus
                                required
                                fullWidth
                                label={'Email'}
                                type={'email'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'company'}
                                required
                                fullWidth
                                label={'Firma'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'firstName'}
                                required
                                fullWidth
                                label={'Imię'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'lastName'}
                                required
                                fullWidth
                                label={'Nazwisko'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectElement
                                parseError={parseError}
                                name={'role'}
                                required
                                fullWidth
                                label={'Rola'}
                                options={rolesList.map(el => ({
                                    id: el,
                                    label: el,
                                }))}
                            />
                        </Grid>
                        <CustomTransferList
                            name='moduleAccess'
                            list={modulesList}
                        />
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{ mr: 1 }}
                            variant='outlined'
                            onClick={closeAndRefresh}
                        >
                            Anuluj
                        </Button>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Zapisz
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default EditUserModal
