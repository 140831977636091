import { useEffect, useState } from 'react'

import Box from '@mui/material/Box/Box'
import Paper from '@mui/material/Paper/Paper'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import Chip from '@mui/material/Chip/Chip'
import IconButton from '@mui/material/IconButton/IconButton'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { Collapse, Divider, ListItem } from '@mui/material'
import { GET, PUT, PATCH, POST, POST_WITH_FILE } from '../../Helpers/fetch'
import { Button } from '@mui/material'
import FormControl from '@mui/material/FormControl/FormControl'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import Select from '@mui/material/Select/Select'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import DraftEditor from '../DraftEditor/DraftEditor'
import Skeleton from '@mui/material/Skeleton/Skeleton'
import InputFileUpload from '../InputFileUpload/InputFileUplaod'
import { ChromePicker } from 'react-color'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'

const Settings = () => {
    const [email, setEmail] = useState('')
    const [emailList, setEmailList] = useState([])
    const [emailError, setEmailError] = useState(false)
    const [nameIOD, setNameIOD] = useState('')
    const [lastNameIOD, setLastNameIOD] = useState('')
    const [emailIOD, setEmailIOD] = useState('')
    const [emailErrorIOD, setEmailErrorIOD] = useState(false)
    const [mainColor, setMainColor] = useState('#10403B')
    const [secondaryColor, setSecondaryColor] = useState('#ff914d')
    const [colorChangeOpen, setColorChangeOpen] = useState(false)
    const [secondaryColorChangeOpen, setSecondaryColorChangeOpen] =
        useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingMessage, setLoadingMessage] = useState(true)
    const [loadingColors, setLoadingColors] = useState(true)
    const [message, setMessage] = useState('')
    const [messageType, setMessageType] = useState('signUp')
    const [refreshData, setRefreshData] = useState(true)
    const [file, setFile] = useState()
    const [selectedFile, setSelectedFile] = useState(null)

    const validateEmail = email => {
        return email.match(
            //eslint-disable-next-line
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    const changeEmail = event => {
        setEmail(event.target.value)
    }

    const addNotificationEmail = async () => {
        if (validateEmail(email)) {
            setLoading(true)
            await PUT('companySettings/addEmail', { email })
                .then(() => {
                    setRefreshData(!refreshData)
                    setEmail('')
                    setLoading(false)
                    setEmailError(false)
                })
                .catch(console.error)
        } else {
            setEmailError(true)
        }
    }

    const updateIOD = async () => {
        if (emailIOD === '' || validateEmail(emailIOD)) {
            setLoading(true)
            await PUT('companySettings/changeIOD', {
                emailIOD,
                nameIOD,
                lastNameIOD,
            })
                .then(() => {
                    setRefreshData(!refreshData)
                    setEmailIOD('')
                    setLoading(false)
                    setEmailErrorIOD(false)
                })
                .catch(console.error)
        } else {
            setEmailErrorIOD(true)
        }
    }

    const updateMessage = async () => {
        setLoadingMessage(true)

        await POST('companySettings/updateMessage', {
            message: message,
            type: messageType,
        })
            .then(() => {
                setRefreshData(!refreshData)
                setLoadingMessage(false)
            })
            .catch(console.error)
    }

    const updateStyles = async () => {
        setLoadingColors(true)

        const formData = new FormData()
        formData.append(
            'data',
            JSON.stringify({
                mainColor: mainColor.hex,
                secondaryColor: secondaryColor.hex,
            })
        )
        formData.append('fileType', 'logo')
        formData.append('file', file)

        await POST_WITH_FILE('companySettings/updateStyles', formData)
            .then(() => {
                setRefreshData(!refreshData)
                setLoadingColors(false)
                window.location.reload()
            })
            .catch(console.error)
    }

    const deleteNotificationEmail = async mail => {
        setLoading(true)
        await PATCH('companySettings/deleteNotificationEmail', { email: mail })
            .then(() => {
                setRefreshData(!refreshData)
                setLoading(false)
            })
            .catch(console.error)
    }

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            const { data: result } = await GET('companySettings/getEmails')
            if (result) {
                const emails = [...result.data[0].notificationEmails]
                setEmailList(emails)
            }

            const { data: iodResult } = await GET('companySettings/getIOD')
            if (iodResult.data[0].iod) {
                const iod = { ...iodResult.data[0].iod }
                setNameIOD(iod.name)
                setLastNameIOD(iod.lastName)
                setEmailIOD(iod.email)
            }
            setLoading(false)
        }

        getData()

        return () => {
            setEmailList([])
            setNameIOD('')
            setLastNameIOD('')
            setEmailIOD('')
        }
    }, [refreshData])

    useEffect(() => {
        setLoadingMessage(true)
        const getEmailMessage = async () => {
            const { data: result } = await GET(
                'companySettings/getEmailMessage',
                {
                    type: messageType,
                }
            )
            if (result.data) {
                setMessage(result.data)
            } else {
                setMessage('')
            }
            setLoadingMessage(false)
        }

        getEmailMessage()
    }, [messageType])

    useEffect(() => {
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                setSelectedFile(reader.result)
            }

            reader.readAsDataURL(file)
        }
    }, [file])

    useEffect(() => {
        const getColor = async () => {
            const { data: result } = await GET('companySettings/getColors')
            const color = result.data[0]
            setMainColor({ hex: color.mainColor })
            setSecondaryColor({ hex: color.secondaryColor })
            setLoadingColors(false)
        }

        getColor()
    }, [])

    const legend = (
        <Box sx={{ padding: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography>
                    {'{pracownik} - imię i nazwisko pracownika'}
                </Typography>
                <Typography>
                    {'{upowaznienia} - lista upoważnień pracownika'}
                </Typography>
            </Box>
        </Box>
    )

    return (
        <Box
            sx={{
                height: '80vh',
                textAlign: 'left',
                padding: 2,
            }}
        >
            <Paper elevation={3} sx={{ p: 2 }}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Typography variant='h5'>
                            Ustawienia aplikacji
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                label='Adres e-mail'
                                type={'email'}
                                value={email}
                                error={emailError}
                                helperText={
                                    emailError
                                        ? 'Podaj poprawny adres email'
                                        : null
                                }
                                onChange={changeEmail}
                                sx={{ maxWidth: '400px', width: '100%' }}
                            />

                            <IconButton onClick={addNotificationEmail}>
                                <AddIcon />
                            </IconButton>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Dodaj adres email do powiadomień
                                </Typography>
                            </ListItem>
                        </Box>
                        <Box sx={{ mt: 1, p: 2 }}>
                            <Typography variant={'h6'}>Adresy:</Typography>
                            {emailList.length > 0 ? (
                                emailList.map(el => (
                                    <Chip
                                        label={el}
                                        onDelete={() =>
                                            deleteNotificationEmail(el)
                                        }
                                    />
                                ))
                            ) : (
                                <Typography>
                                    Brak dodanych adresów do powiadomień
                                </Typography>
                            )}
                        </Box>
                    </>
                )}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Typography variant='h6'>Twój IOD</Typography>
                        <Divider sx={{ mb: 2 }} />
                        <TextField
                            label='Adres e-mail IOD'
                            type={'email'}
                            value={emailIOD}
                            error={emailErrorIOD}
                            helperText={
                                emailErrorIOD
                                    ? 'Podaj poprawny adres email'
                                    : null
                            }
                            onChange={e => setEmailIOD(e.target.value)}
                            sx={{ maxWidth: '500px', width: '100%' }}
                        />
                        <Box
                            pt={1}
                            maxWidth={'500px'}
                            display={'flex'}
                            flexDirection={'row'}
                        >
                            <TextField
                                label='Imię'
                                value={nameIOD}
                                onChange={e => setNameIOD(e.target.value)}
                                sx={{ width: '100%', mr: '5px' }}
                            />
                            <TextField
                                label='Nazwisko'
                                value={lastNameIOD}
                                onChange={e => setLastNameIOD(e.target.value)}
                                sx={{ width: '100%', ml: '5px' }}
                            />
                        </Box>
                        <Button
                            sx={{ mt: 1 }}
                            variant='contained'
                            onClick={updateIOD}
                        >
                            Zapisz
                        </Button>
                    </>
                )}
                <Typography variant='h6'>
                    Edycja interfejsu aplikacji
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {loadingColors ? (
                    <Skeleton sx={{ width: '300px' }} />
                ) : (
                    <Box>
                        <Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ position: 'relative' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            width: '200px',
                                            border: '1px solid #e0e0e0',
                                            borderRadius: '5px',
                                            padding: '5px',
                                        }}
                                        onClick={() =>
                                            setColorChangeOpen(!colorChangeOpen)
                                        }
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: mainColor.hex,
                                                height: '25px',
                                                width: '25px',
                                                border: '1px solid black',
                                                borderRadius: '5px',
                                                mr: '5px',
                                            }}
                                        />
                                        <Typography>Kolor główny</Typography>
                                    </Box>
                                    <Collapse
                                        in={colorChangeOpen}
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 2000,
                                        }}
                                    >
                                        <ChromePicker
                                            disableAlpha
                                            color={mainColor}
                                            onChange={setMainColor}
                                        />
                                    </Collapse>
                                </Box>
                                <Box sx={{ position: 'relative', ml: 1 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            width: '200px',
                                            border: '1px solid #e0e0e0',
                                            borderRadius: '5px',
                                            padding: '5px',
                                        }}
                                        onClick={() =>
                                            setSecondaryColorChangeOpen(
                                                !secondaryColorChangeOpen
                                            )
                                        }
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor:
                                                    secondaryColor.hex,
                                                height: '25px',
                                                width: '25px',
                                                border: '1px solid black',
                                                borderRadius: '5px',
                                                mr: '5px',
                                            }}
                                        />
                                        <Typography>Kolor dodatkowy</Typography>
                                    </Box>
                                    <Collapse
                                        in={secondaryColorChangeOpen}
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 2000,
                                        }}
                                    >
                                        <ChromePicker
                                            disableAlpha
                                            color={secondaryColor}
                                            onChange={setSecondaryColor}
                                        />
                                    </Collapse>
                                </Box>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Box
                                    sx={{
                                        backgroundImage: `
                                            linear-gradient(45deg, #ccc 25%, transparent 25%),
                                            linear-gradient(135deg, #ccc 25%, transparent 25%),
                                            linear-gradient(45deg, transparent 75%, #ccc 75%),
                                            linear-gradient(135deg, transparent 75%, #ccc 75%)`,
                                        backgroundSize: '25px 25px',
                                        backgroundPosition:
                                            '0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px',
                                        height: '48px',
                                        width: '200px',
                                        border: '1px solid #e0e0e0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mb: 1,
                                    }}
                                >
                                    {file ? (
                                        <img
                                            src={selectedFile}
                                            alt='Uploaded file'
                                            width={200}
                                            height={64}
                                        />
                                    ) : (
                                        <Typography>Brak pliku</Typography>
                                    )}
                                </Box>
                                <Typography>{file?.name}</Typography>
                                <InputFileUpload setFile={setFile} />
                            </Box>
                        </Box>
                        <Button
                            sx={{ mt: 1 }}
                            variant='contained'
                            onClick={updateStyles}
                        >
                            Zapisz
                        </Button>
                    </Box>
                )}

                <Typography variant='h6'>Edycja wiadomości email</Typography>
                <Divider sx={{ mb: 2 }} />

                <FormControl sx={{ mb: 2 }}>
                    <InputLabel id='email-message-label'>
                        Typ wiadomości
                    </InputLabel>
                    <Select
                        labelId='email-message-label'
                        id='email-message-select'
                        value={messageType}
                        label='Typ wiadomości'
                        onChange={event => setMessageType(event.target.value)}
                    >
                        <MenuItem value={'signUp'}>
                            Utworzenie użytkownika
                        </MenuItem>
                        <MenuItem value={'newAuthorization'}>
                            Dodanie upoważnienia
                        </MenuItem>
                        <MenuItem value={'fire'}>
                            Zwolnienie pracownika
                        </MenuItem>
                        <MenuItem value={'edit'}>
                            Powiadomienie o edycji
                        </MenuItem>
                    </Select>
                </FormControl>

                <Tooltip title={legend}>
                    <IconButton>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>

                {loadingMessage ? (
                    <Skeleton variant='rounded' width={210} height={60} />
                ) : (
                    <DraftEditor data={message} setData={setMessage} />
                )}

                <Button
                    sx={{ mt: 1 }}
                    variant='contained'
                    onClick={updateMessage}
                >
                    Zapisz
                </Button>
            </Paper>
        </Box>
    )
}

export default Settings
