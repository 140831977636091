import IconButton from '@mui/material/IconButton/IconButton'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Box from '@mui/material/Box/Box'
import { GET_WITH_DOWNLOAD_NAMED } from '../../../Helpers/fetch'
import { MenuItem, Tooltip } from '@mui/material'
import Menu from '@mui/material/Menu/Menu'
import Link from '@mui/material/Link/Link'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'
import FormControl from '@mui/material/FormControl/FormControl'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput'
import Select from '@mui/material/Select/Select'
import ListItemText from '@mui/material/ListItemText/ListItemText'
import { Link as RouterLink } from 'react-router-dom'

const downloadFile = async trainingId => {
    await GET_WITH_DOWNLOAD_NAMED(`/training/download/${trainingId}`)
}

const columns = props => [
    { name: 'name', label: 'Nazwa szkolenia' },
    { name: 'description', label: 'Opis' },
    {
        name: 'isOnline',
        label: 'Typ',
        options: {
            filter: true,
            filterType: 'custom',
            sort: false,
            customFilterListOptions: {
                render: v => (v[0] === 'true' ? ['Online'] : ['Stacjonarnie']),
            },
            filterOptions: {
                display: (filterList, onChange, index, column) => {
                    const optionValues = [
                        { label: 'Online', value: 'true' },
                        { label: 'Stacjonarnie', value: 'false' },
                    ]

                    return (
                        <FormControl variant='outlined'>
                            <InputLabel id='filter-label'>Typ</InputLabel>
                            <Select
                                labelId={'filter-label'}
                                input={
                                    <OutlinedInput
                                        label='Typ'
                                        id='filter-label'
                                    />
                                }
                                value={filterList[index] || ''}
                                onChange={event => {
                                    filterList[index] = [event.target.value]
                                    onChange(filterList[index], index, column)
                                }}
                            >
                                {optionValues.map(item => (
                                    <MenuItem
                                        key={item.label}
                                        value={item.value}
                                    >
                                        <Tooltip title={item.label}>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    noWrap: true,
                                                    maxWidth: '300px',
                                                }}
                                                primary={item.label}
                                            />
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                },
            },
            customBodyRender: value => {
                return value ? 'Online' : 'Stacjonarnie'
            },
        },
    },
    {
        name: 'trainer',
        label: 'Prowadzący',
        options: {
            customBodyRender: value => (value ? value : 'Brak'),
        },
    },
    { name: 'validityPeriod.number', label: 'Okres ważności szkolenia' },
    {
        name: 'validityPeriod.unit',
        label: 'Jednostka ważności szkolenia',
        options: {
            customBodyRender: value => {
                switch (value) {
                    case 'year':
                        return 'Rok'
                    case 'month':
                        return 'Miesiąc'
                    case 'day':
                        return 'Dzień'
                    default:
                        return null
                }
            },
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionsAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={props.editHandler}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkEdit(props.role) && (
                                <MenuItem onClick={props.addMaterialHandler}>
                                    Dodaj Materiały
                                </MenuItem>
                            )}
                            {checkEdit(props.role) && (
                                <MenuItem
                                    disabled={!props.isMaterials}
                                    component={RouterLink}
                                    to={`materialy/${props.trainingId}`}
                                >
                                    Materiały
                                </MenuItem>
                            )}
                            {checkEdit(props.role) && (
                                <MenuItem
                                    disabled={props.isTest}
                                    onClick={props.addTestHandler}
                                >
                                    Dodaj Test
                                </MenuItem>
                            )}
                            {checkEdit(props.role) && (
                                <MenuItem
                                    disabled={!props.isTest}
                                    onClick={props.editTestHandler}
                                >
                                    Edytuj Test
                                </MenuItem>
                            )}
                            {checkEdit(props.role) && (
                                <MenuItem
                                    disabled={!props.isTest}
                                    onClick={() =>
                                        props.setOpenDeleteTestDialog(true)
                                    }
                                >
                                    Usuń Test
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
