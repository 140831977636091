import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import { POST } from '../../../Helpers/fetch'
import { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Modal,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button/Button'
import DoingTest from '../../Modals/TrainingsModal/DoingTest/DoingTest'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'name', direction: 'asc' },
}

const EmployeeTrainingsTable = () => {
    const [data, setData] = useState()
    const [count, setCount] = useState(1)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [openTestModal, setOpenTestModal] = useState(false)
    const [test, setTest] = useState([])
    const [confirmRow, setConfirmRow] = useState()
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [confirmLoading, setConfirmLoading] = useState(false)

    console.log(data)

    const refreshHandler = () => {
        setRefreshData(!refreshData)
    }

    const openDialogHandler = trainingId => {
        setOpenConfirmDialog(true)
        setConfirmRow(trainingId)
    }

    const openTestModalHandler = index => {
        const tempTest = data[index].test
        setTest(tempTest)
        setConfirmRow(data[index]._id)
        setOpenTestModal(true)
    }

    const confirmHandler = async () => {
        setConfirmLoading(true)
        await POST('training/complete', {
            trainingId: confirmRow,
        })
            .then(() => {
                setConfirmLoading(false)
                setRefreshData(!refreshData)
                setOpenConfirmDialog(false)
            })
            .catch(err => {
                setConfirmLoading(false)
                console.log(err)
            })
    }

    const importedColumns = columns({
        data: data?.map(el => ({
            trainingId: el._id,
            videoLink: el.videoLink,
            fileName: el.fileName,
            isMaterials: el.materials.length > 0,
            isTest: el.test.length > 0,
        })),
        refreshHandler,
        openDialogHandler,
        openTestModalHandler,
    })

    return (
        <>
            <DataTable
                title={'Moje szkolenia'}
                url={'training/getForEmployee'}
                data={data}
                setData={setData}
                count={count}
                filter={false}
                setCount={setCount}
                initalOptionsState={optionsState}
                columns={importedColumns}
                firstLoading={loading}
                setFirstLoading={setLoading}
                refreshData={refreshData}
            />
            {!loading && (
                <Modal
                    open={openTestModal}
                    onClose={() => setOpenTestModal(false)}
                >
                    <DialogContent>
                        <DoingTest
                            testData={test}
                            trainingId={confirmRow}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenTestModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
            >
                <DialogTitle>
                    Czy rzeczywiście przeszedłeś szkolenie i pobrałeś wszystkie
                    materiały?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie jest równoznaczne z przejściem szkolenia i
                        deklaracją zapoznania się ze wszystkimi materiałami. Czy
                        na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenConfirmDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={confirmLoading}
                        onClick={confirmHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EmployeeTrainingsTable
