import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import { POST } from '../../../Helpers/fetch'
import { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button/Button'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'name', direction: 'asc' },
}

const EmployeeResourcesTable = () => {
    const [data, setData] = useState()
    const [count, setCount] = useState(1)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [confirmRow, setConfirmRow] = useState()
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const refreshHandler = () => {
        setRefreshData(!refreshData)
    }

    const openDialogHandler = resourceId => {
        setOpenConfirmDialog(true)
        setConfirmRow(resourceId)
    }

    const confirmHandler = async () => {
        setConfirmLoading(true)
        await POST('resourceRegister/confirmReceipt', {
            resourceId: confirmRow,
        })
            .then(() => {
                setConfirmLoading(false)
                setRefreshData(!refreshData)
                setOpenConfirmDialog(false)
            })
            .catch(err => {
                setConfirmLoading(false)
            })
    }

    const importedColumns = columns({
        data: data?.map(el => ({
            resourceId: el._id,
        })),
        refreshHandler,
        openDialogHandler,
    })

    return (
        <>
            <DataTable
                title={'Moje zasoby'}
                url={'resourceRegister/getForEmployee'}
                data={data}
                setData={setData}
                count={count}
                filter={false}
                setCount={setCount}
                initalOptionsState={optionsState}
                columns={importedColumns}
                firstLoading={loading}
                setFirstLoading={setLoading}
                refreshData={refreshData}
            />
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
            >
                <DialogTitle>Potwierdź odbiór zasobu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie jest jednoznaczne z odbiorem zasobu. Czy
                        na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenConfirmDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={confirmLoading}
                        onClick={confirmHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EmployeeResourcesTable
