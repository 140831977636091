import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import moment from 'moment/moment'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Box from '@mui/material/Box/Box'
import { Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import { GET_WITH_DOWNLOAD_NAMED } from '../../../Helpers/fetch'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'

const downloadFile = trainingId => {
    GET_WITH_DOWNLOAD_NAMED(`/entrustmentRegister/download/${trainingId}`)
}

const columns = props => [
    {
        name: 'companyName',
        label: 'Nazwa firmy',
    },
    {
        name: 'agreementNumber',
        label: 'Nr umowy',
        options: { display: false },
    },
    {
        name: 'agreementScope',
        label: 'Zakres umowy',
        options: { display: false },
    },
    {
        name: 'agreementDate',
        label: 'Data zawarcia',
        options: {
            customBodyRender: value => (
                <Typography>{moment(value).format('DD.MM.YYYY')}</Typography>
            ),
        },
    },
    {
        name: 'endDate',
        label: 'Data zakończenia',
        options: {
            customBodyRender: value => (
                <Typography>
                    {value
                        ? moment(value).format('DD.MM.YYYY')
                        : 'Do odwołania'}
                </Typography>
            ),
        },
    },
    {
        name: 'noticePeriod',
        label: 'Okres wypowiedzenia',
        options: { display: false },
    },
    {
        name: 'annex',
        label: 'Aneksy',
        options: { display: false },
    },
    {
        name: 'defaultEntrustmentAgreement',
        label: 'Domyślna zgoda na podpowierzenie',
        options: { display: false },
    },
    {
        name: 'contactPerson',
        label: 'Osoba do kontaktu',
    },
    {
        name: 'subjectList',
        label: 'Lista podmiotów przetwarzających',
        options: { display: false },
    },
    {
        name: 'incidentPeriod',
        label: 'Okres zawiadomienia w razie incydentu',
        options: { display: false },
    },

    {
        name: 'confirmationAfterEnd',
        label: 'Potwierdzenia usunięcia danych po zakończeniu',
        options: { display: false },
    },
    {
        name: 'entrustmentFile',
        label: 'Załącznik',
        options: {
            customBodyRender: (value, tableMeta) => {
                // let file = props.data[rowIndex].correspondenceHistoryFile
                return (
                    value && (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Tooltip title='Pobierz załącznik'>
                                <IconButton
                                    onClick={() =>
                                        downloadFile(
                                            props.data[tableMeta.rowIndex]._id
                                        )
                                    }
                                >
                                    <SimCardDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                )
            },
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={() => props.editHandler()}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
