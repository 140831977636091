import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import moment from 'moment/moment'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Box from '@mui/material/Box/Box'
import { Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import { GET_WITH_DOWNLOAD_NAMED } from '../../../Helpers/fetch'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'

const downloadFile = trainingId => {
    GET_WITH_DOWNLOAD_NAMED(`/demandRegister/download/${trainingId}`)
}

const columns = props => [
    {
        name: 'dateReceived',
        label: 'Data wpłynięcia',
        options: {
            customBodyRender: value =>
                value ? (
                    <Typography>
                        {moment(value).format('DD.MM.YYYY')}
                    </Typography>
                ) : null,
        },
    },
    {
        name: 'personMakingRequest',
        label: 'Osoba zgłaszająca zapytanie',
    },
    {
        name: 'isPersonChecked',
        label: 'Czy osoba zgłaszająca przeszła pozytywną weryfikacje danych',
    },
    {
        name: 'requestsFromPerson',
        label: 'Żądania osoby zgłaszającej',
    },
    {
        name: 'isDataProtectionOfficerInformed',
        label: 'Czy został poinformowany Inspektor Ochrony Danych Osobowych ',
    },
    {
        name: 'notificationDate',
        label: 'Data poinformowania Inspektora Ochrony Danych Osobowych ',
        options: {
            customBodyRender: value =>
                value ? (
                    <Typography>
                        {moment(value).format('DD.MM.YYYY')}
                    </Typography>
                ) : null,
        },
    },
    {
        name: 'correspondenceHistoryFile',
        label: 'Historia korespondencji z osobą zgłaszającą',
        options: {
            customBodyRender: (value, tableMeta) => {
                // let file = props.data[rowIndex].correspondenceHistoryFile
                return (
                    value && (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Tooltip title='Pobierz historię korespondencji'>
                                <IconButton
                                    onClick={() =>
                                        downloadFile(
                                            props.data[tableMeta.rowIndex]._id
                                        )
                                    }
                                >
                                    <SimCardDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                )
            },
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={() => props.editHandler()}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
