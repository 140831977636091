import { useState, forwardRef, useEffect } from 'react'

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import PasswordStrengthBar from 'react-password-strength-bar'
import TextField from '@mui/material/TextField'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Divider from '@mui/material/Divider/Divider'

import { POST } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    maxHeight: '90%',
    overflow: 'auto',
    boxShadow: 24,
    p: 4,
}

const ChangePasswordModal = forwardRef(
    ({ close, setSnackbarText, setOpenSnackbar, userData }, ref) => {
        const [oldPassword, setOldPassword] = useState('')
        const [newPassword, setNewPassword] = useState('')
        const [confirmNewPassword, setConfirmNewPassword] = useState('')
        const [passwordScore, setPasswordScore] = useState(1)
        const [errorText, setErrorText] = useState('')
        const [newPasswordError, setNewPasswordError] = useState('')
        const [disabled, setDisabled] = useState(true)
        const [loading, setLoading] = useState(false)

        const changeInputValue = (event, setFunction) => {
            setFunction(event.target.value)
        }

        const validatePassword = () => {
            if (
                newPassword !== confirmNewPassword ||
                passwordScore !== 4 ||
                newPassword === '' ||
                confirmNewPassword === ''
            ) {
                return false
            } else return true
        }

        const changePassword = event => {
            setLoading(true)
            event.preventDefault()
            const isValid = validatePassword()
            if (isValid) {
                POST('auth/changepassword', {
                    newPassword,
                    oldPassword: userData?._id ? null : oldPassword,
                    userDataId: userData?._id ?? null,
                })
                    .then(res => {
                        setSnackbarText('Hasło zmienione')
                        setOpenSnackbar(true)
                        close()
                        setErrorText('')
                        setNewPassword('')
                        setOldPassword('')
                        setConfirmNewPassword('')
                        setLoading(false)
                    })
                    .catch(err => {
                        if (err.response.status === 422) {
                            if (err.response.data.reason === 'equal') {
                                setNewPasswordError(
                                    'Nowe hasło musi być inne od starego'
                                )
                            } else if (err.response.data.reason === 'wrong') {
                                setErrorText('Błędne hasło')
                            }
                            setLoading(false)
                        } else if (err.response.status === 403) {
                            console.log(err.response)
                            setErrorText('Brak uprawnień')
                            setLoading(false)
                        }
                    })
            }
        }

        useEffect(() => {
            const isValid = validatePassword()

            if (!isValid) {
                setDisabled(true)
            } else setDisabled(false)
            // eslint-disable-next-line
        }, [oldPassword, newPassword, confirmNewPassword])

        useEffect(() => {
            setErrorText('')
        }, [oldPassword])

        useEffect(() => {
            setNewPasswordError('')
        }, [newPassword])

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant={'h5'}>
                    Zmień hasło
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Box
                    component='form'
                    onSubmit={changePassword}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {!userData?._id && (
                        <TextField
                            label='Stare hasło'
                            type={'password'}
                            value={oldPassword}
                            required
                            error={!!errorText}
                            helperText={errorText}
                            onChange={event =>
                                changeInputValue(event, setOldPassword)
                            }
                            sx={{ maxWidth: '400px', width: '100%', m: 1 }}
                        />
                    )}
                    <TextField
                        label='Nowe hasło'
                        type={'password'}
                        value={newPassword}
                        error={!!newPasswordError}
                        helperText={newPasswordError}
                        required
                        onChange={event =>
                            changeInputValue(event, setNewPassword)
                        }
                        sx={{
                            maxWidth: '400px',
                            width: '100%',
                            m: 1,
                            mb: 2,
                        }}
                    />
                    <Box sx={{ maxWidth: '400px', width: '100%' }}>
                        <PasswordStrengthBar
                            password={newPassword}
                            minLength={8}
                            shortScoreWord={'Za krótkie'}
                            scoreWords={[
                                'Bardzo słabe',
                                'Słabe',
                                'Średnie',
                                'Silne',
                                'Bardzo silne',
                            ]}
                            userInputs={['view', 'view24']}
                            onChangeScore={score => setPasswordScore(score)}
                        />
                    </Box>
                    <TextField
                        label='Powtórz hasło'
                        type={'password'}
                        value={confirmNewPassword}
                        required
                        error={newPassword !== confirmNewPassword}
                        helperText={
                            newPassword !== confirmNewPassword
                                ? 'Hasła nie są zgodne!'
                                : ''
                        }
                        onChange={event =>
                            changeInputValue(event, setConfirmNewPassword)
                        }
                        sx={{ maxWidth: '400px', width: '100%', m: 1 }}
                    />
                    <LoadingButton
                        loading={loading}
                        variant='contained'
                        disabled={disabled}
                        type='submit'
                    >
                        Zmień
                    </LoadingButton>
                </Box>
            </Box>
        )
    }
)

export default ChangePasswordModal
