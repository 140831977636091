import FormControl from '@mui/material/FormControl/FormControl'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import { Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import ListItemText from '@mui/material/ListItemText/ListItemText'
import moment from 'moment/moment'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import Chip from '@mui/material/Chip/Chip'
import Typography from '@mui/material/Typography/Typography'
import Input from '@mui/material/Input/Input'
import OutlinedInput from '@mui/material/OutlinedInput'

const columns = props => [
    {
        name: 'departmens',
        label: 'Działy',
        options: {
            filter: true,
            filterType: 'custom',
            sort: false,
            filterOptions: {
                // logic: (value, filters) => {
                //     if (!!value) {
                //         const arr = value.map(el => el.title)
                //         if (filters.length)
                //             return !filters.every(r => arr.includes(r))
                //         return false
                //     }
                // },
                display: (filterList, onChange, index, column) => {
                    const optionValues = [...props.departmentsValues]

                    return (
                        <FormControl variant='outlined'>
                            <InputLabel id='filter-label'>Działy</InputLabel>
                            <Select
                                labelId={'filter-label'}
                                multiple
                                input={
                                    <OutlinedInput
                                        label='Działy'
                                        id='filter-label'
                                    />
                                }
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                    filterList[index] = event.target.value
                                    onChange(filterList[index], index, column)
                                }}
                            >
                                {optionValues.map(item => (
                                    <MenuItem key={item} value={item}>
                                        <Checkbox
                                            color='primary'
                                            checked={
                                                filterList[index].indexOf(
                                                    item
                                                ) > -1
                                            }
                                        />

                                        <Tooltip title={item}>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    noWrap: true,
                                                    maxWidth: '300px',
                                                }}
                                                primary={item}
                                            />
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                },
            },
            customBodyRender: value => {
                return value.map(el => <Typography>{`${el} `}</Typography>)
            },
        },
    },
    {
        name: 'authorizations',
        label: 'Nadane dostępy',
        options: {
            setCellProps: () => ({ style: { maxWidth: '400px' } }),
            filter: true,
            filterType: 'custom',
            sort: false,
            filterOptions: {
                logic: (value, filters) => {
                    if (!!value) {
                        const arr = Object.values(value).map(
                            val => val['authName']
                        )
                        if (filters.length)
                            return !filters.every(r => arr.includes(r))
                        return false
                    }
                },
                display: (filterList, onChange, index, column) => {
                    const optionValues = [...props.optionValues]

                    return (
                        <FormControl variant={'standard'}>
                            <InputLabel id='filter-label'>
                                Nadane Upoważnienia
                            </InputLabel>
                            <Select
                                labelId={'filter-label'}
                                multiple
                                input={<Input id='filter-label' />}
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                    filterList[index] = event.target.value
                                    onChange(filterList[index], index, column)
                                }}
                            >
                                {optionValues.map(item => (
                                    <MenuItem key={item} value={item}>
                                        <Checkbox
                                            color='primary'
                                            checked={
                                                filterList[index].indexOf(
                                                    item
                                                ) > -1
                                            }
                                        />

                                        <Tooltip title={item}>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    noWrap: true,
                                                    maxWidth: '300px',
                                                }}
                                                primary={item}
                                            />
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                },
            },

            customBodyRender: (value, tableMeta) => {
                if (!!value) {
                    return tableMeta.rowData[1].map((val, key) => {
                        let color = 'default'
                        let expiryDate = tableMeta.rowData[2]
                        if (!expiryDate) {
                            color = 'default'
                        } else if (
                            moment(expiryDate).toDate() < moment().toDate()
                        ) {
                            color = 'error'
                        } else if (
                            moment(expiryDate).toDate() <
                            moment().add(1, 'M').toDate()
                        ) {
                            color = 'warning'
                        }

                        let title =
                            'Data wygaśnięcia: ' +
                            (expiryDate
                                ? moment(expiryDate).format('LL')
                                : 'Do odwołania')

                        return (
                            <Tooltip key={key} title={title}>
                                <Chip
                                    sx={{ mr: 1, mb: 1 }}
                                    color={color}
                                    label={val.authName}
                                />
                            </Tooltip>
                        )
                    })
                }
            },
        },
    },
    {
        name: 'expiryDate',
        label: 'Data wygaśnięcia',
        options: {
            customBodyRender: value =>
                value ? (
                    <Typography>
                        {moment(value).format('DD.MM.YYYY')}
                    </Typography>
                ) : (
                    <Typography>Do odwołania</Typography>
                ),
        },
    },
    {
        name: 'additionDate',
        label: 'Data dodania',
        options: {
            customBodyRender: value =>
                value ? (
                    <Typography>
                        {moment(value).format('DD.MM.YYYY')}
                    </Typography>
                ) : null,
        },
    },
    {
        name: 'apps',
        label: 'Aplikacje',
        options: {
            display: false,
            customBodyRender: value => value.join(', '),
        },
    },
    {
        name: 'zones',
        label: 'Lokalizacje',
        options: {
            display: false,
            customBodyRender: value => value.map(el => el.title).join(', '),
        },
    },
    {
        name: 'cancellationDate',
        label: 'Data odwołania',
        options: {
            display: false,
            customBodyRender: value =>
                value ? (
                    <Typography>
                        {moment(value).format('DD.MM.YYYY')}
                    </Typography>
                ) : null,
        },
    },
]

export default columns
