import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState, useContext } from 'react'
import { DELETE } from '../../../Helpers/fetch'
import CustomToolbar from '../CustomToolbar/CustomToolbar'
import Modal from '@mui/material/Modal/Modal'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import AddResolutionsAndOrdersModal from '../../Modals/AddResolutionsAndOrdersModal/AddResolutionsAndOrdersModal'
import EditResolutionsAndOrdersModal from '../../Modals/EditResolutionsAndOrdersModal/EditResolutionsAndOrdersModal'
import Snackbar from '../../Snackbar/Snackbar'
import TokenContext from '../../../store/token-context'

const optionsState = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: { name: 'documentName', direction: 'asc' },
}

const ResolutionsAndOrdersTable = () => {
    const [data, setData] = useState([])
    const [count, setCount] = useState(1)
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [snackbarInfo, setSnackbarInfo] = useState({})
    const [actionAnchor, setActionAnchor] = useState(null)
    const [editedRow, setEditedRow] = useState(0)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const [openAddModal, setOpenAddModal] = useState(false)
    const [deleteDataLoading, setDeleteDataLoading] = useState(false)

    const tokenData = useContext(TokenContext)

    const actionsIsOpen = Boolean(actionAnchor)

    const actionsClickHandler = (event, index) => {
        setActionAnchor(event.currentTarget)
        setEditedRow(index)
    }

    const actionsCloseHandler = () => {
        setActionAnchor(null)
    }

    const addModalCloseHandler = refresh => {
        setOpenAddModal(false)
        if (refresh) setRefreshData(!refreshData)
    }

    const editHandler = () => {
        actionsCloseHandler()
        setOpenEditModal(true)
    }

    const deleteHandler = () => {
        actionsCloseHandler()
        setDeleteDataLoading(true)
        const registerId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('/resolutionsAndOrders/delete/' + registerId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteDataLoading(false)
                setOpenDeleteDialog(false)
            })
            .catch(err => {
                setDeleteDataLoading(false)
                console.error(err)
            })
    }

    const importedColumns = columns({
        actionsClickHandler,
        actionsIsOpen,
        actionsCloseHandler,
        actionAnchor,
        editHandler,
        setOpenDeleteDialog,
        data,
        role: tokenData.data.role,
    })

    const options = {
        customToolbar: () => (
            <CustomToolbar onClick={() => setOpenAddModal(true)} />
        ),
    }

    return (
        <>
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            <DataTable
                title={'Uchwały i zarządzenia'}
                url={'resolutionsAndOrders/get'}
                data={data}
                setData={setData}
                count={count}
                setCount={setCount}
                initalOptionsState={optionsState}
                passedOptions={options}
                columns={importedColumns}
                firstLoading={loading}
                setFirstLoading={setLoading}
                refreshData={refreshData}
            />
            {!loading && (
                <Modal open={openAddModal} onClose={addModalCloseHandler}>
                    <DialogContent>
                        <AddResolutionsAndOrdersModal
                            setSnackbarInfo={setSnackbarInfo}
                            close={addModalCloseHandler}
                        />
                    </DialogContent>
                </Modal>
            )}

            {!loading && (
                <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <DialogContent></DialogContent>
                </Modal>
            )}
            {!loading && (
                <Modal
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                >
                    <DialogContent>
                        <EditResolutionsAndOrdersModal
                            data={data[editedRow]}
                            setSnackbarInfo={setSnackbarInfo}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenEditModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                {' '}
                <DialogTitle>Czy na pewno usunąć daną pozycję?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie danej
                        pozycji. Czy na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={deleteDataLoading}
                        onClick={deleteHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ResolutionsAndOrdersTable
