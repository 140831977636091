import IconButton from '@mui/material/IconButton/IconButton'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { checkEdit, checkDelete } from '../../../Helpers/authCheck'

const columns = props => [
    { name: 'name', label: 'Nazwa' },
    {
        name: 'appType',
        label: 'Typ aplikacji',
    },
    {
        name: 'description',
        label: 'Opis',
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            {checkEdit(props.role) && (
                                <MenuItem onClick={() => props.editHandler()}>
                                    Edytuj
                                </MenuItem>
                            )}
                            {checkDelete(props.role) && (
                                <MenuItem
                                    onClick={() =>
                                        props.setOpenDeleteDialog(true)
                                    }
                                >
                                    Usuń
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
